import { useEffect, useState } from 'react';
import { type ControllerRenderProps, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  HiPlus as AddOptionIcon,
  HiXMark as CancelIcon,
  HiCheck as CheckIcon
} from 'react-icons/hi2';
import { Button, Input, type MultiSelectOption } from '@knack/asterisk-react';

interface AddOptionButtonProps {
  field: ControllerRenderProps<FieldValues, string>;
  isAddNewOptionSuccess: boolean;
  handleSubmit: (
    option: string,
    onChange: ControllerRenderProps['onChange'],
    value: MultiSelectOption[]
  ) => void;
}

export function AddOptionButton({
  field,
  isAddNewOptionSuccess,
  handleSubmit
}: AddOptionButtonProps) {
  const [t] = useTranslation();
  const [newOptionValue, setNewOptionValue] = useState('');
  const [isAddingOption, setIsAddingOption] = useState(false);

  useEffect(() => {
    if (isAddNewOptionSuccess) {
      setIsAddingOption(false);
      setNewOptionValue('');
    }
  }, [isAddNewOptionSuccess]);

  return (
    <div
      className="flex items-center gap-2"
      data-testid="form-view-multiple-choice-add-option-button"
    >
      {isAddingOption ? (
        <div className="flex items-center gap-2">
          <Input
            className="p-2 text-sm"
            placeholder={t('components.views.form.multiple_choice.new_option')}
            value={newOptionValue}
            onChange={(e) => setNewOptionValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit(newOptionValue, field.onChange, field.value);
              }
            }}
          />
          <Button
            intent="minimal"
            size="sm"
            disabled={!isAddingOption && !newOptionValue}
            className="size-6"
            onClick={() => {
              handleSubmit(newOptionValue, field.onChange, field.value);
            }}
          >
            <CheckIcon size={16} className="shrink-0" />
          </Button>
          <Button
            intent="minimal"
            size="sm"
            className="size-6"
            onClick={() => {
              setIsAddingOption(false);
              setNewOptionValue('');
            }}
          >
            <CancelIcon size={16} className="shrink-0" />
          </Button>
        </div>
      ) : (
        <Button
          intent="secondary"
          className="gap-1 p-2 font-medium text-emphasis"
          onClick={() => setIsAddingOption(true)}
        >
          <AddOptionIcon size={16} className="shrink-0" />
          {t('components.views.form.multiple_choice.add_option')}
        </Button>
      )}
    </div>
  );
}

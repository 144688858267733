import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { usePageContext } from '@/context/PageContext';

export function useNavigation({ item }: { item: LiveAppPage }) {
  const location = useLocation();
  const { activePage, protectedPageSlug } = usePageContext();
  const { data: application } = useApplicationQuery();

  const childrenItems = useMemo(
    () => application?.pages.filter((i) => item.dropdownPages?.includes(i.key)),
    [application, item.dropdownPages]
  );
  const shouldDisplayChildren = childrenItems && childrenItems.length > 0;
  const hasActiveChild =
    !!childrenItems &&
    childrenItems.some((childItem) => {
      const lastSegment = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      return lastSegment === childItem.slug;
    });
  const isActive =
    activePage?.type === 'authentication'
      ? protectedPageSlug === item.slug
      : activePage?.key === item.key || hasActiveChild;

  return {
    childrenItems,
    shouldDisplayChildren,
    isActive
  };
}

import { useEffect, useRef, useState } from 'react';

/**
 * Script obtained from https://github.com/MomenSherif/react-oauth/blob/cc7c908637599f0898d8abd97eba7f68c4654612/packages/%40react-oauth/google/src/hooks/useLoadGsiScript.ts#L4
 */
export interface UseLoadGsiScriptOptions {
  /**
   * Callback fires on load [gsi](https://accounts.google.com/gsi/client) script success
   */
  onScriptLoadSuccess?: () => void;
  /**
   * Callback fires on load [gsi](https://accounts.google.com/gsi/client) script failure
   */
  onScriptLoadError?: () => void;
}

export enum GsiScriptState {
  Loading,
  Loaded,
  Failed
}

export function useLoadGsiScript(options: UseLoadGsiScriptOptions = {}): {
  scriptState: GsiScriptState;
} {
  const { onScriptLoadSuccess, onScriptLoadError } = options;

  const [scriptState, setScriptState] = useState<GsiScriptState>(GsiScriptState.Loading);

  const onScriptLoadSuccessRef = useRef(onScriptLoadSuccess);
  onScriptLoadSuccessRef.current = onScriptLoadSuccess;

  const onScriptLoadErrorRef = useRef(onScriptLoadError);
  onScriptLoadErrorRef.current = onScriptLoadError;

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://accounts.google.com/gsi/client';
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.referrerPolicy = 'strict-origin-when-cross-origin';
    scriptTag.onload = () => {
      setScriptState(GsiScriptState.Loaded);
      onScriptLoadSuccessRef.current?.();
    };
    scriptTag.onerror = () => {
      setScriptState(GsiScriptState.Failed);
      onScriptLoadErrorRef.current?.();
    };

    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  return { scriptState };
}

import { type LoginView } from '@/types/schema/views/LoginView';

export const loginViewDemoView: LoginView = {
  id: '66570b47195e3f002ba58036',
  registrationType: 'closed',
  submitButtonText: 'Login',
  limitProfileAccess: true,
  allowedProfileKeys: ['profile_3'],
  columns: [],
  links: [],
  inputs: [],
  name: 'Login Form',
  title: 'Login',
  type: 'login',
  description: 'Enter your email address and password to login.',
  key: 'view_8'
};

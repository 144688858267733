import { useQuery } from '@tanstack/react-query';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { type LiveAppTableKey } from '@/types/schema/LiveAppTable';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';
import { isIframedByBuilder } from '@/utils/iframe';
import { usePageContext } from '@/context/PageContext';

export interface ViewRecord {
  id?: string;
  [key: string]: any;
}

export type FormattedViewRecord = {
  values: ViewRecord;
  rawValues: ViewRecord;
};

type ViewRecordQueryOptions = {
  format: 'both' | 'raw';
};

const formatData = (data: ViewRecord) => {
  const formattedData: FormattedViewRecord = {
    values: {},
    rawValues: {}
  };

  Object.keys(data).forEach((key) => {
    if (key.endsWith('_raw')) {
      const newKey = key.replace('_raw', '');
      formattedData.rawValues[newKey] = data[key];

      // If the field is a phone field
      if (data[key]?.full) {
        formattedData.rawValues[newKey] = data[key].formatted;
      }

      // If the field is a timer, we need to format the data to match the expected in the frontend
      if (data[key]?.times) {
        [formattedData.rawValues[newKey]] = data[key].times;
      }
    } else {
      formattedData.values[key] = data[key];
    }
  });

  return formattedData;
};

async function getViewData(
  pageKey: LiveAppPage['key'],
  viewKey: LiveAppViewKey,
  objectKey: LiveAppTableKey,
  recordId: string | null,
  options?: ViewRecordQueryOptions
) {
  const queryParams = options
    ? new URLSearchParams({
        ...options
      })
    : {};

  // When iframed by the builder we need to fetch the records from the object endpoint so it returns all fields
  const url = isIframedByBuilder()
    ? `v1/objects/${objectKey}/records/${recordId}`
    : `v1/scenes/${pageKey}/views/${viewKey}/records/${recordId}`;

  const { data: formData } = await axios.get<ViewRecord>(url, {
    params: queryParams,
    withCredentials: true,
    headers: getAppBasedRequestHeaders()
  });

  return formatData(formData);
}

export function useViewRecordQuery({
  viewKey,
  objectKey,
  enabled = true,
  options
}: {
  viewKey: LiveAppViewKey;
  objectKey: LiveAppTableKey;
  enabled?: boolean;
  options?: ViewRecordQueryOptions;
}) {
  const { activePage, activePageRecordId } = usePageContext();

  if (!activePage) {
    throw new Error('useViewRecordQuery must be used within a page context');
  }

  return useQuery({
    queryKey: [queryKeys.viewData, viewKey, activePageRecordId],
    queryFn: async () =>
      getViewData(activePage.key, viewKey, objectKey, activePageRecordId, options),
    enabled
  });
}

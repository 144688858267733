import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddConnectionIcon } from 'react-icons/hi2';
import { Button, Dialog, useToast } from '@knack/asterisk-react';
import { useQueryClient } from '@tanstack/react-query';

import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { usePageQuery } from '@/hooks/api/queries/usePageQuery';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { FormViewRender } from '@/components/views/form/FormViewRender';
import { ViewContextProvider } from '@/components/views/ViewContext';
import { usePageContext } from '@/context/PageContext';

interface AddConnectedRecordModalProps {
  connectionInput: FormViewConnectionInput;
  onSuccess: (recordId: string) => void;
}

export function AddConnectedRecordModal({
  connectionInput,
  onSuccess
}: AddConnectedRecordModalProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const queryClient = useQueryClient();

  const { activePage } = usePageContext();
  const { data: pageWithViews } = usePageQuery({ pageSlug: activePage?.slug });

  const [isOpen, setIsOpen] = useState(false);

  const insertRecordView = pageWithViews?.views.find((view) => view.key === connectionInput.view);

  if (!insertRecordView || insertRecordView.type !== 'form') {
    return null;
  }

  const onSubmit = async (newRecord: ViewRecord) => {
    if (!newRecord.id) {
      return;
    }

    onSuccess(newRecord.id);
    presentToast({
      title: t('components.views.form.new_record_added'),
      intent: 'success'
    });
    await queryClient.invalidateQueries({
      queryKey: [queryKeys.connectionRecord, connectionInput.field.key]
    });
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <Button intent="secondary" className="items-center gap-2 p-2 text-sm" size="sm">
          <AddConnectionIcon size={16} />
          {t('components.views.form.connection_input.add_new_connection')}
        </Button>
      </Dialog.Trigger>

      <Dialog.Content>
        <Dialog.Title className="sr-only">
          {t('components.views.form.connection_input.add_new_connection')}
        </Dialog.Title>
        <Dialog.MainContent>
          <ViewContextProvider view={insertRecordView}>
            <FormViewRender onSubmitSuccess={onSubmit} />
          </ViewContextProvider>
        </Dialog.MainContent>
      </Dialog.Content>
    </Dialog>
  );
}

import { type UniqueIdentifier } from '@dnd-kit/core';

import { type LiveAppPageSection } from '@/types/schema/LiveAppPage';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { SectionEditor } from '@/pages/page/page-editor/SectionEditor';
import { SectionEditorContextProvider } from '@/pages/page/page-editor/SectionEditorContext';
import { ViewEditor } from '@/pages/page/page-editor/ViewEditor';
import { DroppableColumn } from './DroppableColumn';

interface DragOverlayContentProps {
  items: LiveAppPageSection[];
  beingDraggedViewId: UniqueIdentifier | null;
  beingDraggedViewColumnId: UniqueIdentifier | null;
  beingDraggedSectionId: UniqueIdentifier | null;
}

export function DragOverlayContent({
  items,
  beingDraggedViewId,
  beingDraggedViewColumnId,
  beingDraggedSectionId
}: DragOverlayContentProps) {
  const { page } = usePageEditorContext();

  // Get overlay for the view being dragged
  if (beingDraggedViewId && beingDraggedViewColumnId) {
    const section = items.find((s) => s.columns.some((c) => c.id === beingDraggedViewColumnId));
    const view = page.views.find((v) => v.key === beingDraggedViewId);

    if (!view || !section) return null;

    return (
      <SectionEditorContextProvider section={section}>
        <ViewEditor view={view} columnId={beingDraggedViewColumnId as string} isDragOverlay />
      </SectionEditorContextProvider>
    );
  }

  // Get overlay for the section being dragged
  if (beingDraggedSectionId) {
    const section = items.find((s) => s.id === beingDraggedSectionId);

    if (!section) return null;

    return (
      <SectionEditorContextProvider section={section}>
        <SectionEditor>
          {section.columns.map((column) => (
            <DroppableColumn key={column.id} column={column} />
          ))}
        </SectionEditor>
      </SectionEditorContextProvider>
    );
  }

  return null;
}

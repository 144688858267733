import axios from 'axios';

import { isIframedByBuilder } from './iframe';

export const axiosInstance = axios.create({
  // Set the default base URL for the axios instance. This is used for the initial load of the app
  baseURL: import.meta.env.PUBLIC_API_URL,
  headers: {
    'x-knack-rest-api-key': 'renderer'
  }
});

function setRequestInterceptors(readBaseUrl: string, writeBaseUrl: string) {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (!config.method) {
        return config;
      }

      const requestMap: { [key: string]: 'read' | 'write' } = {
        GET: 'read',
        PUT: 'write',
        POST: 'write',
        DELETE: 'write'
      };

      const method = config.method.toUpperCase();
      let newBaseUrl = config.baseURL;

      if (requestMap[method] === 'read') {
        newBaseUrl = readBaseUrl;
      } else if (requestMap[method] === 'write') {
        newBaseUrl = writeBaseUrl;
      }

      return {
        ...config,
        baseURL: newBaseUrl
      };
    },
    (error) => Promise.reject(error)
  );
}

export function setApiBaseUrls(readSubdomain: string, writeSubdomain: string) {
  const defaultApiUrl = new URL(import.meta.env.PUBLIC_API_URL);
  const { hostname } = defaultApiUrl;
  const hostnameParts = hostname.split('.');

  // Check if there are more than two parts (to exclude base domains like 'example.com')
  if (hostnameParts.length > 2) {
    const defaultApiUrlSubdomain = hostnameParts[0];

    const readBaseUrl = defaultApiUrl.toString().replace(defaultApiUrlSubdomain, readSubdomain);
    const writeBaseUrl = defaultApiUrl.toString().replace(defaultApiUrlSubdomain, writeSubdomain);

    setRequestInterceptors(readBaseUrl, writeBaseUrl);
  }
}

export function setRequestHeaders(newHeaders: { [key: string]: string }) {
  Object.keys(newHeaders).forEach((header) => {
    axiosInstance.defaults.headers[header] = newHeaders[header];
  });
}

export function getAppBasedRequestHeaders() {
  // Some requests need to use the builder headers in order to avoid 401 errors (e.g. fetching view records in a private page) when iFramed in the builder
  if (isIframedByBuilder()) {
    return {
      ...axiosInstance.defaults.headers.common,
      'x-knack-rest-api-key': 'builder',
      'x-knack-new-builder': true,
      'x-knack-builder-version': 'next'
    };
  }

  return axiosInstance.defaults.headers.common;
}

import { type DetailsView } from '@/types/schema/views/DetailsView';
import { useViewMultipleRecordsQuery } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import {
  useViewRecordQuery,
  type FormattedViewRecord
} from '@/hooks/api/queries/useViewRecordQuery';
import { ListItem } from '@/components/views/list/ListItem';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { usePageContext } from '@/context/PageContext';

export function DetailsViewRender({ sourceData }: { sourceData?: FormattedViewRecord }) {
  const { activePageRecordId } = usePageContext();
  const { view } = useViewContext<DetailsView>();

  // If there is a record associated with the page, we use that record id to fetch the data
  const { data: viewRecordFromPage } = useViewRecordQuery({
    viewKey: view.key,
    objectKey: view.source.object,
    enabled: !sourceData && !!activePageRecordId
  });

  // If there is no record associated with the page, we use the record id associated with the view to fetch the data
  const { data: viewRecordFromView } = useViewMultipleRecordsQuery({
    viewKey: view.key,
    objectKey: view.source.object,
    enabled: !sourceData && !activePageRecordId
  });

  const parsedListData = sourceData || viewRecordFromPage || viewRecordFromView?.records[0];

  if (!parsedListData) {
    return null;
  }

  const listColumns = view.columns;

  return (
    <div className="w-full">
      <ViewHeaderSection view={view} className="mb-6" />
      <div className="flex w-full gap-2">
        {listColumns.map((listColumn) => (
          <div key={listColumn.id} style={{ width: `${listColumn.width}%` }}>
            {listColumn.groups.map((group) => (
              <div key={group.id} className="flex gap-2 *:w-full">
                {group.columns.map((column, columnIndex) => (
                  <ListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${parsedListData.values.id}-${columnIndex}`}
                    listRows={column}
                    record={parsedListData}
                  />
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

import { useEffect, useRef, useState, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Banner, Spinner } from '@knack/asterisk-react';
import { useQueryClient } from '@tanstack/react-query';
import { type CredentialResponse } from 'google';

import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { GsiScriptState, useLoadGsiScript } from '@/hooks/useLoadGsiScript';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { isIframedByBuilder } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';

interface GoogleButtonProps {
  isAllowedToSignUp?: boolean;
  setGoogleAuthLoading: Dispatch<SetStateAction<boolean>>;
}

export interface GoogleUserFields {
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
  name: string;
  picture: string;
}

interface GoogleKnackSSOResponse {
  success: boolean;
  googleUser: GoogleUserFields;
  exists: boolean;
}

export function GoogleButton({
  isAllowedToSignUp = false,
  setGoogleAuthLoading
}: GoogleButtonProps) {
  const [t] = useTranslation();
  const [error, setError] = useState('');
  const googleAuthContainerRef = useRef<HTMLDivElement>(null);
  const { data: application } = useApplicationQuery();
  const { data: account } = useAccountQuery();
  const queryClient = useQueryClient();
  const [picture, setPicture] = useState<string | undefined>(undefined);
  const [googleResponse, setGoogleResponse] = useState<GoogleKnackSSOResponse | null>(null);

  async function runPostGoogleAuth(response: GoogleKnackSSOResponse) {
    const url = `/v1/live-app/${account?.slug}/${application?.slug}/sso/google`;
    try {
      // We need to use withCredentials for the user's session to be added properly
      const res = await axios.post(
        url,
        {
          ...response,
          isAllowedToSignUp
        },
        { withCredentials: true }
      );

      if (res.data.success) {
        if (picture) {
          res.data.session.user.picture = picture;
        }
        queryClient.setQueryData([queryKeys.auth.session], { session: res.data.session });
      }
    } catch (err: any) {
      const errorCode = err.response?.data?.errors[0]?.message;

      const errorMessage =
        !errorCode || errorCode === 'generic_error'
          ? 'errors.generic_error'
          : `components.auth.${errorCode}`;

      setError(t(errorMessage));
    }
  }

  async function handleGoogle(response: CredentialResponse) {
    setGoogleAuthLoading(true);
    const url = `/v1/live-app/${account?.slug}/${application?.slug}/sso/google/validate-sign-up`;
    try {
      // We need to use withCredentials for the user's session to be added properly
      const res = await axios.post(
        url,
        {
          ...response
        },
        { withCredentials: true }
      );

      if (res.data.success) {
        setGoogleAuthLoading(false);
        if (res.data.googleUser) {
          setPicture(res.data.googleUser.picture);
        }
        setGoogleResponse(res.data);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const errorCode = err.response?.data?.errors[0]?.message;

      const errorMessage =
        !errorCode || errorCode === 'generic_error'
          ? 'errors.generic_error'
          : `components.auth.${errorCode}`;

      setError(t(errorMessage));
      setGoogleAuthLoading(false);
    }
  }

  const { scriptState } = useLoadGsiScript({
    onScriptLoadSuccess: () => {
      if (!window?.google || !googleAuthContainerRef.current) return;

      window.google.accounts?.id.initialize({
        client_id: application?.settings.sso?.google?.client_id ?? import.meta.env.PUBLIC_GOOGLE_ID,
        client_secret: application?.settings.sso?.google?.client_secret ?? '',
        callback: handleGoogle
      });
      window.google.accounts?.id.renderButton(googleAuthContainerRef.current, {
        size: 'large',
        text: 'signin_with',
        theme: 'outline',
        type: 'standard',
        width: 275 as unknown as string
      });
    },
    onScriptLoadError: () => {
      // eslint-disable-next-line no-console
      console.error('Failed to load Google SSO Script');
    }
  });
  useEffect(() => {
    if (googleResponse) {
      void runPostGoogleAuth(googleResponse);
    }
  }, [googleResponse]);

  return (
    <div className="mx-auto" data-testid="auth-login-google-button">
      {scriptState === GsiScriptState.Loading && <Spinner className="flex h-[40px]" />}
      <div
        ref={googleAuthContainerRef}
        className={cn({
          'flex h-[40px]': scriptState !== GsiScriptState.Loading,
          'pointer-events-none cursor-pointer': isIframedByBuilder()
        })}
      />
      {error && (
        <Banner intent="destructive" className="mb-10">
          <Banner.Message>{error}</Banner.Message>
        </Banner>
      )}
    </div>
  );
}

import { Controller, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox, Label, RadioGroup } from '@knack/asterisk-react';

import { weeklyCheckboxOptionsMap, type DateTimePayload, type DayKey } from './types';

interface DateTimeRepeatCheckboxProps {
  fieldKey: string;
  currentFieldValue: DateTimePayload;
}

export function DateTimeRepeatsOnOptions({
  fieldKey,
  currentFieldValue
}: DateTimeRepeatCheckboxProps) {
  const [t] = useTranslation();
  const isWeekly = currentFieldValue.repeat?.frequency === 'weekly';

  const handleSelectWeeklyCheckbox = (
    day: DayKey,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    onChange({
      ...fieldValue,
      repeat: {
        ...fieldValue.repeat,
        [day]: !fieldValue.repeat?.[day]
      }
    });
  };

  const handleSelectMonthlyOptions = (
    option: string,
    fieldValue: DateTimePayload,
    onChange: ControllerRenderProps['onChange']
  ) => {
    onChange({
      ...fieldValue,
      repeat: {
        ...fieldValue.repeat,
        repeatby: option as 'dom' | 'dow'
      }
    });
  };

  if (isWeekly) {
    return (
      <Controller
        name={fieldKey}
        render={({ field: { value: fieldValue, onChange } }) => (
          <div className="flex gap-2">
            {Object.keys(weeklyCheckboxOptionsMap()).map((day) => {
              const dayOption = day as DayKey;
              return (
                <div key={day} className="flex items-center gap-2">
                  <Checkbox
                    id={`${fieldKey}-${dayOption}`}
                    checked={fieldValue.repeat?.[dayOption]}
                    onClick={() => handleSelectWeeklyCheckbox(dayOption, fieldValue, onChange)}
                  />
                  <Label htmlFor={`${fieldKey}-${dayOption}`}>
                    {weeklyCheckboxOptionsMap()[dayOption]}
                  </Label>
                </div>
              );
            })}
          </div>
        )}
      />
    );
  }

  return (
    <div>
      <Controller
        name={fieldKey}
        render={({ field: { value: fieldValue, onChange } }) => (
          <RadioGroup
            defaultValue={fieldValue.repeat?.repeatby || 'dom'}
            onValueChange={(val) => handleSelectMonthlyOptions(val, fieldValue, onChange)}
            className="flex"
          >
            <RadioGroup.Container>
              <RadioGroup.Item id={`${fieldKey}-day-of-the-month`} value="dom" />
              <Label htmlFor={`${fieldKey}-day-of-the-month`}>
                {t('components.views.form.date_time_input.day_of_the_month')}
              </Label>
            </RadioGroup.Container>
            <RadioGroup.Container>
              <RadioGroup.Item id={`${fieldKey}-day-of-the-week`} value="dow" />
              <Label htmlFor={`${fieldKey}-day-of-the-week`}>
                {t('components.views.form.date_time_input.day_of_the_week')}
              </Label>
            </RadioGroup.Container>
          </RadioGroup>
        )}
      />
    </div>
  );
}

import { Outlet } from 'react-router-dom';

import { BuilderThemingSetup } from './BuilderThemingSetup';
import { APP_LOAD_ERROR_MESSAGE } from './components/errors/ErrorFallback';
import { FullPageSpinner } from './components/FullPageSpinner';
import { InactivityTimeout } from './components/InactivityTimeout';
import { MessagingContextProvider } from './context/MessagingContext';
import { PageContextProvider } from './context/PageContext';
import { SessionContextProvider } from './context/SessionContext';
import { ThemingContextProvider } from './context/ThemingContext';
import { FlagsmithSetup } from './FlagsmithSetup';
import { useAppInit } from './hooks/useAppInit';
import { LiveAppThemingSetup } from './LiveAppThemingSetup';
import { ThirdPartyIntegrationsSetup } from './ThirdPartyIntegrationsSetup';
import { isIframedByBuilder } from './utils/iframe';

export function App() {
  const { application, account, isPending, isError } = useAppInit();

  if (isPending) {
    return <FullPageSpinner />;
  }

  if (!application || !account) {
    window.location.replace(import.meta.env.PUBLIC_KNACK_REDIRECT_URL);
    return null;
  }

  if (isError) {
    throw new Error(APP_LOAD_ERROR_MESSAGE);
  }

  if (isIframedByBuilder()) {
    return (
      <ThemingContextProvider theming={application.theming}>
        <BuilderThemingSetup>
          <MessagingContextProvider>
            <SessionContextProvider>
              <PageContextProvider pages={application.pages} homePage={application.homePage}>
                <Outlet />
              </PageContextProvider>
            </SessionContextProvider>
          </MessagingContextProvider>
        </BuilderThemingSetup>
      </ThemingContextProvider>
    );
  }

  return (
    <FlagsmithSetup accountId={account.id}>
      <ThemingContextProvider theming={application.theming}>
        <LiveAppThemingSetup>
          <SessionContextProvider>
            <PageContextProvider pages={application.pages} homePage={application.homePage}>
              <ThirdPartyIntegrationsSetup>
                <Outlet />
                <InactivityTimeout />
              </ThirdPartyIntegrationsSetup>
            </PageContextProvider>
          </SessionContextProvider>
        </LiveAppThemingSetup>
      </ThemingContextProvider>
    </FlagsmithSetup>
  );
}

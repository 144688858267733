import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

export interface SignInMutationParams {
  email: string;
  password: string;
  remember?: boolean;
}

export async function signIn({ email, password, remember }: SignInMutationParams) {
  const response = await axios.post(
    '/v1/live-app/session',
    {
      email,
      password,
      remember
    },
    {
      withCredentials: true
    }
  );

  return response;
}

export function useSignInMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ email, password, remember }: SignInMutationParams) =>
      signIn({ email, password, remember }),
    onSuccess: (response) => {
      queryClient.setQueryData([queryKeys.auth.session], response.data);
      // Trigger refetch of the entry pages since they might have changed after the successful login
      void queryClient.invalidateQueries({ queryKey: [queryKeys.entryPages] });
    }
  });
}

import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  type TableDisplayRuleAction,
  type TableDisplayRuleIcon,
  type TableView,
  type TableViewDisplayRule
} from '@/types/schema/views/TableView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { useViewContext } from '@/components/views/ViewContext';

interface DefaultTableDisplayRulesActionsStyles {
  backgroundColor: string;
  color: string;
  fontStyle: string;
  fontWeight: string;
  textDecoration: string;
  shouldHideContent: boolean;
  icon: TableDisplayRuleIcon;
}

export function useTableDisplayRules(
  recordValues: ViewRecord,
  displayRules: TableViewDisplayRule[]
) {
  const [activeDisplayRuleActions, setActiveDisplayRuleActions] = useState<
    TableDisplayRuleAction[]
  >([]);

  const { sourceTable } = useViewContext<TableView>();

  const defaultTableDisplayRulesActionsStyles: DefaultTableDisplayRulesActionsStyles = useMemo(
    () => ({
      backgroundColor: '',
      color: '',
      fontStyle: '',
      fontWeight: '',
      textDecoration: '',
      shouldHideContent: false,
      icon: {
        icon: '',
        align: 'left',
        color: ''
      }
    }),
    []
  );

  const processDisplayRuleActions = useCallback(
    (displayRuleActions: TableDisplayRuleAction[]) => {
      const {
        backgroundColor,
        color,
        fontStyle,
        fontWeight,
        textDecoration,
        shouldHideContent,
        icon
      } =
        displayRuleActions.reduce((acc, action) => {
          switch (action.action) {
            case 'bg-color':
              acc.backgroundColor = action.value;
              break;
            case 'text-color':
              acc.color = action.color;
              break;
            case 'text-style':
              if (action.bold) {
                acc.fontWeight = 'bold';
              }
              if (action.italic) {
                acc.fontStyle = 'italic';
              }
              if (action.strikethrough) {
                acc.textDecoration = 'line-through';
              }
              break;
            case 'hide':
              acc.shouldHideContent = true;
              break;
            case 'icon':
              acc.icon = {
                icon: action.icon.icon,
                align: action.icon.align,
                color: action.icon.color
              };
              break;
            default:
              break;
          }

          return acc;
        }, defaultTableDisplayRulesActionsStyles) || {};

      return {
        backgroundColor,
        color,
        fontStyle,
        fontWeight,
        textDecoration,
        shouldHideContent,
        icon
      };
    },
    [defaultTableDisplayRulesActionsStyles]
  );

  const checkActiveTableDisplayRules = useCallback(
    (data: ViewRecord, rules: TableViewDisplayRule[]) => {
      const actions: TableDisplayRuleAction[] = [];

      rules.forEach((rule) => {
        if (isEveryCriteriaMet(data, sourceTable.fields, rule.criteria)) {
          rule.actions.forEach((action) => {
            const isExistingType = actions.some((a) => a.action === action.action);

            // If the action type already exists, only keep the last one
            if (isExistingType) {
              const existingAction = actions.find((a) => a.action === action.action);

              if (existingAction) {
                if (existingAction.field === action.field) {
                  actions.splice(actions.indexOf(existingAction), 1);
                }
              }
            }
            actions.push(action);
          });
        }
      });

      setActiveDisplayRuleActions(actions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceTable.fields, activeDisplayRuleActions]
  );

  useEffect(() => {
    checkActiveTableDisplayRules(recordValues, displayRules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordValues, displayRules]);

  if (activeDisplayRuleActions.length === 0) {
    return {
      cellStyle: {
        backgroundColor: '',
        color: '',
        fontStyle: '',
        fontWeight: '',
        textDecoration: ''
      },
      shouldHideContent: false,
      icon: {
        icon: '',
        align: 'left',
        color: ''
      }
    };
  }

  const { backgroundColor, color, fontStyle, fontWeight, textDecoration, shouldHideContent, icon } =
    processDisplayRuleActions(activeDisplayRuleActions);

  return {
    cellStyle: {
      backgroundColor,
      color,
      fontStyle,
      fontWeight,
      textDecoration
    },
    shouldHideContent,
    icon: {
      icon: icon.icon,
      align: icon.align,
      color: icon.color
    }
  };
}

import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';

export const detailsDemoData: FormattedViewRecord = {
  values: {
    field_61: '',
    field_55: 'LDRA',
    field_60: 'True',
    field_20: 'True',
    field_21: 'XS',
    field_22: 'Coraline Blaxall',
    field_23: 'Internal Auditor'
  },
  rawValues: {
    field_61_raw: '',
    field_55_raw: 'LDRA',
    field_60_raw: true,
    field_20_raw: true,
    field_21_raw: 'XS',
    field_22_raw: 'Coraline Blaxall',
    field_23_raw: 'Internal Auditor'
  }
};

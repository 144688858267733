import { useEffect, useState } from 'react';
import type {
  CollisionDetection,
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier
} from '@dnd-kit/core';
import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { type LiveAppPageSection } from '@/types/schema/LiveAppPage';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { DragOverlayContent } from './DragOverlayContent';
import { SortableSection } from './SortableSection';

export function PageEditorSortable({ sections }: { sections: LiveAppPageSection[] }) {
  const { updatePage, setIsDraggingActive } = usePageEditorContext();

  // These are the items that are used for the drag and drop operations. They are stored in the state so that the UI can be updated when the items are reordered.
  // The structure of the items is the same as the structure of the sections
  const [items, setItems] = useState<LiveAppPageSection[]>(sections);

  // Sensors for the drag and drop operations
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        distance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        tolerance: 5,
        // Require to press for 100ms to start dragging, this can reduce the chance of dragging accidentally due to page scroll
        delay: 100
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  // State for keeping track of the view (and its container column) being dragged. Needed for rendering the dragging overlay of the view
  const [beingDraggedViewId, setBeingDraggedViewId] = useState<UniqueIdentifier | null>(null);
  const [beingDraggedViewColumnId, setBeingDraggedViewColumnId] = useState<UniqueIdentifier | null>(
    null
  );

  // State for keeping track of the section being dragged. Needed for rendering the dragging overlay of the section
  const [beingDraggedSectionId, setBeingDraggedSectionId] = useState<UniqueIdentifier | null>(null);

  // Custom collision detection strategy optimized for multiple containers
  const collisionDetectionStrategy: CollisionDetection = (args) => {
    const itemDragging = args.active.data.current?.type === 'view' ? 'view' : 'section';

    const droppableContainers = args.droppableContainers.filter((droppableContainer) => {
      // If the active draggable is a section, we only want droppable containers that are sections
      if (itemDragging === 'section') {
        return droppableContainer.data.current?.type === 'section';
      }

      // Otherwise, we need droppable that are views and columns
      if (itemDragging === 'view') {
        if (droppableContainer.data.current?.type === 'view') {
          // If the droppable view is the same as the active view, we only want it if it's being dragged within same column
          if (droppableContainer.id === args.active.id) {
            return (
              droppableContainer.data.current?.sortable.containerId ===
              args.active.data.current?.sortable.containerId
            );
          }

          return true;
        }

        // If the droppable container is a column, we only want it if it's empty
        if (droppableContainer.data.current?.type === 'column') {
          return droppableContainer.data.current.isEmpty;
        }

        if (droppableContainer.data.current?.type === 'section') {
          return false;
        }
      }

      return true;
    });

    // First, let's see if there are any collisions with the pointer
    const pointerCollisions = pointerWithin({
      ...args,
      droppableContainers
    });

    // If there are collisions with the pointer, return them
    if (pointerCollisions.length > 0) {
      return pointerCollisions;
    }

    // If the pointer is not colliding with any droppable container, let's check if the pointer is colliding with any container if we modify the pointer coordinates
    const pointerCollisionsModifiedCoordinates = pointerWithin({
      ...args,
      pointerCoordinates: args.pointerCoordinates
        ? {
            x: args.pointerCoordinates.x,
            y: args.pointerCoordinates.y - 25 // Move up the pointer position by 25px to check for collisions with the container above
          }
        : null,
      droppableContainers
    });

    // If there are collisions with the pointer with modified coordinates, return them
    if (pointerCollisionsModifiedCoordinates.length > 0) {
      return pointerCollisionsModifiedCoordinates;
    }

    // Otherwise, return a closestCorners collision detection
    return closestCorners({
      ...args,
      droppableContainers
    });
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (active.data.current?.type === 'view') {
      // Set the ids of the active (dragged) view and its column
      setBeingDraggedViewId(active.id);
      setBeingDraggedViewColumnId(active.data.current?.sortable.containerId ?? null);
    }

    if (active.data.current?.type === 'section') {
      // Set the active (dragged) section id
      setBeingDraggedSectionId(active.id);
    }

    setIsDraggingActive(true);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!active || !over) {
      return;
    }

    let sortedSections = items;

    // Handle the drag end if the item dragged was a section
    if (active.data.current?.type === 'section') {
      const oldIndex = items.findIndex((section) => section.id === active.id);
      const newIndex = items.findIndex((section) => section.id === over.id);

      sortedSections = arrayMove(items, oldIndex, newIndex);
    }

    // Handle the drag end if the item dragged was a view
    if (active.data.current?.type === 'view') {
      const activeColumnId = active.data.current?.sortable.containerId;

      // If the element being dragged over is not a view, it means we are dragging a view over an empty column
      const overColumnId =
        over.data.current?.type === 'view' ? over.data.current?.sortable.containerId : over.id;

      if (!activeColumnId || !overColumnId) {
        return;
      }

      // If the view is being dragged within the same column, we can just update the view order within the column. There is no need to check for the sections.
      if (activeColumnId === overColumnId) {
        const activeColumnSection = items.find((s) =>
          s.columns.some((c) => c.id === activeColumnId)
        );

        sortedSections = items.map((section) => {
          if (section.id === activeColumnSection?.id) {
            return {
              ...section,
              columns: section.columns.map((column) => {
                if (column.id === activeColumnId) {
                  const oldIndex = column.viewKeys.indexOf(active.id as `view_${string}`);
                  const newIndex = column.viewKeys.indexOf(over.id as `view_${string}`);

                  return {
                    ...column,
                    viewKeys: arrayMove(column.viewKeys, oldIndex, newIndex)
                  };
                }
                return column;
              })
            };
          }
          return section;
        });
      } else {
        // At this point we know that the view is being dragged from one column to another
        sortedSections = items.map((section) => ({
          ...section,
          columns: section.columns.map((column) => {
            // Remove the view from the active column
            if (column.id === activeColumnId) {
              return {
                ...column,
                viewKeys: column.viewKeys.filter((item) => item !== active.id)
              };
            }

            // Add the view to the new column
            if (column.id === overColumnId) {
              // If the column is empty, add the view as the only item
              if (column.viewKeys.length === 0) {
                return {
                  ...column,
                  viewKeys: [active.id as `view_${string}`]
                };
              }

              const existingViewIndex = column.viewKeys.indexOf(over.id as `view_${string}`);

              // Determine if the view being dragged should be placed above or below the view it is being dragged over
              const isBelowView =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height / 2;

              const modifier = isBelowView ? 1 : 0;
              const newIndex =
                existingViewIndex >= 0 ? existingViewIndex + modifier : column.viewKeys.length + 1;

              return {
                ...column,
                viewKeys: [
                  ...column.viewKeys.slice(0, newIndex),
                  active.id as `view_${string}`,
                  ...column.viewKeys.slice(newIndex, column.viewKeys.length)
                ]
              };
            }
            return column;
          })
        }));
      }
    }

    // Update the page with the new section order
    updatePage({
      type: 'section',
      action: 'sort',
      sortedSections
    });

    // Update the local items state with the new section order for immediate UI update
    setItems(sortedSections);

    // Reset the dragging state
    setBeingDraggedViewId(null);
    setBeingDraggedSectionId(null);
    setIsDraggingActive(false);
  };

  useEffect(() => {
    // Since the sections can change from outside this dnd/sortable container (e.g. deleting a section or view),
    // we need to update the sortable items whenever the sections change to keep the order in sync
    setItems(sections);
  }, [sections]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.WhileDragging
        }
      }}
    >
      <SortableContext items={items.map((item) => item.id)}>
        {items.map((section) => (
          <SortableSection key={section.id} section={section} />
        ))}
      </SortableContext>

      <DragOverlay>
        <DragOverlayContent
          items={items}
          beingDraggedViewId={beingDraggedViewId}
          beingDraggedViewColumnId={beingDraggedViewColumnId}
          beingDraggedSectionId={beingDraggedSectionId}
        />
      </DragOverlay>
    </DndContext>
  );
}

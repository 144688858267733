import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '@/components/errors/ErrorFallback';

export function RouterErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.reload()}>
      {children}
    </ErrorBoundary>
  );
}

const orderedLocales = [
  {
    code: 'en'
  }
];

export const SUPPORTED_LOCALES = orderedLocales.map(({ code }) => code);
export const DEFAULT_LOCALE = 'en';

export const BREAKPOINTS = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px'
};

export const DAY_PICKER_FROM_YEAR = 1910;
export const DAY_PICKER_TO_YEAR = 2099;

import { useTranslation } from 'react-i18next';
import { HiArrowUpOnSquare as ExportIcon } from 'react-icons/hi2';
import { Button, DropdownMenu } from '@knack/asterisk-react';

import {
  useExportViewRecords,
  type ExportViewRecordsFormat
} from '@/hooks/api/queries/useExportViewRecordsQuery';
import { useViewSearchParams, type ViewWithSearchParams } from '@/hooks/useViewSearchParams';
import { ListViewFiltersPopover } from '@/components/views/list/ListViewFiltersPopover';
import { TableViewFiltersPopover } from '@/components/views/table/TableViewFiltersPopover';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewFiltersChips } from './ViewFiltersChips';
import { ViewMenuFilters } from './ViewMenuFilters';
import { ViewSearchInput } from './ViewSearchInput';

export function ViewToolbarSection() {
  const [t] = useTranslation();
  const { view } = useViewContext<ViewWithSearchParams>();
  const { activeViewFilters } = useViewSearchParams(view);
  const exportViewRecords = useExportViewRecords();

  const {
    filter_type: filterType,
    keyword_search: isTableSearchEnabled,
    allow_exporting: shouldAllowExport,
    menu_filters: menuFilters
  } = view;

  const hasMenuFilters = menuFilters && menuFilters?.length > 0 && filterType === 'menu';
  const hasActiveViewFilters = activeViewFilters && activeViewFilters.rules.length > 0;

  const handleExportViewRecords = async (fileFormat: ExportViewRecordsFormat) => {
    await exportViewRecords({
      viewKey: view.key,
      fileFormat,
      fileName: `${view.title}-exported-records`
    });
  };

  return (
    <div className="mb-2">
      <div className="flex flex-row flex-wrap items-center gap-2 md:justify-between">
        {(shouldAllowExport || filterType === 'fields') && (
          <div className="flex flex-row flex-wrap items-center gap-2 text-sm">
            {shouldAllowExport && (
              <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                  <Button intent="secondary" className="gap-2 text-default md:w-auto" size="sm">
                    <ExportIcon size={16} />
                    <span className="hidden md:inline">{t('actions.export')}</span>
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content className="text-sm" align="start">
                  <DropdownMenu.Item onSelect={() => handleExportViewRecords('csv')}>
                    {t('components.views.table.export_csv')}
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={() => handleExportViewRecords('text')}>
                    {t('components.views.table.export_txt')}
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onSelect={() => handleExportViewRecords('json')}>
                    {t('components.views.table.export_json')}
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu>
            )}
            {filterType === 'fields' && (
              <>
                {view.type === 'table' && <TableViewFiltersPopover view={view} />}
                {view.type === 'list' && <ListViewFiltersPopover view={view} />}
              </>
            )}
            <div className="hidden md:block">
              {hasActiveViewFilters && !hasMenuFilters && (
                <ViewFiltersChips activeViewFilters={activeViewFilters} />
              )}
            </div>
          </div>
        )}
        {isTableSearchEnabled && (
          <div className="flex-1 md:flex-none">
            <ViewSearchInput />
          </div>
        )}
      </div>
      {hasMenuFilters && (
        <div className="mt-2 pb-2">
          <ViewMenuFilters menuFilters={menuFilters} />
        </div>
      )}
    </div>
  );
}

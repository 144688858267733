import { useTranslation } from 'react-i18next';
import { HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { Tooltip } from '@knack/asterisk-react';

import { type LiveAppProfileKey } from '@/types/schema/LiveAppTable';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { type LoginView } from '@/types/schema/views/LoginView';
import { useUserRolesQuery } from '@/hooks/api/queries/useUserRolesQuery';
import { useViewContext } from '@/components/views/ViewContext';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { SignupButton, type SignupButtonProps } from './SignupButton';

type PageEditorSignupButtonProps = SignupButtonProps & {
  signupViewKey?: LiveAppViewKey;
};

function PageEditorSignupButton({
  userRoleName,
  signupViewKey,
  onClick
}: PageEditorSignupButtonProps) {
  const [t] = useTranslation();
  const { selectItem } = usePageEditorContext();

  if (!signupViewKey) {
    return (
      <div className="flex items-center">
        <SignupButton userRoleName={userRoleName} onClick={onClick} />
        <Tooltip>
          <Tooltip.Trigger asChild>
            <button
              type="button"
              className="z-10 ml-1"
              aria-label={t('components.views.login.login.edit_signup_form_tooltip_aria_label')}
            >
              <InfoIcon size={18} />
            </button>
          </Tooltip.Trigger>
          <Tooltip.Content className="max-w-58">
            {t('components.views.login.login.edit_signup_form_tooltip')}
          </Tooltip.Content>
        </Tooltip>
      </div>
    );
  }

  return (
    <SignupButton
      userRoleName={userRoleName}
      onClick={() => {
        onClick();

        // This is needed to update the selected item in the page editor
        selectItem({ type: 'view', viewKey: signupViewKey });
      }}
    />
  );
}

export function PageEditorSignupButtons() {
  const { view } = useViewContext<LoginView>();
  const { page } = usePageEditorContext();
  const { setActiveAuthFlow, setActiveSignupViewKey } = useAuthFlow();

  const { data: userRoleObjects } = useUserRolesQuery({
    enabled: view.limitProfileAccess
  });

  if (!view.registrationForms) {
    return null;
  }

  // When the user has not set a specific role for registration, only one button is shown for the `all_users` role
  if (!view.limitProfileAccess) {
    const signupViewKey = view.registrationForms.all_users;

    if (!signupViewKey) {
      return null;
    }

    // If the value is not a string (not a view key), it means that the registration form has not been created yet and it's an empty object.
    // This is the case when allowing user registration via page editor but the changes have not been saved yet (and no registration form has been created).
    const isNewRegistrationForm = typeof signupViewKey !== 'string';

    return (
      <PageEditorSignupButton
        signupViewKey={isNewRegistrationForm ? undefined : signupViewKey}
        onClick={() => {
          if (!isNewRegistrationForm) {
            setActiveSignupViewKey(signupViewKey);
            setActiveAuthFlow('signup');
          }
        }}
      />
    );
  }

  if (!userRoleObjects || userRoleObjects.length === 0) {
    return null;
  }

  const registrationForms = Object.entries(view.registrationForms);

  return registrationForms.map((registrationFormEntry) => {
    const profileKey = registrationFormEntry[0] as LiveAppProfileKey;
    const signupViewKey = registrationFormEntry[1];

    if (!signupViewKey) {
      return null;
    }

    const useRoleObject = userRoleObjects.find((role) => role.key === profileKey);

    if (!useRoleObject) {
      return null;
    }

    // If the value is not a string (not a view key), it means that the registration form has not been created yet and it's an empty object.
    // This is the case when restricting access to specific roles in the page editor but the changes have not been saved yet (and no registration forms have been created).
    if (typeof signupViewKey !== 'string') {
      return (
        <PageEditorSignupButton
          key={profileKey}
          userRoleName={registrationForms.length === 1 ? undefined : useRoleObject.label}
          onClick={() => {}}
        />
      );
    }

    const activeSignupView = page?.views.find(
      (potentialSignupView) => potentialSignupView.key === signupViewKey
    );

    if (!activeSignupView) {
      return null;
    }

    return (
      <PageEditorSignupButton
        key={profileKey}
        signupViewKey={activeSignupView.key}
        userRoleName={registrationForms.length === 1 ? undefined : useRoleObject.label}
        onClick={() => {
          setActiveSignupViewKey(activeSignupView.key);
          setActiveAuthFlow('signup');
        }}
      />
    );
  });
}

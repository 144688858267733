import { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TbArrowBackUp as ArrowBack } from 'react-icons/tb';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Divider, ThemeProviderContext } from '@knack/asterisk-react';

import { type FormViewSignatureInput } from '@/types/schema/views/form/Signature';
import { type FormView } from '@/types/schema/views/FormView';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { jSignatureToSignaturePad, signaturePadToJSignature } from './jSignatureUtils';

export const SIGNATURE_CANVAS_WIDTH = 500;
export const SIGNATURE_CANVAS_HEIGHT = 125;

export function SignatureInput({ input }: { input: FormViewSignatureInput }) {
  const [t] = useTranslation();
  const { theme } = useThemingContext();
  const {
    setValue: setFormValue,
    formState: { errors },
    watch,
    clearErrors
  } = useFormContext();
  const { view } = useViewContext<FormView>();
  const { isDarkMode } = useContext(ThemeProviderContext);

  const signatureCanvas = useRef<SignatureCanvas>(null);
  const [isSigning, setIsSigning] = useState(false);
  const inputValue = watch(input.field.key);

  const saveSignature = () => {
    setIsSigning(false);
    if (!signatureCanvas.current) return;

    const decodeDataURL = (dataURL: string) => {
      const parts = dataURL.split(';base64,');
      return atob(parts[1]);
    };

    const svgUrl = signatureCanvas.current?.toDataURL('image/svg+xml') || '';
    const data = {
      svg: decodeDataURL(svgUrl),
      base30: signaturePadToJSignature([...signatureCanvas.current.toData()])
    };

    setFormValue(input.field.key, data);
  };

  const clearSignature = async () => {
    if (!signatureCanvas.current?.isEmpty()) {
      signatureCanvas.current?.clear();
      setFormValue(input.field.key, {
        svg: '',
        base30: ''
      });
    }
  };

  const undoLastStroke = () => {
    const data = signatureCanvas.current?.toData();
    if (data && data.length > 0) {
      data.pop(); // remove the last dot or line
      signatureCanvas.current?.fromData(data);
      saveSignature();
    }
  };

  useEffect(() => {
    if (signatureCanvas.current && view.action === 'update') {
      // https://github.com/szimek/signature_pad#handling-high-dpi-screens
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      const canvas = signatureCanvas.current.getCanvas();
      canvas.width = SIGNATURE_CANVAS_WIDTH * ratio;
      canvas.height = SIGNATURE_CANVAS_HEIGHT * ratio;
      canvas.getContext('2d')?.scale(ratio, ratio);

      // This settings ensure that the signatures created in the V3 renders almost identical in the new Builder.
      signatureCanvas.current.getSignaturePad().maxWidth = 1.5;
      signatureCanvas.current.getSignaturePad().minWidth = 1.5;

      if (inputValue?.base30 && inputValue?.base30 !== '') {
        // Convert from jSignature base30 format to signaturePad
        signatureCanvas.current.fromData(jSignatureToSignaturePad(inputValue.base30));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (input.read_only) {
  //  TODO: Fix this formattedValue
  //   // eslint-disable-next-line react/no-danger
  //   return <div dangerouslySetInnerHTML={{ __html: input.formattedValue }} />;
  // }

  return (
    <div className="min-w-[150px] max-w-[400px]">
      <div className="relative">
        <Controller
          {...(view.action === 'create' && { defaultValue: { base30: '', svg: '' } })}
          name={input.field.key}
          render={() => (
            <>
              <SignatureCanvas
                onEnd={() => saveSignature()}
                onBegin={() => {
                  setIsSigning(true);
                  clearErrors(input.field.key);
                }}
                data-testid="signature-pad"
                penColor={isDarkMode ? 'white' : 'black'}
                ref={signatureCanvas}
                canvasProps={{
                  className: cn(
                    'border border-dashed border-brand-default w-full h-auto min-h-[140px] sm:h-auto max-h-[140px] mb-2 transition-all',
                    {
                      'bg-brand-muted': isSigning,
                      'border-destructive': errors[input.field.key],
                      'rounded-lg': theme.appearance.corners === 'rounded'
                    }
                  )
                }}
              />
              <div className="pointer-events-none absolute bottom-[20%] flex w-full flex-col items-center justify-center text-sm">
                {!inputValue?.base30 && !isSigning && (
                  <p className="max-w-[75%] text-center text-xs text-muted focus:hidden sm:max-w-[336px] sm:text-sm">
                    {t('components.views.form.signature_input.draw_helper')}
                  </p>
                )}
                <Divider className="mt-2 max-w-[80%] sm:mt-4 sm:max-w-[336px]" />
              </div>
            </>
          )}
        />
      </div>
      <FormErrorMessage errors={errors} name={input.field.key} />
      <div className="mt-2 flex w-full items-center gap-4">
        <Button
          data-testid="clear-signature-pad-button"
          intent="secondary"
          size="sm"
          disabled={!inputValue?.base30}
          className={cn('outline-offset-0 hover:border-gray-400 focus:outline-offset-[-2px]', {
            'rounded-md': theme.appearance.corners === 'rounded'
          })}
          onClick={clearSignature}
        >
          {t('components.views.form.signature_input.reset_signature_pad')}
        </Button>
        <Button
          data-testid="undo-last-stroke-signature-pad-button"
          intent="secondary"
          size="sm"
          disabled={!inputValue?.base30}
          className={cn('outline-offset-0 hover:border-gray-400 focus:outline-offset-[-2px]', {
            'rounded-md': theme.appearance.corners === 'rounded'
          })}
          onClick={undoLastStroke}
        >
          <ArrowBack size={16} className="mr-1" />
          {t('components.views.form.signature_input.undo_last_stroke')}
        </Button>
      </div>
    </div>
  );
}

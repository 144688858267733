import { usePageQuery } from '@/hooks/api/queries/usePageQuery';
import { isIframedByBuilder } from '@/utils/iframe';
import { FullPageSpinner } from '@/components/FullPageSpinner';
import { NotFoundPage } from '@/pages/not-found/NotFoundPage';
import { PageEditorContextProvider } from '@/pages/page/page-editor/PageEditorContext';
import { usePageContext } from '@/context/PageContext';
import { AuthFlowContextProvider } from './AuthFlowContext';
import { PageEditor } from './page-editor/PageEditor';
import { PageHelmet } from './PageHelmet';
import { PageRender } from './PageRender';

export function Page() {
  const { activePage } = usePageContext();
  const { data: fullPage, isLoading } = usePageQuery({ pageSlug: activePage?.slug });

  if (!activePage) {
    return <NotFoundPage />;
  }

  if (isLoading) {
    return <FullPageSpinner />;
  }

  if (!fullPage) {
    throw new Error(
      `Something went wrong loading the page with slug: ${activePage.slug} and key: ${activePage.key}`
    );
  }

  if (isIframedByBuilder()) {
    return (
      <AuthFlowContextProvider key={fullPage.key}>
        <PageEditorContextProvider initialPage={fullPage}>
          <PageEditor />
        </PageEditorContextProvider>
      </AuthFlowContextProvider>
    );
  }

  return (
    <AuthFlowContextProvider key={fullPage.key}>
      <PageRender page={fullPage} />
      <PageHelmet pageName={fullPage.name} />
    </AuthFlowContextProvider>
  );
}

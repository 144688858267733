import { useTranslation } from 'react-i18next';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type DateTimeValue, type FormFieldValue } from '@/types/schema/KnackRecord';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { type TableView } from '@/types/schema/views/TableView';
import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { getFormattedBooleanDefaultValue } from './views/form/inputs/boolean/helper';
import { useViewContext } from './views/ViewContext';

interface InlineKnackRecordValueProps {
  viewKey: LiveAppViewKey;
  fieldKey: KnackFieldKey;
  value: FormFieldValue;
}

const getDateTimeInlineValue = (value: DateTimeValue) => {
  if (value.time) {
    return `${value.date} ${value.time}`;
  }
  return value.date;
};

export function InlineKnackRecordValue({ viewKey, fieldKey, value }: InlineKnackRecordValueProps) {
  const [t] = useTranslation();

  const { sourceTable } = useViewContext<TableView>();

  const field = sourceTable.fields.find((sourceTableField) => sourceTableField.key === fieldKey);

  const { data: connectionRecords, isLoading } = useConnectionRecordsQuery({
    viewKey,
    fieldKey,
    enabled: field?.type === 'connection'
  });

  if (!field) return null;

  if (isLoading) {
    return t('keywords.loading');
  }

  const connectionRecord =
    field.type === 'connection' &&
    Array.isArray(value) &&
    connectionRecords?.records.find((record) => record.id === value[0]);

  const valueToRender = connectionRecord ? connectionRecord.identifier : value;

  if (field.type === 'boolean' && typeof valueToRender === 'boolean') {
    return getFormattedBooleanDefaultValue(valueToRender, field.format);
  }

  if (field.type === 'date_time') {
    return getDateTimeInlineValue(value as DateTimeValue);
  }

  if (!valueToRender) return null;

  return valueToRender as string;
}

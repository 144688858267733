import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Button, Combobox, Input, Select, type ComboboxOption } from '@knack/asterisk-react';
import { snakeCase } from 'lodash';

import { type KnackField, type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type ViewFilters } from '@/hooks/useViewFilters';
import { type ViewWithSearchParams } from '@/hooks/useViewSearchParams';
import { isInputRequiredForOperator, isRangeRequiredForOperator } from '@/utils/operators';
import { useViewContext } from '@/components/views/ViewContext';
import { FilterBooleanInput } from './filters-inputs/FilterBooleanInput';
import { FilterConnectionInput } from './filters-inputs/FilterConnectionInput';
import { FilterDateTimeInput } from './filters-inputs/FilterDateTimeInput';
import { FilterDateTimeRangeInput } from './filters-inputs/FilterDateTimeRangeInput';
import { FilterMultipleChoiceInput } from './filters-inputs/FilterMultipleChoiceInput';

interface ViewFilterRowProps {
  ruleIndex: number;
  columnOptions: ComboboxOption[];
  filterOperators: string[];
  onRemoveFilter: (index: number) => void;
}

interface ViewFilterInputProps {
  fieldKey: KnackFieldKey;
  formFieldName: `rules.${number}.value`;
  ruleIndex: number;
}

export interface ViewFilterSpecialInput<T extends KnackField> {
  field: T;
  formFieldName: `rules.${number}.value`;
}

function ViewFilterInput({ fieldKey, formFieldName, ruleIndex }: ViewFilterInputProps) {
  const { sourceTable } = useViewContext<ViewWithSearchParams>();
  const { register } = useFormContext<ViewFilters>();

  const operator = useWatch({
    name: `rules.${ruleIndex}.operator`
  });

  const field = sourceTable.fields.find((sourceTableField) => sourceTableField.key === fieldKey);

  if (!field) {
    return null;
  }

  if (!isInputRequiredForOperator(operator)) {
    return null;
  }

  switch (field.type) {
    case 'connection':
      return <FilterConnectionInput formFieldName={formFieldName} field={field} />;
    case 'date_time':
      if (isRangeRequiredForOperator(operator)) {
        return (
          <FilterDateTimeRangeInput formFieldName={`rules.${ruleIndex}`} operator={operator} />
        );
      }
      return <FilterDateTimeInput formFieldName={formFieldName} field={field} />;
    case 'multiple_choice':
      return <FilterMultipleChoiceInput formFieldName={formFieldName} field={field} />;
    case 'boolean':
      return <FilterBooleanInput formFieldName={formFieldName} field={field} />;
    default:
      break;
  }

  return <Input className="text-sm" {...register(formFieldName)} />;
}

export function ViewFilterRow({
  ruleIndex,
  columnOptions,
  filterOperators,
  onRemoveFilter
}: ViewFilterRowProps) {
  const [t] = useTranslation();
  const { control, register, setValue, getValues } = useFormContext<ViewFilters>();

  const { update: updateLocalFilter } = useFieldArray({
    control,
    name: 'rules'
  });

  const localActiveViewFiltersRules = getValues('rules');

  const handleMatchChange = (value: string) => {
    // Update all rules to have the same match
    localActiveViewFiltersRules.forEach((rule, i) => {
      updateLocalFilter(i, { ...rule, match: value });
    });
  };

  useEffect(() => {
    register(`rules.${ruleIndex}.field_name`);
    if (!getValues(`rules.${ruleIndex}.operator`)) {
      setValue(`rules.${ruleIndex}.operator`, filterOperators[0]);
    }
  }, [filterOperators, getValues, register, ruleIndex, setValue]);

  return (
    <div className="flex items-center justify-center">
      <div className="flex w-full flex-wrap items-center justify-between gap-2 sm:flex-nowrap">
        {ruleIndex === 0 ? (
          <span className="min-w-16 text-xs">{t('keywords.where')}</span>
        ) : (
          <Controller
            name="match"
            render={({ field }) => (
              <Select
                value={field.value}
                onValueChange={(value) => {
                  field.onChange(value);
                  handleMatchChange(value);
                }}
              >
                <Select.Trigger className="uppercase" />
                <Select.Content className="uppercase">
                  <Select.Item value="and">{t('keywords.and')}</Select.Item>
                  <Select.Item value="or">{t('keywords.or')}</Select.Item>
                </Select.Content>
              </Select>
            )}
          />
        )}
        <Controller
          name={`rules.${ruleIndex}.field`}
          render={({ field: { value, onChange } }) => {
            const columnValue = {
              key: (value || columnOptions[0].key) as KnackFieldKey,
              label: value
                ? localActiveViewFiltersRules[ruleIndex].field_name
                : columnOptions[0].label
            };

            return (
              <Combobox
                id="view-viewFilters"
                isSearchEnabled
                options={columnOptions}
                triggerClassName="w-full text-emphasis"
                selectedOption={columnValue}
                onSelectOption={(field) => {
                  updateLocalFilter(ruleIndex, {
                    ...localActiveViewFiltersRules[ruleIndex],
                    field_name: field.label,
                    operator: '',
                    value: '',
                    type: undefined,
                    range: undefined
                  });
                  onChange(field.key);
                }}
              />
            );
          }}
        />
        <Controller
          name={`rules.${ruleIndex}.operator`}
          render={({ field: { value, onChange } }) => (
            <Select
              value={value || filterOperators[0]}
              onValueChange={(val) => {
                onChange(val);
                if (!isInputRequiredForOperator(val)) {
                  updateLocalFilter(ruleIndex, {
                    ...localActiveViewFiltersRules[ruleIndex],
                    value: '',
                    type: undefined,
                    range: undefined
                  });
                }
              }}
            >
              <Select.Trigger className="h-auto w-full min-w-[152px] whitespace-nowrap py-2" />
              <Select.Content>
                {filterOperators.map((operator) => (
                  <Select.Item key={operator} value={operator}>
                    {t(`operators.${snakeCase(operator)}`)}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          )}
        />
        <ViewFilterInput
          fieldKey={localActiveViewFiltersRules[ruleIndex].field}
          formFieldName={`rules.${ruleIndex}.value`}
          ruleIndex={ruleIndex}
        />
        <Button intent="minimal" onClick={() => onRemoveFilter(ruleIndex)}>
          <RemoveIcon size={16} className="shrink-0" />
        </Button>
      </div>
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { HiArrowLeft as BackIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { isPageEditor } from '@/utils/iframe';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';

function BackButton({ onClick }: { onClick: () => void }) {
  const [t] = useTranslation();

  return (
    <Button intent="minimal" onClick={onClick}>
      <BackIcon className="mr-2" />
      {t('components.views.login.login.back_to_login')}
    </Button>
  );
}

function PageEditorBackButton({ onClick }: { onClick: () => void }) {
  const { loginViewKey } = useAuthFlow();
  const { selectItem } = usePageEditorContext();

  if (!loginViewKey) {
    return null;
  }

  return (
    <BackButton
      onClick={() => {
        onClick();

        // This is needed to update the selected item in the page editor
        selectItem({ type: 'view', viewKey: loginViewKey });
      }}
    />
  );
}

export function BackToLoginButton() {
  const { setActiveAuthFlow } = useAuthFlow();

  if (isPageEditor()) {
    return <PageEditorBackButton onClick={() => setActiveAuthFlow('login')} />;
  }

  return <BackButton onClick={() => setActiveAuthFlow('login')} />;
}

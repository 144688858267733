import { queryOptions, useQuery } from '@tanstack/react-query';

import { type LiveAppAccount } from '@/types/schema/LiveAppAccount';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function getAccount(accountSlug: string) {
  const { data: account } = await axios.get<LiveAppAccount>(`/v1/live-app/${accountSlug}`, {
    withCredentials: true
  });
  return account;
}

export function accountQuery() {
  const { accountSlug } = getApplicationBasePathSegments();

  return queryOptions({
    queryKey: [queryKeys.account, accountSlug],
    queryFn: async () => getAccount(accountSlug)
  });
}

export function useAccountQuery() {
  return useQuery(accountQuery());
}

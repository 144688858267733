import { type LiveAppPage } from '@/types/schema/LiveAppPage';

export function getApplicationBasePathSegments() {
  const pathSegments = window.location.pathname.split('/').filter(Boolean);
  const [accountSlug, appSlug] = pathSegments;
  return { accountSlug, appSlug };
}

export function getApplicationBasePath() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  if (!accountSlug || !appSlug) {
    return '/';
  }

  return `/${accountSlug}/${appSlug}`;
}

export function getOldLiveAppBaseUrl() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();
  return `//${accountSlug}.${import.meta.env.PUBLIC_OLD_LIVE_APP_HOST}/${appSlug}`;
}

export function getPageAncestorsUrl(page: LiveAppPage, pages: LiveAppPage[]): LiveAppPage[] {
  if (!page || !pages) {
    return [];
  }

  let currentPage: LiveAppPage | null = page;
  const ancestorPages: LiveAppPage[] = [];

  while (currentPage) {
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    const parentPage = pages.find((p) => p.slug === currentPage?.parentSlug);

    if (parentPage && !parentPage.requiresAuthentication) {
      ancestorPages.unshift(parentPage);
      currentPage = parentPage;
    } else {
      currentPage = null;
    }
  }

  return ancestorPages;
}

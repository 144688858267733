import { type ComponentPropsWithoutRef } from 'react';
import { Banner } from '@knack/asterisk-react';

import { getErrorMessages, type ErrorResponseProps } from '@/utils/errors';

interface ApiErrorBannerProps extends ErrorResponseProps, ComponentPropsWithoutRef<'div'> {}

function ErrorBanner({ message, ...restProps }: { message: string }) {
  return (
    <Banner intent="destructive" {...restProps} className="justify-center">
      <Banner.Message>{message}</Banner.Message>
    </Banner>
  );
}

export function ApiErrorBanner({
  error: apiResponseError,
  translationPrefix,
  fallbackKey,
  i18nOptions,
  ...restProps
}: ApiErrorBannerProps) {
  const errorMessages = apiResponseError
    ? getErrorMessages({
        error: apiResponseError,
        translationPrefix,
        fallbackKey,
        i18nOptions
      })
    : [];

  return errorMessages?.map((error) => <ErrorBanner key={error} message={error} {...restProps} />);
}

import { Chart, type ChartConfig } from '@knack/asterisk-react';
import {
  Label,
  LabelList,
  Pie,
  PieChart,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip
} from 'recharts';
import { type HorizontalAlignmentType } from 'recharts/types/component/DefaultLegendContent';
import { type ViewBox } from 'recharts/types/util/types';

import type { ReportsResponse, ReportView } from '@/types/schema/views/ReportView';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { colors } from './shared/colors';
import { CustomLabel } from './shared/CustomLabel';

export function PieChartRender({ data, view }: { data?: ReportsResponse; view: ReportView }) {
  if (!data || !data.reports || !data.reports[0]) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getKey = (record: any) =>
    typeof record.group_0 === 'string'
      ? record.group_0?.replaceAll(' ', '-').toLowerCase()
      : record.group_0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chartData = data.reports[0].records.map((record: any) => {
    const key = getKey(record);
    return {
      ...record,
      group_0: key,
      fill: `var(--color-${key})`
    };
  });

  const total = chartData.reduce((acc, record) => acc + record.calc_0, 0);
  const totalLabel = (props: { viewBox?: ViewBox }) => {
    const { viewBox } = props; // Extract viewBox from props, which could be undefined
    if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
      return (
        <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle">
          <tspan x={viewBox.cx} y={viewBox.cy} className="fill-current text-3xl font-bold">
            {total}
          </tspan>
          <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-current">
            {view.rows[0].reports[0].calculations[0].label}
          </tspan>
        </text>
      );
    }
    return null;
  };

  const chartLayout = view.rows[0].reports[0].layout;

  const chartConfig: ChartConfig = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data.reports[0].records.forEach((record: any, index: number) => {
    const key = getKey(record);
    chartConfig[key] = {
      label: record.group_0,
      color: colors[index % colors.length]
    };
  });

  const shouldUseCustomLabel = chartLayout.data_labels && chartLayout.label_position === 'outside';
  const customLabel = ({
    payload,
    ...props
  }: {
    payload: { raw_0: string };
    [key: string]: unknown;
  }) => <CustomLabel chartConfig={chartConfig} payload={payload} {...props} />;
  const labelValue = shouldUseCustomLabel ? customLabel : chartLayout.data_labels;

  const shouldUseVerticalLegend = chartLayout.legend === 'right' || chartLayout.legend === 'left';

  return (
    <>
      <ViewHeaderSection view={view} />
      <Chart config={chartConfig} className="w-full">
        <PieChart>
          <RechartsTooltip content={<Chart.TooltipContent hideLabel />} />
          {chartLayout.legend !== 'none' && (
            <RechartsLegend
              content={<Chart.LegendContent nameKey="group_0" />}
              layout={shouldUseVerticalLegend ? 'vertical' : 'horizontal'}
              verticalAlign={shouldUseVerticalLegend ? 'middle' : undefined}
              align={
                chartLayout.legend === 'below'
                  ? 'center'
                  : (chartLayout.legend as HorizontalAlignmentType)
              }
            />
          )}
          <Pie
            data={chartData}
            dataKey="calc_0"
            nameKey="group_0"
            innerRadius={chartLayout.pie_type === 'donut' ? '50%' : undefined}
            labelLine={!shouldUseCustomLabel}
            label={chartLayout.label_position !== 'inside' && labelValue}
          >
            {chartLayout.data_labels && chartLayout.label_position === 'inside' && (
              <LabelList
                dataKey="group_0"
                fill="white"
                stroke="none"
                fontSize={12}
                formatter={(value: keyof typeof chartConfig) => chartConfig[value]?.label}
              />
            )}
            {chartLayout.shouldShowTotal && chartLayout.pie_type === 'donut' && (
              <Label content={totalLabel} />
            )}
          </Pie>
        </PieChart>
      </Chart>
    </>
  );
}

import { cn } from '@/utils/tailwind';

interface FormInputWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export function FormInputWrapper({ children, className }: FormInputWrapperProps) {
  return <div className={cn('space-y-2 py-2', className)}>{children}</div>;
}

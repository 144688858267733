import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { type LiveAppPageSection } from '@/types/schema/LiveAppPage';
import { SectionEditor } from '@/pages/page/page-editor/SectionEditor';
import { SectionEditorContextProvider } from '@/pages/page/page-editor/SectionEditorContext';
import { DroppableColumn } from './DroppableColumn';

export function SortableSection({ section }: { section: LiveAppPageSection }) {
  const {
    active,
    activeIndex,
    over,
    overIndex,
    attributes,
    setNodeRef,
    transform,
    transition,
    isSorting
  } = useSortable({
    id: section.id,
    data: {
      type: 'section'
    }
  });

  const style = {
    transition,
    transform: isSorting ? undefined : CSS.Translate.toString(transform)
  };

  let insertPositionState: 'before' | 'after' | null = null;
  if (active && over && over.id === section.id && active.id !== section.id) {
    insertPositionState = overIndex > activeIndex ? 'after' : 'before';
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <SectionEditorContextProvider section={section}>
        <SectionEditor draggingOverInsertPosition={insertPositionState}>
          {section.columns.map((column) => (
            <DroppableColumn key={column.id} column={column} />
          ))}
        </SectionEditor>
      </SectionEditorContextProvider>
    </div>
  );
}

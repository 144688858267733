import { Controller } from 'react-hook-form';
import { Combobox } from '@knack/asterisk-react';
import { t } from 'i18next';

import { type MultipleChoiceField } from '@/types/schema/fields';
import { type ViewFilterSpecialInput } from '@/components/views/view-options/ViewFilterRow';

export function FilterMultipleChoiceInput({
  formFieldName,
  field
}: ViewFilterSpecialInput<MultipleChoiceField>) {
  const { options } = field.format;

  return (
    <Controller
      name={formFieldName}
      render={({ field: { value, onChange } }) => {
        const formattedOptions = options.map((option) => ({
          key: option || '',
          label: option || ''
        }));

        const selectedOption =
          value === 'kn-blank' ? { key: 'kn-blank', label: '' } : { key: value, label: value };

        return (
          <Combobox
            id={`${formFieldName}-filter-multiple-choice`}
            options={formattedOptions}
            selectedOption={selectedOption}
            addOptionText={t('actions.add')}
            onSelectOption={(option) => onChange(option.key)}
          />
        );
      }}
    />
  );
}

import { useEffect } from 'react';
import LogRocket from 'logrocket';

import { useSessionContext } from '@/context/SessionContext';

export function ThirdPartyIntegrationsSetup({ children }: { children: React.ReactNode }) {
  const session = useSessionContext();

  useEffect(() => {
    if (!session) return;

    if (import.meta.env.PUBLIC_LOGROCKET_APP_ID) {
      LogRocket.identify(session.user.id, {
        name: `${session.user?.name?.firstName} ${session.user?.name?.lastName}`,
        email: session.user.email
      });
    }
  }, [session]);

  return children;
}

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

export function usePageHelpers() {
  const { data: application } = useApplicationQuery();

  const pages = application?.pages ?? [];

  const getPageBySlug = (slug: string) => pages.find((page) => page.slug === slug);

  const getPagePathBySlug = (slug: string) => {
    const page = getPageBySlug(slug);

    if (!page) {
      return '';
    }

    let path = `/${page.slug}`;
    let parentSlug = page?.parentSlug;

    while (parentSlug) {
      const parentPage = getPageBySlug(parentSlug);

      if (!parentPage) {
        break;
      }

      path = `/${parentSlug}${path}`;
      parentSlug = parentPage.parentSlug;
    }

    return path;
  };

  return {
    getPageBySlug,
    getPagePathBySlug
  };
}

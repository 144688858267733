import { type UniqueIdentifier } from '@dnd-kit/core';

import { type FormViewGroup, type FormViewInput } from '@/types/schema/views/FormView';
import { FormInputEditor } from './FormInputEditor';

interface DragOverlayContentProps {
  items: FormViewGroup[];
  beingDraggedInputId: UniqueIdentifier | null;
}

function getFormInputById(groups: FormViewGroup[], beingDraggedInputId: UniqueIdentifier | null) {
  let foundInput: FormViewInput | null = null;

  groups.forEach((group) => {
    group.columns.forEach((column) => {
      const input = column.inputs.find((i) => i.id === beingDraggedInputId);
      if (input) {
        foundInput = input;
      }
    });
  });

  return foundInput;
}

export function DragOverlayContent({ items, beingDraggedInputId }: DragOverlayContentProps) {
  const input = getFormInputById(items, beingDraggedInputId);

  if (!input) {
    return null;
  }

  return <FormInputEditor input={input} isDragOverlay />;
}

import { useMutation } from '@tanstack/react-query';

import { axiosInstance as axios } from '@/utils/axiosConfig';

export interface ConfirmResetMutationParams {
  token: string;
}

async function confirmReset({ token }: ConfirmResetMutationParams) {
  const response = await axios.post('/v1/live-app/forgot-password/confirm', {
    token
  });

  return response;
}

export function useConfirmResetPasswordMutation() {
  return useMutation({
    mutationFn: ({ token }: ConfirmResetMutationParams) => confirmReset({ token })
  });
}

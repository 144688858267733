import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';

interface AddOptionParams {
  pageKey: string;
  viewKey: string;
  fieldKey: string;
  option: string;
}

export async function addMultipleChoiceOption({
  pageKey,
  viewKey,
  fieldKey,
  option
}: AddOptionParams) {
  // Temporary endpoint - replace view_67 with the correct view key
  const url = `/v1/scenes/${pageKey}/views/${viewKey}/fields/${fieldKey}/options`;
  const response = await axios.post(url, {
    option
  });
  return response;
}

export function useAddMultipleChoiceOptionMutation() {
  const queryClient = useQueryClient();
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useMutation({
    mutationFn: ({
      viewKey,
      fieldKey,
      option
    }: {
      viewKey: string;
      fieldKey: string;
      option: string;
    }) => addMultipleChoiceOption({ pageKey: activePage.key, viewKey, fieldKey, option }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.table]
      });
    }
  });
}

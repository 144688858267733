import { Chart } from '@knack/asterisk-react';
import {
  Bar,
  BarChart,
  LabelList,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { type HorizontalAlignmentType } from 'recharts/types/component/DefaultLegendContent';

import type { ReportsResponse, ReportView } from '@/types/schema/views/ReportView';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { useChart } from './shared/useChart';

export function BarChartHorizontalRender({
  data,
  view
}: {
  data?: ReportsResponse;
  view: ReportView;
}) {
  const { chartData, chartKeys, chartConfig, hasMultipleGroups } = useChart({ data, view });
  const chartLayout = view.rows[0].reports[0].layout;
  const shouldUseVerticalLegend = chartLayout.legend === 'right' || chartLayout.legend === 'left';
  const heightPerBar = 20;
  const padding = 2;
  const dynamicHeight = chartData.length * heightPerBar + padding;

  return (
    <>
      <ViewHeaderSection view={view} />
      <Chart config={chartConfig} style={{ minHeight: `${dynamicHeight}px` }} className="w-full">
        <BarChart
          accessibilityLayer
          data={chartData}
          layout="vertical"
          margin={{
            left: 20,
            right: 20
          }}
        >
          <YAxis
            dataKey="group_0"
            type="category"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) => value}
          />
          <XAxis dataKey={hasMultipleGroups ? undefined : 'calc_0'} type="number" hide />
          <RechartsTooltip cursor={false} content={<Chart.TooltipContent hideLabel />} />
          {chartLayout.legend !== 'none' && (
            <RechartsLegend
              content={<Chart.LegendContent />}
              layout={shouldUseVerticalLegend ? 'vertical' : 'horizontal'}
              verticalAlign={shouldUseVerticalLegend ? 'middle' : undefined}
              align={
                chartLayout.legend === 'below'
                  ? 'center'
                  : (chartLayout.legend as HorizontalAlignmentType)
              }
            />
          )}
          {hasMultipleGroups ? (
            Object.keys(chartKeys).map((key) => (
              <Bar
                key={`${view.key}-bar-${key}`}
                dataKey={key}
                fill={`var(--color-${key})`}
                radius={5}
              >
                {chartLayout.data_labels && (
                  <LabelList
                    dataKey={key}
                    position="right"
                    offset={8}
                    className="fill-foreground"
                    fontSize={12}
                  />
                )}
              </Bar>
            ))
          ) : (
            <Bar key={`${view.key}-calc_0`} dataKey="calc_0" fill="var(--color-calc_0)" radius={5}>
              {chartLayout.data_labels && (
                <LabelList
                  dataKey="calc_0"
                  position="right"
                  offset={8}
                  className="fill-foreground"
                  fontSize={12}
                />
              )}
            </Bar>
          )}
        </BarChart>
      </Chart>
    </>
  );
}

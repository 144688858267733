import { useBlocker } from 'react-router-dom';

import { isPageEditorPreview } from '@/utils/iframe';
import { View } from '@/components/views/View';
import { PageRender } from '@/pages/page/PageRender';
import { PageTitle } from '@/pages/page/PageTitle';
import { PageEditorSortable } from './page-editor-sortable/PageEditorSortable';
import { usePageEditorContext } from './PageEditorContext';

export function PageEditor() {
  const { page, isSyncingPage } = usePageEditorContext();

  // Block internal navigation since the app is running inside the builder's page editor.
  useBlocker(true);

  // Don't render the page editor while the page data is syncing to avoid flickering.
  if (isSyncingPage) {
    return null;
  }

  // If the app is running inside the builder's page editor preview, render the static page with no editing capabilities
  if (isPageEditorPreview()) {
    return <PageRender page={page} />;
  }

  return (
    <>
      <PageTitle page={page} />

      {/* If the page's parents have menu views that link to this page (e.g. tabs menus), we need to render those menu views at the top of the page  */}
      {page.parentLinkedMenuViews?.map((view) => <View key={view.key} view={view} />)}

      <PageEditorSortable sections={page.groups} />
    </>
  );
}

import { useQueries } from '@tanstack/react-query';

import { accountQuery } from '@/hooks/api/queries/useAccountQuery';
import { applicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { setApiBaseUrls, setRequestHeaders } from '@/utils/axiosConfig';

let isInitialLoad = true;

export function useAppInit() {
  const queriesResult = useQueries({
    queries: [applicationQuery(), accountQuery()],
    combine: (results) => ({
      data: {
        application: results[0].data,
        account: results[1].data
      },
      isPending: results.some((result) => result.isPending),
      isError: results.some((result) => result.isError)
    })
  });

  const { isPending, isError } = queriesResult;
  const { application, account } = queriesResult.data;

  if (isInitialLoad && application) {
    setRequestHeaders({ 'x-knack-application-id': application.id });
    setApiBaseUrls(
      application.settings.clientSubdomainMap.read[0],
      application.settings.clientSubdomainMap.write[0]
    );

    isInitialLoad = false;
  }

  return { application, account, isPending, isError };
}

import { useMutation } from '@tanstack/react-query';

import { axiosInstance as axios } from '@/utils/axiosConfig';

export interface ResetPasswordMutationParams {
  token: string;
  password: string;
  passwordConfirmation: string;
  email: string;
}

export async function resetPassword({
  token,
  password,
  passwordConfirmation,
  email
}: ResetPasswordMutationParams) {
  const response = await axios.post('/v1/live-app/forgot-password/reset', {
    token,
    password,
    password_confirmation: passwordConfirmation,
    email
  });

  return response;
}

export function useResetPasswordMutation() {
  return useMutation({
    mutationFn: ({ token, password, passwordConfirmation, email }: ResetPasswordMutationParams) =>
      resetPassword({ token, password, passwordConfirmation, email })
  });
}

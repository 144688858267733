import { useFormContext } from 'react-hook-form';
import { useIMask } from 'react-imask';
import { Input } from '@knack/asterisk-react';

interface LatituteLongitudeInputProps {
  inputFieldKey: string;
  value: string | number | undefined;
  type: 'longitude' | 'latitude';
}

export function LatituteLongitudeInput({
  inputFieldKey,
  type,
  value
}: LatituteLongitudeInputProps) {
  const { setValue: setFormValue } = useFormContext();
  const { ref: inputRef } = useIMask<HTMLInputElement>(
    {
      mask: Number,
      radix: '.',
      scale: 5,
      mapToRadix: [','],
      min: type === 'latitude' ? -90 : -180,
      max: type === 'latitude' ? 90 : 180,
      autofix: true
    },
    {
      defaultValue: value?.toString(),
      onAccept: (val: string) => {
        setFormValue(inputFieldKey, val);
      }
    }
  );

  return <Input ref={inputRef} value={value} className="text-sm" onChange={() => {}} />;
}

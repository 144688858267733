import { useMemo } from 'react';
import { ThemeProvider } from '@knack/asterisk-react';

import { AppBackground } from './AppBackground';
import { useMessagingContext } from './context/MessagingContext';
import { useThemingContext } from './context/ThemingContext';

export function BuilderThemingSetup({ children }: { children: React.ReactNode }) {
  const { messageFromBuilder } = useMessagingContext();
  const { theme } = useThemingContext();

  const builderTheme = useMemo(
    () =>
      // To make the themeProvider rerender, we need to create new references
      messageFromBuilder?.action === 'set-theme'
        ? { ...messageFromBuilder.theme } // Ensures a new object reference
        : { ...theme },
    [messageFromBuilder, theme]
  );

  return (
    <ThemeProvider theme={builderTheme}>
      {children}
      <AppBackground theme={builderTheme} />
    </ThemeProvider>
  );
}

import { useMutation } from '@tanstack/react-query';

import { axiosInstance as axios } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';

interface AddOptionParams {
  pageKey: string;
  viewKey: string;
  objectKey: string | null;
  data: any;
}

export async function signUp({ pageKey, viewKey, objectKey, data }: AddOptionParams) {
  const url = `/v1/live-app/pages/${pageKey}/views/${viewKey}/profiles/${objectKey}/records/`;
  const response = await axios.post(
    url,
    {
      ...data
    },
    {
      withCredentials: true
    }
  );
  return response;
}

export function useSignUpMutation() {
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useMutation({
    mutationFn: ({ viewKey, objectKey, data }: { viewKey: string; objectKey: string; data: any }) =>
      signUp({ pageKey: activePage.key, viewKey, objectKey, data })
  });
}

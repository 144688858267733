import { DateTime } from 'luxon';

import { type DateTimeField } from '@/types/schema/fields';

export type DateConvertedFormat = 'none' | 'dd/MM/yyyy' | 'MM/dd/yyyy';

export const getDefaultDate = (
  inputFormat: DateTimeField['format'],
  dateFormat: DateConvertedFormat
) => {
  const todayFormatted = DateTime.now().toFormat(dateFormat);

  if (inputFormat.default_type === 'date' && inputFormat.default_date) {
    return inputFormat.default_date;
  }
  if (inputFormat.default_type === 'current') {
    return todayFormatted;
  }
  return '';
};

export const checkIsValidDate = (value: string, dateFormat: DateConvertedFormat) =>
  DateTime.fromFormat(value, dateFormat).isValid ||
  DateTime.fromFormat(value, dateFormat.replace('yyyy', 'yy')).isValid;

export const getFormattedDatePickerDate = (date: string, dateFormat: DateConvertedFormat) =>
  !date || !checkIsValidDate(date, dateFormat)
    ? DateTime.now().toJSDate()
    : DateTime.fromFormat(date, dateFormat.replace('yyyy', 'yy')).toJSDate();

export function getDefaultTimeToString(
  inputFormat: DateTimeField['format'],
  additionalHours?: number
) {
  const defaultTime =
    inputFormat.time_format === 'HH:MM am'
      ? inputFormat.default_time
      : inputFormat.default_time?.slice(0, -2);
  const defaultFormat = inputFormat.time_format === 'HH:MM am' ? 'hh:mm' : 'HH:mm';

  if (inputFormat.time_type === 'current') {
    if (additionalHours) {
      return DateTime.now().plus({ hours: additionalHours }).toFormat(defaultFormat);
    }
    return DateTime.now().toFormat(defaultFormat);
  }

  if (inputFormat.time_type === 'time' && defaultTime) {
    if (additionalHours) {
      return DateTime.fromFormat(defaultTime, defaultFormat)
        .plus({ hours: additionalHours })
        .toFormat(defaultFormat);
    }
    return DateTime.fromFormat(defaultTime, defaultFormat).toFormat(defaultFormat);
  }

  return '';
}

export const getDefaultDatePayload = (
  inputFormat: DateTimeField['format'],
  dateFormat: DateConvertedFormat,
  isRangeMode: boolean
) => {
  const todayFormatted = DateTime.now().toFormat(dateFormat);
  const defaultDate =
    inputFormat.default_type === 'date' && inputFormat.default_date
      ? inputFormat.default_date
      : todayFormatted;

  if (inputFormat.time_format === 'Ignore Time') {
    return {
      date: getDefaultDate(inputFormat, dateFormat),
      ...(isRangeMode && {
        to: {
          date: defaultDate || todayFormatted
        }
      })
    };
  }
  return {
    date: getDefaultDate(inputFormat, dateFormat),
    am_pm: DateTime.now().hour < 12 ? 'AM' : 'PM',
    hours: DateTime.now().hour,
    minutes: DateTime.now().minute,
    rawTime: getDefaultTimeToString(inputFormat),
    ...(isRangeMode && {
      to: {
        date: getDefaultDate(inputFormat, dateFormat),
        am_pm: DateTime.now().hour + 1 < 12 ? 'AM' : 'PM',
        hours: DateTime.now().hour + 1,
        minutes: DateTime.now().minute,
        rawTime: getDefaultTimeToString(inputFormat, 1)
      }
    })
  };
};

export const getRepeatDefaultOptions = () => ({
  frequency: 'daily',
  interval: '1',
  SU: false,
  MO: false,
  TU: false,
  WE: false,
  TH: false,
  FR: false,
  SA: false,
  repeatby: 'dom',
  endson: 'never',
  end_count: '',
  end_date: ''
});

export const getTimeValue = (
  valueTime: string
): Record<'hours' | 'minutes' | 'rawTime', string> => {
  const [hours, minutes] = valueTime.split(':');
  return {
    hours,
    minutes,
    rawTime: valueTime
  };
};

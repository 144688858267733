import { Divider } from '@knack/asterisk-react';

import { type FormViewStaticInput } from '@/types/schema/views/FormView';
import { FormInputWrapper } from './FormInputWrapper';

interface FormStaticInputProps {
  input: FormViewStaticInput;
}

export function FormStaticInput({ input }: FormStaticInputProps) {
  if (input.type === 'section_break') {
    return (
      <FormInputWrapper>
        {input.label && <h3 className="mb-2 text-lg font-medium">{input.label}</h3>}
        {/* eslint-disable-next-line react/no-danger */}
        {input.copy && <div dangerouslySetInnerHTML={{ __html: input.copy }} />}
      </FormInputWrapper>
    );
  }

  return (
    <FormInputWrapper className="py-4">
      <Divider />
    </FormInputWrapper>
  );
}

import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';

import { type LiveAppTableKey, type LiveAppTableResponse } from '@/types/schema/LiveAppTable';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

interface TableQueryParams {
  tableKey: string | undefined;
  isEnabled?: boolean;
}

interface UseTableQueriesParams {
  tableKeys: LiveAppTableKey[];
  isEnabled?: boolean;
}

async function getTable(accountSlug: string, appSlug: string, tableKey: string | undefined) {
  if (!tableKey) {
    return undefined;
  }

  const { data: table } = await axios.get<LiveAppTableResponse>(
    `/v1/live-app/${accountSlug}/${appSlug}/tables/${tableKey}`,
    {
      withCredentials: true
    }
  );

  return table;
}

export function tableQuery({ tableKey, isEnabled = true }: TableQueryParams) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return queryOptions({
    queryKey: [queryKeys.table, tableKey, accountSlug, appSlug],
    queryFn: async () => getTable(accountSlug, appSlug, tableKey),
    enabled: !!tableKey && isEnabled
  });
}

export function useTableQuery({
  tableKey,
  isEnabled = true
}: {
  tableKey: string | undefined;
  isEnabled?: boolean;
}) {
  return useQuery(tableQuery({ tableKey, isEnabled }));
}

export function useTableQueries({ tableKeys = [], isEnabled = true }: UseTableQueriesParams) {
  const queries = tableKeys.map((tableKey) => tableQuery({ tableKey, isEnabled }));
  return useQueries({ queries });
}

import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type ListView } from '@/types/schema/views/ListView';
import { type TableView } from '@/types/schema/views/TableView';
import { type ViewFilters } from './useViewFilters';

export interface ViewQueryParams {
  rowsPerPage: string;
  page: number | null;
  search: string | null;
  filters: ViewFilters | null;
  sortField?: KnackFieldKey | undefined;
  sortOrder?: 'asc' | 'desc';
}

export interface ViewQueryParamsWithSorting extends ViewQueryParams {
  sortField: KnackFieldKey | undefined;
  sortOrder: 'asc' | 'desc';
}

export type ViewParam = keyof ViewQueryParams;

export type ViewWithSearchParams = TableView | ListView;

export function useViewSearchParams(view: ViewWithSearchParams) {
  const [searchParams, setSearchParams] = useSearchParams();

  const getViewOptionsFromParams = useCallback((): ViewQueryParams => {
    // Find the first field to sort by - action columns don't have a field key
    const firstFieldToSortBy =
      view.type === 'table' ? view.columns.find((column) => column.field?.key) : null;

    const defaultBaseParams = {
      rowsPerPage: view.rows_per_page,
      page: 1,
      search: '',
      filters: null
    };

    const defaultTableViewParams: ViewQueryParams = {
      ...defaultBaseParams,
      sortField: firstFieldToSortBy?.field?.key,
      sortOrder: 'asc'
    };

    const viewParams = Object.fromEntries(searchParams.entries());

    if (!viewParams) return view.type !== 'table' ? defaultBaseParams : defaultTableViewParams;

    const updatedParams = Object.keys(viewParams).reduce((accumulatedParams, param) => {
      if (param.startsWith(`${view.key}_`)) {
        const extractedParam = param.substring(view.key.length + 1);
        const value =
          extractedParam === 'filters' ? JSON.parse(viewParams[param]) : viewParams[param];
        return {
          ...accumulatedParams,
          [extractedParam]: value
        };
      }
      return accumulatedParams;
    }, defaultTableViewParams);

    return updatedParams;
  }, [searchParams, view]);

  const {
    rowsPerPage,
    sortOrder,
    sortField,
    search: searchValueFromParams,
    page: currentPage,
    filters: activeViewFilters
  } = useMemo(getViewOptionsFromParams, [getViewOptionsFromParams]);

  const setViewParam = useCallback(
    (value: Partial<ViewQueryParams>) => {
      Object.keys(value).forEach((key) => {
        const newKey = `${view.key}_${key as ViewParam}` as const;
        const newValue = value[key as keyof typeof value];

        if (newValue === null) {
          searchParams.delete(newKey);
          return;
        }
        if (newValue) {
          if (key === 'filters') {
            searchParams.set(newKey, JSON.stringify(newValue));
            return;
          }
          if (typeof newValue === 'number') {
            searchParams.set(newKey, newValue.toString());
            return;
          }
          searchParams.set(newKey, newValue as string);
        }
      });

      setSearchParams(searchParams, {
        replace: true
      });
    },
    [searchParams, setSearchParams, view.key]
  );

  return {
    rowsPerPage,
    sortOrder,
    sortField,
    searchValueFromParams,
    currentPage,
    activeViewFilters,
    setViewParam
  };
}

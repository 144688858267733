import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { Rating } from '@knack/asterisk-react';

import { type FormViewRatingInput } from '@/types/schema/views/form/Rating';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';

export function RatingInput({ input }: { input: FormViewRatingInput }) {
  const { getValues } = useFormContext();

  const inputFormat = input.format;
  const ratingNumber = getValues(input.field.key) || 0;

  const handleChangeRating = (
    value: number,
    fieldValue: string,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (value === parseFloat(fieldValue)) {
      onChange('0');
      return;
    }
    onChange(value.toString());
  };

  return (
    <div className="flex items-center gap-1">
      <Controller
        name={input.field.key}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          const fieldValue = value || ratingNumber;
          return (
            <>
              <Rating
                maxRatingNumber={parseInt(inputFormat.max, 10)}
                ratingNumber={Number(fieldValue)}
                allowHalf={inputFormat.allow_half}
                {...(!input.read_only && {
                  onChange: (val) => handleChangeRating(val, fieldValue, onChange)
                })}
              />
              <FormErrorMessage errors={errors} name={input.field.key} />
            </>
          );
        }}
      />
    </div>
  );
}

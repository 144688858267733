import { useCallback, useEffect } from 'react';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type LiveAppTableResponse } from '@/types/schema/LiveAppTable';
import { useViewSearchParams, type ViewWithSearchParams } from './useViewSearchParams';

export type ViewFilterRule = Record<'operator' | 'value' | 'field_name' | 'match', string> & {
  field: KnackFieldKey;
  range?: string;
  type?: string;
};

export interface ViewFilters {
  match: string;
  rules: ViewFilterRule[];
}

interface UseViewFiltersProps {
  view: ViewWithSearchParams;
  sourceTable: LiveAppTableResponse;
}

export function useViewFilters({ view, sourceTable }: UseViewFiltersProps) {
  const { activeViewFilters, setViewParam } = useViewSearchParams(view);

  const {
    allow_preset_filters: allowPresetFilters,
    preset_filters: viewPresetFilters,
    filter_type: filterType
  } = view;

  const initializeFilters = useCallback(() => {
    if (
      activeViewFilters ||
      !allowPresetFilters ||
      filterType === 'menu' ||
      filterType === 'none'
    ) {
      return;
    }

    if (viewPresetFilters.length > 0) {
      const presetFilters = viewPresetFilters.map((filter) => ({
        match: 'and',
        field: filter.field,
        operator: filter.operator,
        value: filter.value,
        field_name: sourceTable.fields.find((field) => field.key === filter.field)?.name ?? ''
      }));

      setViewParam({ filters: { match: 'and', rules: presetFilters } });
    }
  }, [
    activeViewFilters,
    allowPresetFilters,
    filterType,
    viewPresetFilters,
    setViewParam,
    sourceTable.fields
  ]);

  const saveActiveFilters = useCallback(
    (filters: ViewFilters | null) => {
      setViewParam({ filters, page: null });
    },
    [setViewParam]
  );

  const resetFilters = useCallback(() => {
    setViewParam({ filters: null });
  }, [setViewParam]);

  const removeActiveFilter = useCallback(
    (index: number) => {
      if (!activeViewFilters) return;

      if (activeViewFilters.rules.length === 1) {
        setViewParam({ filters: null });
        return;
      }

      const newRuleState = activeViewFilters.rules.filter((_, i) => i !== index);
      if (!newRuleState || newRuleState.length === 0) {
        setViewParam({ filters: null });
        return;
      }
      setViewParam({
        filters: {
          ...activeViewFilters,
          rules: newRuleState
        }
      });
    },
    [activeViewFilters, setViewParam]
  );

  const getDefaultFilters = () => {
    let defaultFieldInputKey: KnackFieldKey | undefined;
    let defaultFieldInputName: string | undefined;

    if (view.type === 'table') {
      const fieldColumn = view.columns.find((column) => column.type === 'field');

      if (fieldColumn) {
        defaultFieldInputKey = fieldColumn.field.key;
        defaultFieldInputName = fieldColumn.header;
      }
    }

    if (view.type === 'list') {
      // Use `some` to leverage early exits once the field input is found
      view.columns.some((column) =>
        column.groups.some((group) =>
          group.columns.some((innerColumn) =>
            innerColumn.some((listItem) => {
              if (listItem.type === 'field') {
                defaultFieldInputKey = listItem.key;
                defaultFieldInputName = listItem.name;
                return true;
              }
              return false;
            })
          )
        )
      );
    }

    // If no field input is found, return a filter with empty rules
    if (!defaultFieldInputKey || !defaultFieldInputName) {
      const defaultFilters: ViewFilters = {
        match: 'and',
        rules: []
      };

      return defaultFilters;
    }

    const defaultFilters: ViewFilters = {
      match: 'and',
      rules: [
        {
          match: 'and',
          field: defaultFieldInputKey,
          operator: '',
          value: '',
          field_name: defaultFieldInputName
        }
      ]
    };

    return defaultFilters;
  };

  useEffect(() => {
    initializeFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    saveActiveFilters,
    resetFilters,
    removeActiveFilter,
    getDefaultFilters
  };
}

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, Label } from '@knack/asterisk-react';

import { type FormViewEmailInput } from '@/types/schema/views/form/Email';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';

export function EmailInput({ input }: { input: FormViewEmailInput }) {
  const [t] = useTranslation();
  const { view } = useViewContext();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const textFormat = input.format?.text_format;
  const isReadOnly = input.read_only;

  return (
    <>
      <Input
        id={`${view.key}-${input.id}`}
        disabled={isReadOnly}
        type="email"
        placeholder={t('components.views.form.email_input.placeholder')}
        data-testid="form-view-email-input"
        className="text-sm"
        {...register(`${input.field.key}.email`)}
      />
      <FormErrorMessage errors={errors} name={`${input.field.key}.email`} />
      {textFormat === 'unique' && (
        <div>
          <Label
            htmlFor={`${view.key}-${input.id}-unique-email-input`}
            className="text-sm text-subtle"
          >
            {t('components.views.form.email_input.email_text')}
          </Label>
          <Input
            id={`${view.key}-${input.id}-unique-email-input`}
            disabled={isReadOnly}
            placeholder={t('components.views.form.email_input.label_placeholder')}
            className="mt-1 text-sm"
            {...register(`${input.field.key}.label`)}
          />
          <FormErrorMessage errors={errors} name={`${input.field.key}.label`} />
        </div>
      )}
    </>
  );
}

import { HiPlus as PlusIcon } from 'react-icons/hi';
import { Button, Tooltip } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';

interface AddNewButtonProps extends React.ComponentProps<typeof Button> {
  position: 'top' | 'bottom';
  tooltip: string;
}

export function AddNewButton({ onClick, position, tooltip }: AddNewButtonProps) {
  const { theme } = useThemingContext();
  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <Button
          intent="secondary"
          className={cn('absolute left-1/2 z-10 size-5 -translate-x-1/2 p-0.5', {
            'bottom-0 translate-y-1/2': position === 'bottom',
            'top-0 -translate-y-1/2': position === 'top',
            'rounded-full': theme.appearance.corners === 'rounded'
          })}
          onClick={onClick}
        >
          <PlusIcon size={12} />
        </Button>
      </Tooltip.Trigger>
      <Tooltip.Content>{tooltip}</Tooltip.Content>
    </Tooltip>
  );
}

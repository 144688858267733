import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { type LiveAppPageColumn } from '@/types/schema/LiveAppPage';
import { type LiveAppView } from '@/types/schema/LiveAppView';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { ColumnEditor } from '@/pages/page/page-editor/ColumnEditor';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { SortableView } from './SortableView';

export function DroppableColumn({ column }: { column: LiveAppPageColumn }) {
  const { activeAuthFlow, activeSignupViewKey } = useAuthFlow();
  const { page } = usePageEditorContext();

  // This is needed so empty columns can be droppable
  const { setNodeRef, over } = useDroppable({
    id: column.id,
    data: {
      type: 'column',
      isEmpty: column.viewKeys.length === 0
    }
  });

  // Create a map of `views` for faster lookups
  const viewsMap = new Map(page.views.map((view) => [view.key, view]));

  const views: LiveAppView[] = [];
  column.viewKeys.forEach((viewKey) => {
    const view = viewsMap.get(viewKey);

    // Skip rendering the view if it's a registration view.
    // Registration views shouldn't be in the array of viewKeys for a column, but pages with corrupted schemas can still have them.
    if (!view || view.type === 'registration') {
      return;
    }

    // If the view to render is a login view and the active auth flow is signup, render the signup view instead
    if (view.type === 'login' && activeAuthFlow === 'signup' && activeSignupViewKey) {
      const signupView = viewsMap.get(activeSignupViewKey);

      if (!signupView || signupView.type !== 'registration') {
        return;
      }

      views.push(signupView);
      return;
    }

    views.push(view);
  });

  return (
    <SortableContext id={column.id} items={column.viewKeys}>
      <ColumnEditor column={column} ref={setNodeRef} isDraggingOver={over?.id === column.id}>
        {views.map((view) => (
          <SortableView key={view.key} view={view} column={column} />
        ))}
      </ColumnEditor>
    </SortableContext>
  );
}

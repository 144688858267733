import { Divider, RichTextRenderer, Table } from '@knack/asterisk-react';
import { nanoid } from 'nanoid';

import { type DetailsView } from '@/types/schema/views/DetailsView';
import {
  type ListView,
  type ListViewColumn,
  type ListViewColumnType
} from '@/types/schema/views/ListView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { ListActionButton } from '@/components/views/action-button/ListActionButton';
import { useDetailsDisplayRules } from '@/components/views/details/useDetailsDisplayRules';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';

const listActionLinksTypes: ListViewColumnType[] = ['scene_link', 'delete', 'action_link'];

export function ListItem({
  listRows,
  record,
  isSingleColumn
}: {
  listRows: ListViewColumn[];
  record: FormattedViewRecord;
  isSingleColumn?: boolean;
}) {
  const { theme } = useThemingContext();
  const { view } = useViewContext<ListView | DetailsView>();
  const { processDisplayRuleActions } = useDetailsDisplayRules(record.values, view.rules?.fields);

  function getLabelStyles(currentSection: ListViewColumn) {
    return cn({
      'text-4xl': currentSection.format?.styles.includes('h1'),
      'text-2xl': currentSection.format?.styles.includes('h2'),
      'font-bold': currentSection.format?.styles.includes('strong'),
      'font-normal italic': currentSection.format?.styles.includes('em'),
      'text-right': currentSection.format?.label_format === 'right',
      'text-left': currentSection.format?.label_format === 'left'
    });
  }

  function getValueStyles(currentSection: ListViewColumn) {
    return cn({
      'text-4xl': currentSection.format?.styles.includes('h1'),
      'text-2xl': currentSection.format?.styles.includes('h2'),
      'font-bold': currentSection.format?.styles.includes('strong'),
      'font-normal italic': currentSection.format?.styles.includes('em')
    });
  }

  // We reorganize the groups to be able to insert the dividers and special titles between the elements without breaking the UI
  function reorganizeGroups() {
    const newArray: { id: string; columns: ListViewColumn[] }[] = [];
    let temporalGroupRows: ListViewColumn[] = [];

    listRows.forEach((row) => {
      if (
        row.type === 'divider' ||
        row.type === 'special_title' ||
        listActionLinksTypes.includes(row.type)
      ) {
        // If there are rows in the current group, we add it to the newArray
        if (temporalGroupRows.length > 0) {
          newArray.push({ id: nanoid(5), columns: temporalGroupRows });
          temporalGroupRows = []; // Reset the current group
        }
        // Add the special row as a separate group
        newArray.push({ id: nanoid(5), columns: [row] });
      } else {
        // Add the normal row to the current group
        temporalGroupRows.push(row);
      }
    });

    // If there are rows in the current group after the loop, we add them
    if (temporalGroupRows.length > 0) {
      newArray.push({ id: nanoid(5), columns: temporalGroupRows });
    }

    return newArray;
  }

  const listRowsOrganizeGroups = reorganizeGroups();

  return (
    <div className="w-full space-y-2">
      {listRowsOrganizeGroups.map((group) => {
        if (group.columns[0].type === 'divider') {
          return <Divider key={group.id} className="max-w-[400px] py-2" />;
        }

        if (listActionLinksTypes.includes(group.columns[0].type)) {
          return (
            <div // eslint-disable-next-line react/no-array-index-key
              key={`${group.columns[0].key}-${group.id}-${group.columns[0].key}`}
            >
              <ListActionButton column={group.columns[0]} record={record} />
            </div>
          );
        }

        if (group.columns[0].type === 'special_title') {
          return (
            <div key={group.id}>
              <p>{group.columns[0].value}</p>
              <RichTextRenderer
                dangerouslySetInnerHTML={{ __html: group.columns[0].copy }}
                className="text-sm"
              />
            </div>
          );
        }

        return (
          <div
            key={group.id}
            className={cn('h-fit overflow-hidden border border-default text-sm', {
              'max-w-[400px]': isSingleColumn,
              'rounded-lg': theme.appearance.corners === 'rounded'
            })}
          >
            <Table className="table overflow-auto">
              <Table.Body>
                {group.columns.map((section) => {
                  if (section.type === 'divider' || section.type === 'special_title') {
                    return null;
                  }

                  if (listActionLinksTypes.includes(section.type)) {
                    return null;
                  }

                  const processedDisplayRuleActions = processDisplayRuleActions(section.key);
                  if (processedDisplayRuleActions.shouldHideField) {
                    return null;
                  }

                  if (!record.values[section.key] && view.hide_fields) {
                    return null;
                  }

                  const isLabelOnTop = section.format?.label_format === 'top';
                  if (isLabelOnTop) {
                    return (
                      <>
                        <Table.Row key={section.key}>
                          {section.format?.label_format !== 'none' && (
                            <Table.Cell
                              colSpan={2}
                              className={cn(
                                'w-full border-r-0 border-default bg-muted p-2 font-medium hover:bg-muted',
                                getLabelStyles(section)
                              )}
                            >
                              {processedDisplayRuleActions.label || section.label || section.name}
                            </Table.Cell>
                          )}
                        </Table.Row>
                        <Table.Row key={section.key}>
                          <Table.Cell
                            colSpan={2}
                            className={cn('bg-card p-2 hover:bg-card', getValueStyles(section))}
                            dangerouslySetInnerHTML={{ __html: record.values[section.key] }}
                          />
                        </Table.Row>
                      </>
                    );
                  }

                  return (
                    <Table.Row key={section.key}>
                      {section.format?.label_format !== 'none' && (
                        <Table.Cell
                          className={cn(
                            'w-36 border-r-0 border-default bg-muted p-2 font-medium hover:bg-muted',
                            getLabelStyles(section)
                          )}
                        >
                          {processedDisplayRuleActions.label || section.label || section.name}
                        </Table.Cell>
                      )}
                      <Table.Cell
                        className={cn('bg-card p-2 hover:bg-card', getValueStyles(section))}
                        dangerouslySetInnerHTML={{ __html: record.values[section.key] }}
                      />
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        );
      })}
    </div>
  );
}

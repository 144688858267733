import { useFormContext } from 'react-hook-form';
import { Textarea } from '@knack/asterisk-react';

import { type FormViewParagraphTextInput } from '@/types/schema/views/form/ParagraphText';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';

export function ParagraphTextInput({ input }: { input: FormViewParagraphTextInput }) {
  const { view } = useViewContext();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <Textarea
        disabled={input.read_only}
        id={`${view.key}-${input.id}`}
        intent={errors[input.field.key] ? 'destructive' : 'default'}
        data-testid={`${view.key}-${input.id}-paragraph-input`}
        {...register(input.field.key)}
      />
      <FormErrorMessage errors={errors} name={input.field.key} />
    </>
  );
}

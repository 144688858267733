import { type FallbackProps } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HiArrowPath as RefreshIcon } from 'react-icons/hi2';
import { Button, ThemeProvider } from '@knack/asterisk-react';

import { PageError } from '@/components/errors/PageError';

export const APP_LOAD_ERROR_MESSAGE = 'Error loading application';

function ErrorFallbackContent({ resetErrorBoundary }: FallbackProps) {
  const [t, { language }] = useTranslation();

  return (
    <>
      <PageError
        title={t('components.something_went_wrong.title')}
        message={t('components.something_went_wrong.try_again')}
        button={
          <Button intent="primary" onClick={resetErrorBoundary}>
            <RefreshIcon size={14} className="mr-2" />
            {t('actions.refresh')}
          </Button>
        }
      />
      <Helmet>
        <title lang={language}>
          {`${t('components.something_went_wrong.title')} - ${t('titles.app_name')}`}
        </title>
      </Helmet>
    </>
  );
}

export function ErrorFallback(props: FallbackProps) {
  const { error } = props;

  // If an error is thrown during app load, there is no ThemeProvider loaded yet since it depends on the app theme,
  // so we need to wrap ErrorFallbackContent in a ThemeProvider with no explicit theme so it gets the default Asterisk styles
  if (error.message === APP_LOAD_ERROR_MESSAGE) {
    return (
      <ThemeProvider>
        <ErrorFallbackContent {...props} />
      </ThemeProvider>
    );
  }

  return <ErrorFallbackContent {...props} />;
}

import { type FieldErrors } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

interface FormErrorMessageProps {
  name: string;
  errors: FieldErrors;
}

export function FormErrorMessage({ name, errors }: FormErrorMessageProps) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <p className="text-xs text-destructive">{message}</p>}
    />
  );
}

import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsDot as DotIcon } from 'react-icons/bs';
import { HiExclamationTriangle as ErrorIcon, HiCheck as SuccessIcon } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Button, Card, InputPassword, Label } from '@knack/asterisk-react';
import { z } from 'zod';

import { useResetPasswordMutation } from '@/hooks/api/mutations/password-management/useResetPasswordMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { cn } from '@/utils/tailwind';
import { getPasswordValidationState } from '@/components/views/form/inputs/password/helper';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { useThemingContext } from '@/context/ThemingContext';

interface ResetPasswordFormProps {
  passwordResetToken: string;
  forgottenEmail: string;
}

export function ResetPasswordForm({ passwordResetToken, forgottenEmail }: ResetPasswordFormProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { data: application } = useApplicationQuery();
  const { mutate: resetPasswordMutation, isSuccess } = useResetPasswordMutation();

  const { theme } = useThemingContext();
  const { setActiveAuthFlow } = useAuthFlow();

  const logoUrl = theme.mainNavContainer.logo.url;
  const resetSchema = z
    .object({
      password: z.string(),
      passwordConfirmation: z.string()
    })
    .superRefine(({ password, passwordConfirmation }, context) => {
      if (password !== passwordConfirmation) {
        context.addIssue({
          code: 'custom',
          message: t('components.views.login.reset.password_missmatch'),
          path: ['passwordConfirmation']
        });
      }
    });
  type ResetViewSchemaType = z.infer<typeof resetSchema>;

  const {
    handleSubmit,
    register,
    clearErrors,
    formState: { dirtyFields, errors, isValid },
    watch
  } = useForm<ResetViewSchemaType>({
    resolver: zodResolver(resetSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: ''
    }
  });

  const onSubmit: SubmitHandler<ResetViewSchemaType> = (data) => {
    resetPasswordMutation(
      {
        token: passwordResetToken,
        email: forgottenEmail,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation
      },
      {
        onError: () => {
          // TODO: Check the error coming from the API when token has expired and redirect to the forgot password page FE-2600
        }
      }
    );
  };

  const passwordValue = watch('password');
  const passwordConfirmationValue = watch('passwordConfirmation');
  const passwordConditions = getPasswordValidationState(
    passwordValue,
    application?.settings?.passwordOptions
  );

  const isEveryPasswordConditionSuccess =
    passwordConditions &&
    Object.values(passwordConditions).every((condition) => condition === 'success');

  const validationPasswordMapping = {
    minimumCharacter: t('components.views.form.password_input.minimum_characters_error'),
    requireNoCommon: t('components.views.form.password_input.no_common_words_error'),
    requireNumber: t('components.views.form.password_input.need_one_number_error'),
    specialCharacter: t('components.views.form.password_input.need_one_special_character_error'),
    requireLowerCase: t('components.views.form.password_input.need_one_lowercase_error'),
    requireUpperCase: t('components.views.form.password_input.need_one_uppercase_error')
  } as const;

  type ValidationPasswordMapping = keyof typeof validationPasswordMapping;

  const activePasswordValidationRules =
    (passwordConditions && Object.keys(passwordConditions)) || [];

  const passwordHintOptions = activePasswordValidationRules
    ? activePasswordValidationRules.map((key: string) => ({
        key,
        message: validationPasswordMapping[key as ValidationPasswordMapping]
      }))
    : [];

  const shouldDisableSubmitButton: boolean =
    !isValid ||
    (activePasswordValidationRules.length > 0 ? !isEveryPasswordConditionSuccess : false) ||
    passwordValue !== passwordConfirmationValue;

  return (
    <Card className="mx-auto w-full max-w-[520px] p-10 text-sm sm:p-10">
      {theme.mainNavContainer.logo.isVisible && logoUrl && (
        <img
          src={theme.mainNavContainer.logo.url}
          alt={`${application?.name} Logo`}
          className="max-w-[64px] sm:max-w-[120px]"
        />
      )}
      {isSuccess ? (
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-2xl font-medium text-emphasis">
            {t('components.views.login.reset.success')}
          </h1>
          <Button
            intent="primary"
            className="mt-8 w-full"
            onClick={() => {
              setActiveAuthFlow('login');
              navigate({ pathname: '/', search });
            }}
          >
            {t('components.views.login.reset.go_back_home')}
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8 flex flex-col items-center justify-center space-y-2">
            <h1 className="text-2xl font-medium text-emphasis">
              {t('components.views.login.reset.title')}
            </h1>
            <p>{t('components.views.login.reset.description')}</p>
          </div>
          {(errors.password || errors.passwordConfirmation) && (
            <Banner
              intent="destructive"
              className="mb-6 flex h-auto justify-center p-3 font-medium"
            >
              {errors.password
                ? t('components.views.login.reset.error_requirements')
                : t('components.views.login.reset.password_missmatch')}
            </Banner>
          )}
          <div className="mb-6 space-y-2">
            <Label htmlFor="password" className="font-medium">
              {t('components.views.login.reset.password')}
            </Label>
            <InputPassword
              id="password"
              intent={errors.password ? 'destructive' : undefined}
              {...register('password', {
                onChange: () => {
                  if (errors.password || errors.passwordConfirmation) {
                    clearErrors();
                  }
                }
              })}
            />
          </div>
          <div className="mb-6 space-y-2">
            <Label htmlFor="confirm-password" className="font-medium">
              {t('components.views.login.reset.confirm_password')}
            </Label>
            <InputPassword
              id="confirm-password"
              intent={errors.passwordConfirmation ? 'destructive' : undefined}
              {...register('passwordConfirmation', {
                onChange: () => {
                  if (errors.password || errors.passwordConfirmation) {
                    clearErrors();
                  }
                }
              })}
            />
          </div>
          {errors && (
            <div className="space-y-2">
              <p
                className={cn('text-xs text-subtle', {
                  'text-destructive': errors?.password
                })}
              >
                {errors?.password && !isEveryPasswordConditionSuccess && (
                  <ErrorIcon size={12} className="mr-1 inline-block text-destructive" />
                )}
                {activePasswordValidationRules.length > 0 &&
                  (dirtyFields.password || dirtyFields.passwordConfirmation) && (
                    <>
                      {isEveryPasswordConditionSuccess && (
                        <SuccessIcon size={12} className="mr-1 inline-block text-success-default" />
                      )}
                      {t('components.views.form.password_input.must_contain')}
                      {passwordHintOptions.length > 2 ? ':' : ' '}
                      {passwordHintOptions.length <= 2 &&
                        passwordHintOptions.map((hint, index) => (
                          <span key={hint.key}>
                            {hint.message}{' '}
                            {index === 0 &&
                              passwordHintOptions.length > 1 &&
                              `${t('keywords.with')} `}
                          </span>
                        ))}
                    </>
                  )}
              </p>
              {passwordHintOptions.length > 2 && (
                <ul
                  className={cn('mt-2 text-xs text-subtle', {
                    'text-destructive': errors?.password
                  })}
                >
                  {passwordHintOptions.map((hint) => {
                    const condition = passwordConditions?.[hint.key as ValidationPasswordMapping];

                    return (
                      <li
                        key={hint.key}
                        className={cn('ml-2 flex items-center gap-2', {
                          'text-success-default': condition === 'success'
                        })}
                      >
                        {condition === 'success' ? (
                          <SuccessIcon size={12} />
                        ) : (
                          <DotIcon size={12} />
                        )}
                        {hint.message}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}
          <Button
            intent="primary"
            type="submit"
            className="w-full"
            disabled={shouldDisableSubmitButton}
          >
            {t('components.views.login.reset.title')}
          </Button>
        </form>
      )}
    </Card>
  );
}

import { useMutation } from '@tanstack/react-query';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { axiosInstance as axios } from '@/utils/axiosConfig';

interface UploadAssetMutationProps {
  fieldKey: string;
  viewKey: string;
  file: File;
  isImage: boolean;
}

interface UploadAssetProps extends UploadAssetMutationProps {
  appId: string;
}

export async function uploadAsset({ appId, fieldKey, viewKey, file, isImage }: UploadAssetProps) {
  if (!file) {
    throw new Error('No file selected');
  }
  const { name, size, type } = file;
  const url = `/v1/applications/${appId}/assets/${isImage ? 'image' : 'file'}/upload/stream?fieldKey=${fieldKey}&filename=${name}&size=${size}&type=${type}`;

  const formData = new FormData();
  formData.append('files', file);
  formData.append(
    'origin',
    JSON.stringify({
      view_key: viewKey,
      field_key: fieldKey
    })
  );

  const response = await axios.post(url, formData);

  return response;
}

export function useUploadAssetMutation() {
  const { data: application } = useApplicationQuery();

  if (!application) {
    throw new Error('Application is not loaded');
  }

  const appId = application.id;

  return useMutation({
    mutationFn: ({ fieldKey, viewKey, file, isImage }: UploadAssetMutationProps) =>
      uploadAsset({ appId, fieldKey, viewKey, file, isImage })
  });
}

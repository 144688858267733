import { useQuery } from '@tanstack/react-query';

import { type LiveAppProfileKey } from '@/types/schema/LiveAppTable';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

interface UserRoleData {
  key: LiveAppProfileKey;
  label: string;
}

async function getUserRoles({ accountSlug, appSlug }: { accountSlug: string; appSlug: string }) {
  const url = `/v1/live-app/${accountSlug}/${appSlug}/user-roles`;
  const { data: userRoles } = await axios.get<UserRoleData[]>(url, {
    withCredentials: true
  });

  return userRoles;
}

export function useUserRolesQuery({ enabled = true }: { enabled?: boolean } = {}) {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return useQuery({
    queryKey: [queryKeys.userRoles, accountSlug, appSlug],
    queryFn: () => getUserRoles({ accountSlug, appSlug }),
    enabled
  });
}

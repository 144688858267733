import { type LiveAppPageFull } from '@/types/schema/LiveAppPage';
import { View } from '@/components/views/View';
import { PageTitle } from '@/pages/page/PageTitle';
import { useAuthFlow } from './AuthFlowContext';
import { PageColumn } from './PageColumn';
import { PageSection } from './PageSection';

function PageSections({ page }: { page: LiveAppPageFull }) {
  const { activeAuthFlow, activeSignupViewKey } = useAuthFlow();
  const viewsMap = new Map(page.views.map((view) => [view.key, view]));

  // If the page is an authentication page and the active auth view is 'signup', we only want to render the signup view and nothing else
  if (page.type === 'authentication' && activeAuthFlow === 'signup' && activeSignupViewKey) {
    const signupView = viewsMap.get(activeSignupViewKey);

    if (!signupView || signupView.type !== 'registration') {
      return null;
    }

    return <View view={signupView} />;
  }

  return (
    <>
      {/* If the page's parents have menu views that link to this page (e.g. tabs menus), we need to render those menu views at the top of the page  */}
      {page.parentLinkedMenuViews?.map((view) => <View key={view.key} view={view} />)}

      {page.groups.map((section) => {
        // Skip rendering the section if all columns are empty
        if (section.columns.every((column) => column.viewKeys.length === 0)) {
          return null;
        }

        return (
          <PageSection key={section.id}>
            {section.columns.map((column) => (
              <PageColumn key={column.id} column={column}>
                {column.viewKeys.map((viewKey) => {
                  const view = viewsMap.get(viewKey);

                  // Skip rendering the view if it's a registration view.
                  // Registration views shouldn't be in the array of viewKeys for a column, but pages with corrupted schemas can still have them.
                  if (!view || view.type === 'registration') {
                    return null;
                  }

                  return <View key={viewKey} view={view} />;
                })}
              </PageColumn>
            ))}
          </PageSection>
        );
      })}
    </>
  );
}

export function PageRender({ page }: { page: LiveAppPageFull }) {
  return (
    <>
      <PageTitle page={page} />
      <PageSections page={page} />
    </>
  );
}

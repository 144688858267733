import { type MenuView } from '@/types/schema/views/MenuView';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { MenuButtons } from './MenuButtons';
import { MenuTabs } from './MenuTabs';

export function MenuViewRender() {
  const { view } = useViewContext<MenuView>();

  return (
    <div>
      <ViewHeaderSection view={view} className="mb-4" />
      {(view.format === 'none' || !view.format) && <MenuButtons menuView={view} />}
      {view.format === 'tabs' && <MenuTabs menuView={view} />}
    </div>
  );
}

import validationHelper from '@knack/validation-helper';

import { type LiveAppApplicationPasswordOptions } from '@/types/schema/LiveAppApplication';

export const getConditionState = (password: string | undefined, condition: boolean) => {
  if (password?.length === 0 || password === undefined) return 'error';

  return condition ? 'success' : 'error';
};

type PasswordValidationRules =
  | 'minimumCharacter'
  | 'requireNumber'
  | 'specialCharacter'
  | 'requireLowerCase'
  | 'requireUpperCase'
  | 'requireNoCommon';

type ValidationState = 'success' | 'error';

type PasswordValidationState = Partial<Record<PasswordValidationRules, ValidationState>>;

export const getPasswordValidationState = (
  password: string,
  options?: LiveAppApplicationPasswordOptions
) => {
  if (!options) return null;

  const validationResults: PasswordValidationState = {};

  if (options.minimumCharacter) {
    validationResults.minimumCharacter = getConditionState(password, password?.length > 7);
  }
  if (options.requireNumber) {
    validationResults.requireNumber = getConditionState(
      password,
      validationHelper.validatePasswordRequireNumber(password)
    );
  }
  if (options.specialCharacter) {
    validationResults.specialCharacter = getConditionState(
      password,
      validationHelper.validatePasswordSpecialCharacter(password)
    );
  }
  if (options.requireLowerCase) {
    validationResults.requireLowerCase = getConditionState(
      password,
      validationHelper.validatePasswordRequireLowerCase(password)
    );
  }
  if (options.requireUpperCase) {
    validationResults.requireUpperCase = getConditionState(
      password,
      validationHelper.validatePasswordRequireUpperCase(password)
    );
  }
  if (options.requireNoCommon) {
    validationResults.requireNoCommon = getConditionState(
      password,
      validationHelper.validatePasswordRequireUncommon(password)
    );
  }

  return validationResults;
};

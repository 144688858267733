import { useRef, useState } from 'react';
import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker, Input, Label, RadioGroup, Select } from '@knack/asterisk-react';

import { DateTimeRepeatsOnOptions } from './DateTimeRepeatsOnOptions';
import { selectPeriodOption, type DateTimePayload, type SelectPeriodOption } from './types';
import { getFormattedDatePickerDate, type DateConvertedFormat } from './utils';

interface DateTimeRepeatOptionsProps {
  dateFormat: DateConvertedFormat;
  getFormattedDate: (date: Date) => string;
  fieldKey: string;
}

export function DateTimeRepeatOptions({
  dateFormat,
  getFormattedDate,
  fieldKey
}: DateTimeRepeatOptionsProps) {
  const { watch } = useFormContext();
  const [t] = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const currentFieldValue: DateTimePayload = watch(fieldKey);

  if (!currentFieldValue?.repeat) {
    return null;
  }

  const repeatOptionPostFix = {
    daily: 'days',
    weekly: 'weeks',
    monthly: 'months',
    yearly: 'years'
  } as const;

  const handleSelectDate = (
    date: Date | undefined,
    onChange: ControllerRenderProps['onChange']
  ) => {
    if (!date) {
      return;
    }
    onChange({
      ...currentFieldValue,
      repeat: {
        ...currentFieldValue.repeat,
        end_date: getFormattedDate(date)
      }
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: ControllerRenderProps['onChange']
  ) => {
    const { value } = e.target;
    // Only allow numbers and slashes
    if (!/^[0-9/]*$/.test(value)) {
      return;
    }

    onChange({
      ...currentFieldValue,
      repeat: {
        ...currentFieldValue.repeat,
        end_date: value
      }
    });
  };

  const handleRepeatOptionChange = (
    key: 'frequency' | 'interval',
    optionValue: string,
    onChange: ControllerRenderProps['onChange']
  ) => {
    const isChangingFrenquency = key === 'frequency';

    // if key is interval, only allow integers
    if (key === 'interval' && optionValue !== '' && !/^\d+$/.test(optionValue)) {
      return;
    }

    onChange({
      ...currentFieldValue,
      repeat: {
        ...currentFieldValue.repeat,
        [key]: optionValue,
        ...(isChangingFrenquency && {
          SU: false,
          MO: false,
          TU: false,
          WE: false,
          TH: false,
          FR: false,
          SA: false
        })
      }
    });
  };

  const handleEndOptionChange = (
    optionValue: 'date' | 'limit' | 'never',
    onChange: ControllerRenderProps['onChange']
  ) => {
    onChange({
      ...currentFieldValue,
      repeat: {
        ...currentFieldValue.repeat,
        endson: optionValue
      }
    });
  };

  const handleEndInputOcurrencesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: ControllerRenderProps['onChange']
  ) => {
    const { value } = e.target;
    onChange({
      ...currentFieldValue,
      repeat: {
        ...currentFieldValue.repeat,
        end_count: value
      }
    });
  };

  const handleResetInputOnBlur = (onChange: ControllerRenderProps['onChange']) => {
    if (currentFieldValue.repeat?.interval === '') {
      onChange({
        ...currentFieldValue,
        repeat: {
          ...currentFieldValue.repeat,
          interval: '1'
        }
      });
    }
  };

  return (
    <div className="space-y-4 pt-4 text-sm">
      <div className="flex items-center gap-2">
        <div className="w-full items-center gap-2 sm:flex">
          <p className="mb-2 w-24 sm:mb-0 sm:text-right">
            {t('components.views.form.date_time_input.repeat_option')}
          </p>
          <div className="flex items-center gap-2">
            <Controller
              name={fieldKey}
              render={({ field: { onChange } }) => (
                <>
                  <Select
                    defaultValue="daily"
                    value={currentFieldValue.repeat?.frequency}
                    onValueChange={(selectedValue) =>
                      handleRepeatOptionChange('frequency', selectedValue, onChange)
                    }
                  >
                    <Select.Trigger className="w-full" />
                    <Select.Content>
                      {Object.keys(selectPeriodOption()).map((option) => (
                        <Select.Item value={option} key={option}>
                          {selectPeriodOption()[option as SelectPeriodOption]}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                  <span>{t('keywords.every')}</span>
                  <Input
                    className="w-16"
                    size="sm"
                    value={currentFieldValue.repeat?.interval}
                    onChange={(e) => handleRepeatOptionChange('interval', e.target.value, onChange)}
                    onBlur={() => handleResetInputOnBlur(onChange)}
                  />
                  {currentFieldValue.repeat?.frequency
                    ? t(
                        `components.views.form.date_time_input.${
                          repeatOptionPostFix[currentFieldValue.repeat.frequency]
                        }`
                      )
                    : 'components.views.form.date_time_input.days'}
                </>
              )}
            />
          </div>
        </div>
      </div>
      {(currentFieldValue?.repeat?.frequency === 'weekly' ||
        currentFieldValue?.repeat?.frequency === 'monthly') && (
        <div className="flex w-full items-center gap-2">
          <p className="w-24 font-semibold sm:text-right">
            {t('components.views.form.date_time_input.repeats_on')}
          </p>
          <DateTimeRepeatsOnOptions currentFieldValue={currentFieldValue} fieldKey={fieldKey} />
        </div>
      )}
      <div className="flex w-full items-center gap-2">
        <p className="w-24 font-semibold sm:text-right">
          {t('components.views.form.date_time_input.starts_on')}
        </p>
        {currentFieldValue.date}
      </div>
      <div className="gap-2 sm:flex">
        <p className="mb-2 w-24 font-semibold sm:mb-0 sm:text-right">
          {t('components.views.form.date_time_input.ends')}
        </p>
        <Controller
          name={fieldKey}
          render={({ field: { value: fieldValue, onChange } }) => {
            const formattedSelectedEndDate = getFormattedDatePickerDate(
              fieldValue.repeat?.end_date,
              dateFormat
            );
            return (
              <RadioGroup
                onValueChange={(val: 'date' | 'limit' | 'never') =>
                  handleEndOptionChange(val, onChange)
                }
                defaultValue={currentFieldValue.repeat?.endson || 'never'}
                className="space-y-1"
              >
                <RadioGroup.Container className="flex gap-4">
                  <RadioGroup.Item id={`${fieldKey}-date-time-never`} value="never" />
                  <Label htmlFor={`${fieldKey}-date-time-never`}>
                    {t('components.views.form.date_time_input.never')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item value="limit" id={`${fieldKey}-date-time-limit`} />
                  <Label
                    htmlFor={`${fieldKey}-date-time-limit`}
                    className="flex items-center gap-2"
                  >
                    {t('components.views.form.date_time_input.after')}{' '}
                    <Input
                      disabled={currentFieldValue.repeat?.endson !== 'limit'}
                      value={currentFieldValue.repeat?.end_count}
                      onChange={(e) => handleEndInputOcurrencesChange(e, onChange)}
                      className="w-16 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                      size="sm"
                      type="number"
                    />
                    {t('components.views.form.date_time_input.ocurrences')}
                  </Label>
                </RadioGroup.Container>
                <RadioGroup.Container>
                  <RadioGroup.Item value="date" id={`${fieldKey}-date-time-on-date-picker`} />
                  <Label htmlFor={`${fieldKey}-date-time-on-date-picker`} className="space-x-2">
                    <span>{t('keywords.on')}</span>
                    {currentFieldValue.repeat?.endson === 'date' && (
                      <DatePicker
                        onSelect={(val) => handleSelectDate(val, onChange)}
                        selected={formattedSelectedEndDate}
                        mode="single"
                        triggerRef={inputRef}
                        onDatePickerOpenChange={setIsDatePickerOpen}
                      >
                        <Input
                          ref={inputRef}
                          value={fieldValue.repeat?.end_date || ''}
                          placeholder={dateFormat}
                          disabled={currentFieldValue.repeat?.endson !== 'date'}
                          onChange={(e) => handleInputChange(e, onChange)}
                          onClick={(e) => {
                            if (isDatePickerOpen) {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </DatePicker>
                    )}
                  </Label>
                </RadioGroup.Container>
              </RadioGroup>
            );
          }}
        />
      </div>
    </div>
  );
}

import { Controller, type ControllerRenderProps } from 'react-hook-form';
import { MultiSelect, type MultiSelectOption } from '@knack/asterisk-react';

import { type FormViewUserRolesInput } from '@/types/schema/views/form/UserRoles';
import { useUserRolesQuery } from '@/hooks/api/queries/useUserRolesQuery';
import { useViewContext } from '@/components/views/ViewContext';

interface UserRoles {
  key: string;
  label: string;
}

export function UserRolesInput({ input }: { input: FormViewUserRolesInput }) {
  const { data: userRoles = [] } = useUserRolesQuery();
  const { view } = useViewContext();

  function handleUserRolesChange(
    selectedValues: MultiSelectOption[],
    onChange: ControllerRenderProps['onChange']
  ) {
    onChange(selectedValues.map((val) => val.key));
  }

  const getRoleLabelBasedOnKey = (key: string) => {
    const role =
      userRoles.length > 0
        ? userRoles.find((userRole: UserRoles) => userRole.key === key)
        : undefined;
    return role?.label;
  };

  return (
    <Controller
      name={input.field.key}
      render={({ field }) => {
        const formattedValue = field.value
          ? field.value.map((value: string) => ({
              key: value,
              label: getRoleLabelBasedOnKey(value)
            }))
          : [];
        return (
          <MultiSelect
            id={`${view.key}-${input.id}`}
            isSearchEnabled
            maxVisibleChips={5}
            options={userRoles}
            selectedOptions={formattedValue}
            onSelectOptions={(values) => handleUserRolesChange(values, field.onChange)}
          />
        );
      }}
    />
  );
}

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useIMask } from 'react-imask';
import { InputWithDisabledText } from '@knack/asterisk-react';

import { type FormViewCurrencyInput } from '@/types/schema/views/form/Currency';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';

export function CurrencyInput({ input }: { input: FormViewCurrencyInput }) {
  const [t] = useTranslation();
  const {
    register,
    getValues,
    setValue: setFormValue,
    formState: { errors }
  } = useFormContext();
  const { view } = useViewContext();

  const inputValue = getValues(input.field.key);
  const currencyFormat = input.format?.format;
  const isReadOnly = input.read_only;

  const { ref: formRef, onBlur, ...formRegister } = register(input.field.key);

  const {
    ref: inputRef,
    value: iMaskValue,
    typedValue
  } = useIMask<HTMLInputElement>(
    {
      mask: Number,
      scale: 2,
      thousandsSeparator: ',',
      radix: '.',
      padFractionalZeros: true
    },
    {
      defaultValue: inputValue,
      onAccept: (value: string) => {
        setFormValue(input.field.key, value);
      }
    }
  );

  return (
    <div className="relative">
      <InputWithDisabledText
        ref={inputRef}
        id={`${view.key}-${input.id}`}
        disabled={isReadOnly}
        intent={errors[input.field.key] ? 'destructive' : 'default'}
        placeholder={t('components.views.form.currency_input.placeholder')}
        data-testid={`${view.key}-${input.id}-currency-input`}
        value={typedValue !== undefined ? iMaskValue : inputValue}
        {...(currencyFormat === '€_after'
          ? { disabledTextRight: '€' }
          : { disabledTextLeft: currencyFormat })}
        {...formRegister}
      />
      <FormErrorMessage errors={errors} name={input.field.key} />
    </div>
  );
}

import { type LiveAppProfileKey } from '@/types/schema/LiveAppTable';
import { type LoginView } from '@/types/schema/views/LoginView';
import { usePageQuery } from '@/hooks/api/queries/usePageQuery';
import { useUserRolesQuery } from '@/hooks/api/queries/useUserRolesQuery';
import { useViewContext } from '@/components/views/ViewContext';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { usePageContext } from '@/context/PageContext';
import { SignupButton } from './SignupButton';

export function SignupButtons() {
  const { view } = useViewContext<LoginView>();
  const { activePage } = usePageContext();
  const { setActiveAuthFlow, setActiveSignupViewKey } = useAuthFlow();

  const { data: fullPage } = usePageQuery({ pageSlug: activePage?.slug });
  const { data: userRoleObjects } = useUserRolesQuery({
    enabled: view.limitProfileAccess
  });

  if (!view.registrationForms) {
    return null;
  }

  // When the user has not set a specific role for registration, only one button is shown for the `all_users` role
  if (!view.limitProfileAccess) {
    const signupViewKey = view.registrationForms.all_users;

    if (!signupViewKey) {
      return null;
    }

    // If the value is not a string (not a view key), it means that the registration form has not been created yet and it's an empty object.
    // This is the case when allowing user registration via page editor but the changes have not been saved yet (and no registration form has been created).
    if (typeof signupViewKey !== 'string') {
      return null;
    }

    return (
      <SignupButton
        onClick={() => {
          setActiveSignupViewKey(signupViewKey);
          setActiveAuthFlow('signup');
        }}
      />
    );
  }

  if (!userRoleObjects || userRoleObjects.length === 0) {
    return null;
  }

  const registrationForms = Object.entries(view.registrationForms);

  return registrationForms.map((registrationFormEntry) => {
    const profileKey = registrationFormEntry[0] as LiveAppProfileKey;
    const signupViewKey = registrationFormEntry[1];

    if (!signupViewKey) {
      return null;
    }

    const useRoleObject = userRoleObjects.find((role) => role.key === profileKey);

    if (!useRoleObject) {
      return null;
    }

    // If the value is not a string (not a view key), it means that the registration form has not been created yet and it's an empty object.
    // This is the case when restricting access to specific roles in the page editor but the changes have not been saved yet (and no registration forms have been created).
    if (typeof signupViewKey !== 'string') {
      return null;
    }

    const activeSignupView = fullPage?.views.find(
      (potentialSignupView) => potentialSignupView.key === signupViewKey
    );

    if (!activeSignupView) {
      return null;
    }

    return (
      <SignupButton
        key={profileKey}
        userRoleName={registrationForms.length === 1 ? undefined : useRoleObject.label}
        onClick={() => {
          setActiveSignupViewKey(activeSignupView.key);
          setActiveAuthFlow('signup');
        }}
      />
    );
  });
}

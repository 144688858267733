import { HiArrowDown as SortIcon } from 'react-icons/hi2';
import { Table } from '@knack/asterisk-react';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { useViewSearchParams } from '@/hooks/useViewSearchParams';
import { cn } from '@/utils/tailwind';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { getColumnStyles } from './helper';

export function TableViewHeadContent({ column }: { column: TableViewColumn }) {
  const { view } = useViewContext<TableView>();
  const { sortField, sortOrder } = useViewSearchParams(view);

  const columnFieldKey = column?.field?.key;
  const isFieldTypeColumn = column.type === 'field';
  const isThisColumnSorting = sortField === columnFieldKey;

  return (
    <div
      className={cn('flex items-center justify-between gap-2', {
        'justify-center': !isFieldTypeColumn || column.align === 'center',
        'justify-start': column.align === 'left',
        'justify-end': column.align === 'right'
      })}
    >
      {column.header}
      {isThisColumnSorting && (
        <SortIcon
          size={16}
          className={cn('shrink-0', {
            'rotate-180': sortOrder === 'desc'
          })}
        />
      )}
    </div>
  );
}

export function TableViewHead({ column }: { column: TableViewColumn }) {
  const { view } = useViewContext<TableView>();
  const { sortField, sortOrder, setViewParam } = useViewSearchParams(view);
  const { theme } = useThemingContext();

  const handleChangeSorting = (selectedSortField: KnackFieldKey) => {
    if (!sortField) return;

    setViewParam({
      sortField: selectedSortField,
      sortOrder: selectedSortField === sortField && sortOrder === 'asc' ? 'desc' : 'asc'
    });
  };

  const handleClickColumn = () => {
    if (column.type !== 'field') return;

    handleChangeSorting(column.field.key);
  };

  return (
    <Table.Head
      tabIndex={0}
      role="button"
      style={{ ...getColumnStyles(column) }}
      className={cn(
        'h-auto cursor-pointer border-x-0 border-t-0 border-default px-2 py-1 text-default hover:bg-subtle',
        {
          'border-r': theme.tables.border === 'rows-and-columns'
        }
      )}
      onClick={handleClickColumn}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClickColumn();
        }
      }}
    >
      <TableViewHeadContent column={column} />
    </Table.Head>
  );
}

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HiEllipsisHorizontal as EllipsisHorizontalIcon,
  HiTrash as TrashIcon
} from 'react-icons/hi2';
import { MdDragIndicator as DragIcon } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { DropdownMenu } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { PageSection } from '@/pages/page/PageSection';
import { AddNewButton } from './AddNewButton';
import { DragInsertIndicator } from './page-editor-sortable/DragInsertIndicator';
import { usePageEditorContext } from './PageEditorContext';
import { useSectionEditorContext } from './SectionEditorContext';

interface AddSectionButtonProps {
  buttonPosition: 'top' | 'bottom';
  originSectionId: string;
}

interface SectionEditorProps {
  draggingOverInsertPosition?: 'before' | 'after' | null;
  children: React.ReactNode;
}

function AddSectionButton({ buttonPosition, originSectionId }: AddSectionButtonProps) {
  const { updatePage } = usePageEditorContext();

  const onAddSection = (event: React.MouseEvent) => {
    event.stopPropagation();

    updatePage({
      type: 'section',
      action: 'add',
      originSectionId,
      newSectionPosition: buttonPosition === 'top' ? 'above' : 'below'
    });
  };

  return (
    <AddNewButton onClick={onAddSection} position={buttonPosition} tooltip="Add New Section" />
  );
}

export function SectionEditor({ draggingOverInsertPosition, children }: SectionEditorProps) {
  const [t] = useTranslation();

  const { page, updatePage, selectItem, selectedItem, isDraggingActive } = usePageEditorContext();
  const { section, setSectionHover, hasSectionHover, currentHoveredViewKey } =
    useSectionEditorContext();

  const { listeners, setActivatorNodeRef, isDragging } = useSortable({ id: section.id });

  const isSectionSelected =
    selectedItem?.type === 'section' && selectedItem.section.id === section.id;
  const isSectionHovered = hasSectionHover && !currentHoveredViewKey;
  const isSectionActiveState = (isSectionHovered || isSectionSelected) && !isDraggingActive;

  const onSectionDelete = (event: React.MouseEvent) => {
    event.stopPropagation();

    // Do not allow deleting section if it's the only one in the page
    if (page.groups.length === 1) {
      return;
    }

    updatePage({
      type: 'section',
      action: 'delete',
      sectionId: section.id
    });
  };

  function onSectionClick(event: React.MouseEvent) {
    if (event.target !== event.currentTarget) {
      return;
    }

    event.stopPropagation();
    selectItem({ type: 'section', sectionId: section.id });
  }

  function onSectionKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    event.preventDefault();

    if (event.key === 'Enter' || event.key === 'Space') {
      selectItem({ type: 'section', sectionId: section.id });
    }
  }

  useEffect(() => {
    // Reset column hover state when dragging is active
    if (isDraggingActive) {
      setSectionHover(false);
    }
  }, [isDraggingActive, setSectionHover]);

  return (
    <>
      <DragInsertIndicator isVisible={draggingOverInsertPosition === 'before'} className="mb-4" />

      <section
        role="button"
        tabIndex={0}
        onClick={onSectionClick}
        onKeyDown={onSectionKeyDown}
        {...(!isDraggingActive && {
          onMouseEnter: () => setSectionHover(true),
          onMouseLeave: () => setSectionHover(false)
        })}
        className={cn('relative rounded-lg border border-subtle bg-default p-3 pt-8', {
          'border-blue-500': isSectionActiveState,
          'opacity-20': isDragging
        })}
      >
        <PageSection>{children}</PageSection>

        <div
          className={cn(
            'border-1 absolute -left-[1px] top-0 rounded-br-md rounded-tl-lg border-subtle bg-subtle',
            {
              'border-blue-500 bg-blue-500': isSectionActiveState
            }
          )}
        >
          <div
            className={cn('flex h-6 items-center px-1 text-sm', {
              'text-white': isSectionActiveState
            })}
          >
            <button
              type="button"
              className="mr-1 inline-flex size-5 cursor-move items-center justify-center rounded-md hover:bg-white/25"
              ref={setActivatorNodeRef}
              {...listeners}
            >
              <DragIcon size={16} />
            </button>
            <span className="mr-2">{t('components.page_editor.section')}</span>
            <DropdownMenu>
              <DropdownMenu.Trigger asChild>
                <button
                  type="button"
                  aria-label={t('components.page_editor.section_options')}
                  className={cn(
                    'inline-flex size-5 items-center justify-center rounded-md hover:bg-white/25 focus:outline-0',
                    {
                      'text-white hover:text-white': isSectionActiveState
                    }
                  )}
                >
                  <EllipsisHorizontalIcon size={14} />
                </button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content className="min-w-44 p-1 text-sm" align="start">
                <DropdownMenu.Item
                  className="p-1"
                  onClick={onSectionDelete}
                  disabled={page.groups.length === 1}
                >
                  <TrashIcon size={14} className="mr-1" />
                  {t('actions.delete')}
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu>
          </div>
        </div>

        {isSectionHovered && (
          <>
            <AddSectionButton buttonPosition="top" originSectionId={section.id} />
            <AddSectionButton buttonPosition="bottom" originSectionId={section.id} />
          </>
        )}
      </section>

      <DragInsertIndicator isVisible={draggingOverInsertPosition === 'after'} className="mt-4" />
    </>
  );
}

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

export function PageHelmet({ pageName }: { pageName: string }) {
  const [, { language }] = useTranslation();
  const { data: application } = useApplicationQuery();

  if (!application) return null;

  return (
    <Helmet>
      <title lang={language}>{`${pageName} - ${application.name}`}</title>
    </Helmet>
  );
}

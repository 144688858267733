import { Collapsible } from '@knack/asterisk-react';

import type { LiveAppPage } from '@/types/schema/LiveAppPage';
import { NavItemWrapper } from '@/components/main-navigation/NavItemWrapper';
import { useNavigation } from '@/components/main-navigation/useNavigation';

export function NavVerticalItem({ item, isChild }: { item: LiveAppPage; isChild?: boolean }) {
  const { childrenItems, shouldDisplayChildren, isActive } = useNavigation({ item });

  return shouldDisplayChildren ? (
    <Collapsible defaultOpen={isActive}>
      <Collapsible.Trigger className="group flex w-full items-center text-subtle">
        <NavItemWrapper item={item} isActive={false} isDropdownMenu />
      </Collapsible.Trigger>

      <Collapsible.Content className="mt-1 flex flex-col gap-1">
        {childrenItems?.map((childItem) => (
          <NavVerticalItem key={childItem.key} item={childItem} isChild />
        ))}
      </Collapsible.Content>
    </Collapsible>
  ) : (
    <NavItemWrapper item={item} isActive={isActive} isChild={isChild} />
  );
}

import { forwardRef } from 'react';

import { type FormViewGroupColumn } from '@/types/schema/views/FormView';
import { DragInsertIndicator } from '@/pages/page/page-editor/page-editor-sortable/DragInsertIndicator';

interface FormGroupColumnEditorProps {
  column: FormViewGroupColumn;
  isDraggingOver: boolean;
  children: React.ReactNode;
}

function FormGroupColumnEditorWithRef(
  { column, isDraggingOver, children }: FormGroupColumnEditorProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <section ref={ref} className="relative flex min-w-0 flex-1 flex-col gap-4">
      <DragInsertIndicator isVisible={isDraggingOver && column.inputs.length === 0} />
      {column.inputs.length === 0 && !isDraggingOver ? (
        <div
          aria-hidden="true"
          className="relative flex h-full items-center justify-center rounded-md border-2 border-dashed border-subtle p-4 hover:border-default"
        />
      ) : (
        children
      )}
    </section>
  );
}

export const FormGroupColumnEditor = forwardRef<HTMLDivElement, FormGroupColumnEditorProps>(
  FormGroupColumnEditorWithRef
);

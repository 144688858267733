export function isIframedByBuilder() {
  const referrerOrigin = document.referrer ? new URL(document.referrer).origin : null;
  const isBuilderOrigin = referrerOrigin === import.meta.env.PUBLIC_BUILDER_URL;
  const isIframed = window.self !== window.top;

  return isIframed && isBuilderOrigin;
}

export function isPageEditorPreview() {
  const urlParams = new URLSearchParams(window.location.search);
  return isIframedByBuilder() && urlParams.get('preview') === 'true';
}

export function isPageEditor() {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    isIframedByBuilder() && (!urlParams.has('preview') || urlParams.get('preview') === 'false')
  );
}

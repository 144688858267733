import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Banner } from '@knack/asterisk-react';

import { useConfirmResetPasswordMutation } from '@/hooks/api/mutations/password-management/useConfirmResetPasswordMutation';
import { ResetPasswordForm } from './ResetPasswordForm';

export function ResetPasswordWrapper() {
  const [t] = useTranslation();
  const params = useParams();

  const confirmResetPasswordMutation = useConfirmResetPasswordMutation();

  const [passwordResetToken, setPasswordResetToken] = useState('');
  const [forgottenEmail, setForgottenEmail] = useState('');
  const [hasError, setHasError] = useState(false);

  const onConfirmResetToken = () => {
    const tokenFromUrl = params.token;

    if (!tokenFromUrl) {
      setHasError(true);
      return;
    }

    setPasswordResetToken(tokenFromUrl);
    confirmResetPasswordMutation.mutate(
      { token: tokenFromUrl },
      {
        onSuccess: (response) => {
          setForgottenEmail(response.data?.user?.values?.email?.email);
        },
        onError: () => {
          setHasError(true);
        }
      }
    );
  };

  useEffect(() => {
    onConfirmResetToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {hasError && (
        <Banner intent="destructive" className="mb-10">
          <Banner.Message>{t('auth.reset.invalid_link_error')}</Banner.Message>
        </Banner>
      )}
      {forgottenEmail && (
        <ResetPasswordForm
          passwordResetToken={passwordResetToken}
          forgottenEmail={forgottenEmail}
        />
      )}
    </>
  );
}

import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Combobox,
  Label,
  MultiSelect,
  RadioGroup,
  useToast,
  type ComboboxOption,
  type MultiSelectOption
} from '@knack/asterisk-react';

import { type FormViewMultipleChoiceInput } from '@/types/schema/views/form/MultipleChoice';
import { type FormView } from '@/types/schema/views/FormView';
import { useAddMultipleChoiceOptionMutation } from '@/hooks/api/mutations/useAddMultipleChoiceOptionMutation';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';
import { AddOptionButton } from './AddOptionButton';

export function MultipleChoiceInput({ input }: { input: FormViewMultipleChoiceInput }) {
  const [t] = useTranslation();
  const {
    formState: { errors }
  } = useFormContext();
  const { presentToast } = useToast();
  const { view } = useViewContext<FormView>();
  const { allow_option_inserts: allowOptionInserts } = input;

  const { mutate: addNewOption, isSuccess: isAddNewOptionSuccess } =
    useAddMultipleChoiceOptionMutation();

  const blankOption = input.format?.blank; // This can be an empty string too
  const hasBlankOption = input.format?.default === 'kn-blank';
  const placeholder = hasBlankOption
    ? blankOption
    : t('components.views.form.multiple_choice.select');
  const multiChoiceType = input.format?.type;
  const inputOptions = input.format?.options || [];
  const isReadOnly = input.read_only;

  function handleCheckboxChange(
    option: string,
    fieldValue: ControllerRenderProps['value'],
    onChange: ControllerRenderProps['onChange']
  ) {
    if (fieldValue.includes(option)) {
      onChange(fieldValue.filter((item: string) => item !== option));
    } else {
      onChange([...fieldValue, option]);
    }
  }

  const handleAddOption = (
    option: string,
    onChange: ControllerRenderProps['onChange'],
    selectedValues?: MultiSelectOption[]
  ) => {
    addNewOption(
      {
        viewKey: view.key,
        fieldKey: input.field.key,
        option
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.views.form.multiple_choice.new_option_success'),
            intent: 'success'
          });

          if (multiChoiceType === 'multi' || multiChoiceType === 'checkboxes') {
            onChange([...(selectedValues || []), option]);
            return;
          }

          onChange(option);
        },
        onError: () => {
          presentToast({
            title: t('components.views.form.multiple_choice.new_option_error'),
            intent: 'destructive'
          });
        }
      }
    );
  };

  function handleMultiSelectChange(
    selectedValues: MultiSelectOption[],
    onChange: ControllerRenderProps['onChange']
  ) {
    onChange(selectedValues.map((val) => val.value));
  }

  function handleSingleSelectChange(
    option: ComboboxOption,
    onChange: ControllerRenderProps['onChange']
  ) {
    if (option.key === 'kn-blank' || option.key === blankOption) {
      onChange('');
      return;
    }
    onChange(option.key);
  }

  if (multiChoiceType === 'radios') {
    return (
      <Controller
        name={input.field.key}
        render={({ field }) => (
          <>
            <RadioGroup disabled={isReadOnly} value={field.value} onValueChange={field.onChange}>
              {inputOptions.map((option, index) => {
                const radioInputId = `${view.key}-${input.id}-radio-${index}`;
                return (
                  <RadioGroup.Container key={radioInputId}>
                    <RadioGroup.Item value={option} id={radioInputId} />
                    <Label className="text-sm" htmlFor={radioInputId}>
                      {option}
                    </Label>
                  </RadioGroup.Container>
                );
              })}
            </RadioGroup>
            <FormErrorMessage errors={errors} name={input.field.key} />
            {allowOptionInserts && !isReadOnly && (
              <AddOptionButton
                field={field}
                isAddNewOptionSuccess={isAddNewOptionSuccess}
                handleSubmit={handleAddOption}
              />
            )}
          </>
        )}
      />
    );
  }

  if (multiChoiceType === 'checkboxes') {
    return (
      <Controller
        name={input.field.key}
        render={({ field }) => (
          <>
            {inputOptions.map((option, index) => {
              const checkboxesInputId = `${view.key}-${input.id}-checkbox-${index}`;
              return (
                <div className="flex items-center gap-2" key={checkboxesInputId}>
                  <Checkbox
                    disabled={isReadOnly}
                    checked={field.value?.includes(option)}
                    id={checkboxesInputId}
                    onClick={() => {
                      handleCheckboxChange(option, field.value, field.onChange);
                    }}
                  />
                  <Label className="text-sm" htmlFor={checkboxesInputId}>
                    {option}
                  </Label>
                </div>
              );
            })}
            <FormErrorMessage errors={errors} name={input.field.key} />
            {allowOptionInserts && !isReadOnly && (
              <AddOptionButton
                field={field}
                isAddNewOptionSuccess={isAddNewOptionSuccess}
                handleSubmit={handleAddOption}
              />
            )}
          </>
        )}
      />
    );
  }

  if (multiChoiceType === 'multi') {
    const formattedInputOptions = inputOptions.map((option) => ({
      label: option,
      value: option,
      key: option
    }));

    return (
      <Controller
        name={input.field.key}
        render={({ field }) => {
          const fieldValues = field.value.map((val: string) => ({
            label: val,
            value: val,
            key: val
          }));

          return (
            <>
              <MultiSelect
                disabled={isReadOnly}
                intent={errors[input.field.key] ? 'destructive' : 'default'}
                id={`${view.key}-${input.id}-multiselect`}
                options={formattedInputOptions}
                selectedOptions={fieldValues}
                maxVisibleChips={5}
                isSearchEnabled
                onSelectOptions={(values) => handleMultiSelectChange(values, field.onChange)}
                onAddOption={
                  allowOptionInserts && !isReadOnly
                    ? (option) => handleAddOption(option, field.onChange, field.value)
                    : undefined
                }
              />
              <FormErrorMessage errors={errors} name={input.field.key} />
              {allowOptionInserts && !isReadOnly && (
                <AddOptionButton
                  field={field}
                  isAddNewOptionSuccess={isAddNewOptionSuccess}
                  handleSubmit={handleAddOption}
                />
              )}
            </>
          );
        }}
      />
    );
  }

  // If multiChoiceType is not defined, we load the single select as default
  if (multiChoiceType === 'single' || multiChoiceType === undefined) {
    return (
      <Controller
        name={input.field.key}
        render={({ field }) => {
          const options =
            hasBlankOption && inputOptions ? [blankOption, ...inputOptions] : inputOptions;
          const formattedOptions = options.map((option) => ({
            key: option || '',
            label: option || ''
          }));

          const selectedOption =
            field.value === 'kn-blank'
              ? { key: 'kn-blank', label: '' }
              : { key: field.value, label: field.value };

          return (
            <>
              <Combobox
                id={`${view.key}-${input.id}-combobox-single-multiple-choice`}
                placeholder={placeholder}
                intent={errors[input.field.key] ? 'destructive' : 'default'}
                options={formattedOptions}
                disabled={isReadOnly}
                selectedOption={selectedOption}
                addOptionText={t('actions.add')}
                onAddOption={
                  allowOptionInserts && !isReadOnly
                    ? (option) => handleAddOption(option, field.onChange)
                    : undefined
                }
                onSelectOption={(option) => handleSingleSelectChange(option, field.onChange)}
              />
              <FormErrorMessage errors={errors} name={input.field.key} />
              {allowOptionInserts && !isReadOnly && (
                <AddOptionButton
                  field={field}
                  isAddNewOptionSuccess={isAddNewOptionSuccess}
                  handleSubmit={handleAddOption}
                />
              )}
            </>
          );
        }}
      />
    );
  }

  return null;
}

import { type FormView } from '@/types/schema/views/FormView';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { FormGroupsSortable } from './FormGroupsSortable';

interface FormGroupsSortableWrapperProps {
  view: FormView;
  children: React.ReactNode;
}

export function FormGroupsSortableWrapper({ view, children }: FormGroupsSortableWrapperProps) {
  const { selectedItem } = usePageEditorContext();

  if (!selectedItem || selectedItem.type !== 'view') {
    return children;
  }

  // Show the sortable form inputs for active form views, and form views with an active child form view
  const isViewActive = selectedItem.view.key === view.key;
  const isChildFormViewActive =
    selectedItem.view.type === 'form' && selectedItem.view.parent === view.key;

  if (isViewActive || isChildFormViewActive) {
    return <FormGroupsSortable view={view} />;
  }

  return children;
}

import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { HiOutlineDotsVertical as DotsIcon } from 'react-icons/hi';
import { Button, DropdownMenu } from '@knack/asterisk-react';

import type { LiveAppPage } from '@/types/schema/LiveAppPage';
import { useEntryPagesQuery } from '@/hooks/api/queries/useEntryPagesQuery';
import { NavBrand } from '@/components/main-navigation/NavBrand';
import { NavItemProfile } from '@/components/main-navigation/NavItemProfile';
import { useThemingContext } from '@/context/ThemingContext';
import { NavHorizontalItem } from './NavHorizontalItem';

export function NavHorizontalContainer({
  containerclasses,
  navBrandRef
}: {
  containerclasses: string;
  navBrandRef: React.RefObject<HTMLDivElement>;
}) {
  const { theme } = useThemingContext();
  const headerRef = useRef<HTMLDivElement>(null);
  const navRef = useRef<HTMLDivElement>(null);
  const { data: entryPages } = useEntryPagesQuery();
  const [visibleItems, setVisibleItems] = useState<LiveAppPage[]>([]);
  const [hiddenItems, setHiddenItems] = useState<LiveAppPage[]>([]);
  const [isMeasuring, setIsMeasuring] = useState(true);
  const pagesWithoutParent = useMemo(() => {
    setIsMeasuring(true);
    return entryPages?.filter((page) => !page.menuPageKey && page.type !== 'user') || [];
  }, [entryPages]);

  useLayoutEffect(() => {
    const measureItems = () => {
      if (
        !headerRef.current ||
        !navBrandRef.current ||
        !navRef.current ||
        navRef.current?.children.length === 0
      ) {
        return;
      }

      const headerWidth = headerRef.current.offsetWidth;
      const brandWidth = navBrandRef.current.offsetWidth;
      let navCurrentWidth = 0;
      const newVisibleItems: LiveAppPage[] = [];
      const newHiddenItems: LiveAppPage[] = [];

      // First, measure widths of all items
      const itemElements: HTMLCollection = navRef.current.children;
      Array.from(itemElements).forEach((itemElement, index) => {
        const itemWidth = itemElement.clientWidth + 32; // 32px gap between items
        const item = pagesWithoutParent[index];

        if (navCurrentWidth + itemWidth < headerWidth - brandWidth - 50) {
          // 50px space for the profile button
          newVisibleItems.push(item);
          navCurrentWidth += itemWidth;
        } else {
          newHiddenItems.push(item);
        }
      });

      setVisibleItems(newVisibleItems);
      setHiddenItems(newHiddenItems);
      setIsMeasuring(false);
    };

    if (isMeasuring) {
      measureItems();
    }

    const handleResize = () => {
      setIsMeasuring(true); // Trigger re-measurement
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [pagesWithoutParent, isMeasuring, headerRef, navRef, navBrandRef]);

  return (
    <header ref={headerRef} className={containerclasses}>
      <div className="flex items-center justify-between">
        <NavBrand navBrandRef={navBrandRef} />
        <div className="flex items-center">
          {theme.mainNavContainer.navigationMenu.isVisible && (
            <nav ref={navRef} className="flex items-center gap-4 text-center">
              {(isMeasuring ? pagesWithoutParent : visibleItems).map((item) => (
                <NavHorizontalItem key={item.key} item={item} allItems={entryPages || []} />
              ))}
              {!isMeasuring && hiddenItems.length > 0 && (
                <DropdownMenu>
                  <DropdownMenu.Trigger asChild>
                    <Button intent="minimal" size="sm">
                      <Button.Icon icon={DotsIcon} />
                    </Button>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content>
                    {hiddenItems.map((item) => (
                      <NavHorizontalItem
                        key={item.key}
                        item={item}
                        allItems={entryPages || []}
                        shouldUseSubmenu
                      />
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu>
              )}
            </nav>
          )}
          <div className="ml-4">
            <NavItemProfile />
          </div>
        </div>
      </div>
    </header>
  );
}

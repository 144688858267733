import { type FormView } from '@/types/schema/views/FormView';

export const formViewDemoView: FormView = {
  id: '664b0b939b49ff01368c40fd',
  submitButtonText: 'Submit',
  columns: [],
  links: [],
  inputs: [],
  groups: [
    {
      id: 'group_1',
      columns: [
        {
          id: 'column_1',
          inputs: [
            {
              id: 'field_54',
              instructions: 'This is hint text',
              field: {
                key: 'field_54'
              },
              label: 'Short Text Input',
              type: 'short_text'
            },
            {
              id: 'field_62',
              instructions: '',
              field: {
                key: 'field_62'
              },
              label: 'Date From / To',
              type: 'date_time',
              format: {
                calendar: true,
                date_format: 'mm/dd/yyyy',
                default_time: '',
                default_type: 'current',
                time_format: 'HH:MM am',
                time_type: 'current'
              }
            },
            {
              id: 'field_56',
              instructions: '',
              field: {
                key: 'field_56'
              },
              label: 'Rich Text Input',
              type: 'rich_text'
            },
            {
              id: 'field_66',
              instructions: '',
              field: {
                key: 'field_66'
              },
              label: 'Link',
              type: 'link',
              format: {
                target: '_self',
                text_format: 'url'
              }
            },
            {
              id: 'field_60',
              instructions: '',
              field: {
                key: 'field_60'
              },
              label: 'Yes/No',
              type: 'boolean',
              format: {
                default: false,
                format: 'yes_no',
                input: 'dropdown',
                required: false
              }
            }
          ]
        },
        {
          id: 'column_2',
          inputs: [
            {
              id: 'field_89',
              instructions: 'This is hint text',
              field: {
                key: 'field_89'
              },
              label: 'Non-Editable Input',
              type: 'short_text',
              read_only: true
            },
            {
              id: 'field_58',
              instructions: '',
              field: {
                key: 'field_58'
              },
              label: 'Currency Input',
              type: 'currency',
              format: {
                format: '$'
              }
            },
            {
              id: 'field_55',
              instructions: '',
              field: {
                key: 'field_55'
              },
              label: 'Long Text Input',
              type: 'paragraph_text'
            },
            {
              id: 'field_68',
              instructions: '',
              field: {
                key: 'field_68'
              },
              label: 'Rating',
              type: 'rating',
              format: {
                allow_half: false,
                max: '5',
                method: 'stars',
                min: 1,
                precision: 2
              }
            },
            {
              id: 'field_57',
              instructions: '',
              field: {
                key: 'field_57'
              },
              label: 'Number',
              type: 'number',
              format: {
                format: 'none',
                mark_decimal: 'none',
                mark_thousands: 'none',
                precision: 2,
                rounding: 'none'
              }
            },
            {
              instructions: '',
              field: {
                key: 'field_69'
              },
              id: 'field_69',
              label: 'Phone',
              type: 'phone',
              format: {
                extension: false,
                format: '(999) 999-9999'
              }
            },
            {
              instructions: '',
              field: {
                key: 'field_64'
              },
              id: 'field_64',
              label: 'Person',
              type: 'name',
              format: {
                format: 'Title First Last'
              }
            }
          ]
        }
      ]
    },
    {
      id: 'group_2',
      columns: [
        {
          id: 'column_3',
          inputs: [
            {
              id: 'divider_1',
              instructions: '',
              type: 'divider'
            }
          ]
        }
      ]
    },
    {
      id: 'group_3',
      columns: [
        {
          id: 'column_4',
          inputs: [
            {
              id: 'field_61',
              instructions: '',
              field: {
                key: 'field_61'
              },
              label: 'Date/Time',
              type: 'date_time',
              format: {
                calendar: false,
                date_format: 'mm/dd/yyyy',
                default_time: '',
                default_type: 'current',
                time_format: 'Ignore Time',
                time_type: 'current'
              }
            }
          ]
        },
        {
          id: 'column_5',
          inputs: [
            {
              id: 'field_59',
              instructions: '',
              field: {
                key: 'field_59'
              },
              label: 'Multiple Choice',
              type: 'multiple_choice',
              format: {
                blank: 'Select...',
                default: 'kn-blank',
                options: ['First Choice', 'Second Choice', 'Third Choice'],
                sorting: 'alphabetical',
                type: 'multi'
              }
            }
          ]
        }
      ]
    },
    {
      id: 'group_4',
      columns: [
        {
          id: 'column_6',
          inputs: [
            {
              id: 'divider_2',
              instructions: '',
              type: 'divider'
            }
          ]
        }
      ]
    },
    {
      id: 'group_5',
      columns: [
        {
          id: 'column_7',
          inputs: [
            {
              id: 'field_65',
              instructions: '',
              field: {
                key: 'field_65'
              },
              label: 'Address',
              type: 'address',
              format: {
                enable_autocomplete: false,
                format: 'US',
                input: 'address'
              }
            }
          ]
        }
      ]
    },
    {
      id: 'group_6',
      columns: [
        {
          id: 'column_8',
          inputs: [
            {
              id: 'divider_3',
              instructions: '',
              type: 'divider'
            }
          ]
        }
      ]
    },
    {
      id: 'group_7',
      columns: [
        {
          id: 'column_9',
          inputs: [
            {
              id: 'field_67',
              instructions: '',
              field: {
                key: 'field_67'
              },
              label: 'Signature',
              type: 'signature'
            }
          ]
        },
        {
          id: 'column_10',
          inputs: [
            {
              id: 'field_63',
              instructions: '',
              field: {
                key: 'field_63'
              },
              label: 'File Upload',
              type: 'file',
              format: {
                secure: false
              }
            }
          ]
        }
      ]
    },
    {
      id: 'group_8',
      columns: [
        {
          id: 'column_11',
          inputs: [
            {
              id: 'divider_4',
              instructions: '',
              type: 'divider'
            }
          ]
        }
      ]
    }
  ],
  rules: {
    submits: [
      {
        key: 'submit_1',
        action: 'message',
        message: 'Form successfully submitted.',
        reload_show: true,
        is_default: true
      }
    ],
    fields: [],
    records: [],
    emails: []
  },
  type: 'form',
  reportType: null,
  name: 'Add Demo Form',
  source: {
    criteria: {
      match: 'all',
      rules: [],
      groups: []
    },
    limit: '',
    sort: [
      {
        field: 'field_194',
        order: 'asc'
      }
    ],
    object: 'object_22'
  },
  action: 'create',
  title: 'Form Element',
  description: '',
  alert: 'none',
  key: 'view_100',
  design: {}
};

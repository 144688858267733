import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { type FormViewGroupColumn } from '@/types/schema/views/FormView';
import { FormGroupColumnEditor } from './FormGroupColumnEditor';
import { SortableFormInput } from './SortableFormInput';

export function DroppableFormGroupColumn({ column }: { column: FormViewGroupColumn }) {
  // This is needed so empty columns can be droppable
  const { setNodeRef, over } = useDroppable({
    id: column.id,
    data: {
      type: 'column',
      isEmpty: column.inputs.length === 0
    }
  });

  return (
    <SortableContext id={column.id} items={column.inputs}>
      <FormGroupColumnEditor
        column={column}
        ref={setNodeRef}
        isDraggingOver={over?.id === column.id}
      >
        <div className="flex flex-1 flex-col">
          {column.inputs.map((input) => (
            <SortableFormInput key={input.id} input={input} column={column} />
          ))}
        </div>
      </FormGroupColumnEditor>
    </SortableContext>
  );
}

import { queryOptions, useQuery } from '@tanstack/react-query';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function getEntryPages(accountSlug: string, appSlug: string) {
  const { data: entryPages } = await axios.get<LiveAppPage[]>(
    `/v1/live-app/${accountSlug}/${appSlug}/entry-pages`,
    {
      withCredentials: true
    }
  );
  return entryPages;
}

export function entryPagesQuery() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return queryOptions({
    queryKey: [queryKeys.entryPages, accountSlug, appSlug],
    queryFn: async () => getEntryPages(accountSlug, appSlug)
  });
}

export function useEntryPagesQuery() {
  return useQuery(entryPagesQuery());
}

import { Button } from '@knack/asterisk-react';

import { type MenuView } from '@/types/schema/views/MenuView';
import { cn } from '@/utils/tailwind';
import { usePageContext } from '@/context/PageContext';
import { MenuLinkContent } from './MenuLinkContent';

export function MenuTabs({ menuView }: { menuView: MenuView }) {
  const { activePage } = usePageContext();

  if (menuView.links.length === 0) {
    return null;
  }

  return (
    <div className="relative flex flex-wrap gap-y-2 after:absolute after:bottom-0 after:h-0.5 after:w-full after:bg-emphasis">
      {menuView.links.map((link) => {
        const isActive = link.type !== 'url' && link.scene === activePage?.slug;

        return (
          <Button
            key={link.id}
            intent="minimal"
            className={cn(
              'relative rounded-none font-normal text-default after:absolute after:bottom-0 after:z-10 after:h-0.5 after:w-full after:bg-emphasis hover:bg-transparent hover:text-brand-default hover:after:bg-brand-accent',
              {
                'text-brand-default after:bg-brand-accent': isActive
              }
            )}
            asChild
          >
            <MenuLinkContent link={link} />
          </Button>
        );
      })}
    </div>
  );
}

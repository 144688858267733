import { type Control } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';

import { isIframedByBuilder } from '@/utils/iframe';

export function ReactHookFormDevTools({ control }: { control: Control<any> }) {
  if (isIframedByBuilder() || process.env.NODE_ENV !== 'development') {
    return null;
  }
  return <DevTool control={control} />;
}

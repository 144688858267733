import { useCallback, useEffect, useState } from 'react';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import {
  type DetailsDisplayRuleAction,
  type DetailsView,
  type DetailsViewDisplayRule
} from '@/types/schema/views/DetailsView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isEveryCriteriaMet } from '@/utils/criteriaRules';
import { useViewContext } from '@/components/views/ViewContext';

export function useDetailsDisplayRules(
  recordValues: ViewRecord,
  displayRules: DetailsViewDisplayRule[] | undefined
) {
  const [activeDisplayRuleActions, setActiveDisplayRuleActions] = useState<
    DetailsDisplayRuleAction[]
  >([]);

  const { sourceTable } = useViewContext<DetailsView>();

  const processDisplayRuleActions = useCallback(
    (fieldKey: KnackFieldKey) => {
      const { label, shouldHideField } =
        activeDisplayRuleActions.reduce(
          (acc, action) => {
            if (action.field === fieldKey) {
              switch (action.action) {
                case 'show':
                  acc.shouldHideField = false;
                  break;
                case 'hide':
                  acc.shouldHideField = true;
                  break;
                case 'label':
                  acc.label = action.value;
                  break;
                default:
                  break;
              }
            }
            return acc;
          },
          { label: '', shouldHideField: false }
        ) || {};

      return {
        label,
        shouldHideField
      };
    },
    [activeDisplayRuleActions]
  );

  const checkActiveDetailsDisplayRules = useCallback(
    (data: ViewRecord, rules: DetailsViewDisplayRule[]) => {
      const actions: DetailsDisplayRuleAction[] = [];

      rules.forEach((rule) => {
        if (isEveryCriteriaMet(data, sourceTable.fields, rule.criteria)) {
          rule.actions.forEach((action) => {
            const isExistingType = actions.some((a) => a.action === action.action);

            // If the action type already exists, only keep the last one
            if (isExistingType) {
              const existingAction = actions.find((a) => a.action === action.action);

              if (existingAction) {
                if (existingAction.field === action.field) {
                  actions.splice(actions.indexOf(existingAction), 1);
                }
              }
            }
            actions.push(action);
          });
        }
      });

      setActiveDisplayRuleActions(actions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceTable.fields, activeDisplayRuleActions]
  );

  useEffect(() => {
    if (displayRules) {
      checkActiveDetailsDisplayRules(recordValues, displayRules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordValues]);

  return {
    activeDisplayRuleActions,
    processDisplayRuleActions
  };
}

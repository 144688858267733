import { type ComboboxOption } from '@knack/asterisk-react';

import { type ListView } from '@/types/schema/views/ListView';
import { ViewFiltersPopover } from '@/components/views/view-options/ViewFiltersPopover';

export function ListViewFiltersPopover({ view }: { view: ListView }) {
  const columnOptions: ComboboxOption[] = view.columns[0].groups[0].columns.reduce(
    (acc: ComboboxOption[], column) => {
      column.forEach((col) => {
        if (col.type === 'field') {
          acc.push({
            key: col.key,
            label: col.name
          });
        }
      });
      return acc;
    },
    []
  );

  return <ViewFiltersPopover view={view} columnOptions={columnOptions} />;
}

import { forwardRef } from 'react';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { type LiveAppPageColumn } from '@/types/schema/LiveAppPage';
import { cn } from '@/utils/tailwind';
import { DragInsertIndicator } from './page-editor-sortable/DragInsertIndicator';
import { usePageEditorContext } from './PageEditorContext';
import { useSectionEditorContext } from './SectionEditorContext';

interface ColumnEditorProps {
  column: LiveAppPageColumn;
  isDraggingOver: boolean;
  children: React.ReactNode;
}

function AddViewButton({ columnId, sectionId }: { columnId: string; sectionId: string }) {
  const { startAddView } = usePageEditorContext();

  const onAddView = (event: React.MouseEvent) => {
    event.stopPropagation();
    startAddView({
      columnId,
      sectionId
    });
  };

  return (
    <Button intent="secondary" onClick={onAddView}>
      <PlusIcon className="mr-2" /> Element
    </Button>
  );
}

function ColumnEditorWithRef(
  { column, isDraggingOver, children }: ColumnEditorProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { section } = useSectionEditorContext();

  return (
    <section
      ref={ref}
      className={cn('relative flex min-w-0 flex-1 flex-col gap-4', {
        'basis-1/4': column.width === '25%',
        'basis-1/3': column.width === '33%',
        'basis-1/2': column.width === '50%',
        'basis-2/3': column.width === '66%',
        'basis-3/4': column.width === '75%',
        'h-20': column.viewKeys.length === 0 && section.columns.length === 1
      })}
    >
      <DragInsertIndicator isVisible={isDraggingOver && column.viewKeys.length === 0} />

      {column.viewKeys.length === 0 && !isDraggingOver ? (
        <div className="relative flex h-20 flex-col gap-4 rounded-md border-2 border-dashed border-subtle p-4 hover:border-default">
          <div className="flex items-center justify-center text-gray-400">
            <AddViewButton columnId={column.id} sectionId={section.id} />
          </div>
        </div>
      ) : (
        children
      )}
    </section>
  );
}

export const ColumnEditor = forwardRef<HTMLDivElement, ColumnEditorProps>(ColumnEditorWithRef);

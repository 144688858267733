import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';

interface DeleteRecordParams {
  pageKey: string;
  viewKey: string;
  recordId: string;
}

export async function deleteRecord({ pageKey, viewKey, recordId }: DeleteRecordParams) {
  const url = `/v1/scenes/${pageKey}/views/${viewKey}/records/${recordId}`;
  const response = await axios.delete(url, { withCredentials: true });
  return response;
}

export function useDeleteRecordMutation() {
  const queryClient = useQueryClient();
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useMutation({
    mutationFn: ({ viewKey, recordId }: { viewKey: string; recordId: string }) =>
      deleteRecord({ pageKey: activePage.key, viewKey, recordId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.viewData]
      });
    }
  });
}

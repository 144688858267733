import { useEffect, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { useEntryPagesQuery } from '@/hooks/api/queries/useEntryPagesQuery';
import { cn } from '@/utils/tailwind';
import { HamburgerToggle } from '@/components/main-navigation/mobile/HamburgerToggle';
import { NavMobileItem } from '@/components/main-navigation/mobile/NavMobileItem';
import { NavBrand } from '@/components/main-navigation/NavBrand';
import { NavItemProfile } from '@/components/main-navigation/NavItemProfile';
import { useThemingContext } from '@/context/ThemingContext';

export function NavMobileContainer({ containerclasses }: { containerclasses: string }) {
  const { theme } = useThemingContext();
  const { data: entryPages } = useEntryPagesQuery();
  const pagesWithoutParent = useMemo(
    () => entryPages?.filter((page) => !page.menuPageKey) || [],
    [entryPages]
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Prevent body scrolling when the mobile nav is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const containerAnimationVariants = {
    open: {
      x: 0,
      transition: {
        duration: 0.2
      }
    },
    closed: {
      x: '100%',
      transition: {
        duration: 0.2
      }
    }
  };

  return (
    <>
      <header
        className={cn(
          containerclasses,
          'flex h-[60px] flex-col justify-center overflow-hidden bg-navigation transition-[background-color] duration-200 ease-in-out md:hidden',
          {
            'w-full': isOpen
          }
        )}
      >
        <div className="flex flex-1 items-center justify-between">
          <NavBrand />
          {theme.mainNavContainer.navigationMenu.isVisible && (
            <HamburgerToggle isOpen={isOpen} setIsOpen={setIsOpen} />
          )}
        </div>
      </header>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="mobile-nav"
            className="fixed inset-0 top-[60px] z-20 flex w-full flex-col overflow-y-auto bg-navigation p-4 md:hidden"
            variants={containerAnimationVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <div className="flex grow flex-col">
              <div className="mb-auto">
                {pagesWithoutParent.map((item) => (
                  <NavMobileItem key={item.key} item={item} setIsOpen={setIsOpen} hasDivider />
                ))}
              </div>
              <div className="mt-4">
                <NavItemProfile />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

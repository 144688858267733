import { memo } from 'react';
import { Card } from '@knack/asterisk-react';

import { type KnackViewType, type LiveAppView } from '@/types/schema/LiveAppView';
import { LoginViewRender } from './auth/login/LoginViewRender';
import { DetailsViewRender } from './details/DetailsViewRender';
import { FallBackView } from './FallBackView';
import { FormViewRender } from './form/FormViewRender';
import { ListViewRender } from './list/ListViewRender';
import { MenuViewRender } from './menu/MenuViewRender';
import { ReportViewRender } from './report/ReportViewRender';
import { RichTextViewRender } from './rich-text/RichTextViewRender';
import { TableViewRender } from './table/TableViewRender';
import { ViewContextProvider } from './ViewContext';

function StaticView({ view }: { view: LiveAppView }) {
  switch (view.type) {
    case 'menu':
      return <MenuViewRender />;
    case 'rich_text':
      return <RichTextViewRender />;
    case 'login':
      return <LoginViewRender />;
    default:
      return <FallBackView />;
  }
}

function RecordView({ view }: { view: LiveAppView }) {
  switch (view.type) {
    case 'form':
    case 'registration':
      return <FormViewRender />;
    case 'table':
      return <TableViewRender />;
    case 'list':
      return <ListViewRender />;
    case 'details':
      return <DetailsViewRender />;
    case 'report':
      return <ReportViewRender />;
    default:
      return <FallBackView />;
  }
}

export function ViewRender({ view }: { view: LiveAppView }) {
  const staticViewTypes: KnackViewType[] = ['rich_text', 'menu', 'login'];
  const recordViewTypes: KnackViewType[] = [
    'table',
    'list',
    'search',
    'details',
    'form',
    'calendar',
    'map',
    'report',
    'checkout',
    'registration'
  ];

  let ViewToRender = null;

  if (recordViewTypes.includes(view.type)) {
    ViewToRender = <RecordView view={view} />;
  }

  if (staticViewTypes.includes(view.type)) {
    ViewToRender = <StaticView view={view} />;
  }

  if (ViewToRender) {
    // Menu views don't need to be wrapped in a card
    if (view.type === 'menu') {
      return ViewToRender;
    }

    return <Card className="w-full sm:p-4">{ViewToRender}</Card>;
  }

  return null;
}

function View({ view }: { view: LiveAppView }) {
  return (
    <ViewContextProvider view={view}>
      <ViewRender view={view} />
    </ViewContextProvider>
  );
}

const memoized = memo(View);
export { memoized as View };

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddConnectionIcon } from 'react-icons/hi2';
import { Button, Dialog } from '@knack/asterisk-react';

import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { type FormView } from '@/types/schema/views/FormView';
import { FormViewRender } from '@/components/views/form/FormViewRender';
import { ViewContextProvider } from '@/components/views/ViewContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';

export function AddConnectedRecordModalPageEditorPreview({
  connectionInput
}: {
  connectionInput: FormViewConnectionInput;
}) {
  const [t] = useTranslation();
  const { page } = usePageEditorContext();

  const [isOpen, setIsOpen] = useState(false);

  if (!connectionInput.view) {
    return null;
  }

  let childView: FormView | undefined;
  let isNewlyCreatedChildForm = false;

  // If the connection input's `view` references a view key, we'll find the child view in the page's views array
  if (typeof connectionInput.view === 'string') {
    page.views.forEach((view) => {
      if (view.type === 'form' && view.key === connectionInput.view) {
        childView = view;
      }
    });
  } else {
    // Otherwise, it means the connection input's `view` stores a newly created child form view, which means that the user has enabled the ability to insert connected records
    // but the child view yet hasn't yet been created and processed by the server, so it doesn't yet exist in the page's views array.
    isNewlyCreatedChildForm = true;
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <Button
          intent="secondary"
          className="relative items-center gap-2 p-2 text-sm"
          size="sm"
          disabled={isNewlyCreatedChildForm}
        >
          <AddConnectionIcon size={16} />
          {t('components.views.form.connection_input.add_new_connection')}
        </Button>
      </Dialog.Trigger>

      {childView && (
        <Dialog.Content>
          <Dialog.Title className="sr-only">
            {t('components.views.form.connection_input.add_new_connection')}
          </Dialog.Title>
          <Dialog.MainContent className="pt-8">
            <ViewContextProvider view={childView}>
              <FormViewRender />
            </ViewContextProvider>
          </Dialog.MainContent>
        </Dialog.Content>
      )}
    </Dialog>
  );
}

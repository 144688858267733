import { Table } from '@knack/asterisk-react';

import { type TableViewColumn } from '@/types/schema/views/TableView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { cn } from '@/utils/tailwind';
import { useThemingContext } from '@/context/ThemingContext';
import { type InsertPositionState } from './table-sortable/SortableTableWrapper';
import { TableViewCellContent } from './TableViewCellContent';
import { useTableDisplayRules } from './useTableDisplayRules';

interface TableViewCellWrapperProps {
  recordValues: ViewRecord;
  column: TableViewColumn;
  className?: string;
  children?: React.ReactNode;
  insertPositionState?: InsertPositionState | null;
}

export function TableViewCellWrapper({
  recordValues,
  column,
  className,
  insertPositionState,
  children
}: TableViewCellWrapperProps) {
  const {
    cellStyle: displayRuleActionCellStyle,
    icon,
    shouldHideContent
  } = useTableDisplayRules(recordValues, column.rules);
  const { theme } = useThemingContext();

  // We center the content of the cell when it is of type action, link or delete
  const shouldCenterContent = column.type !== 'field';

  return (
    <Table.Cell
      className={cn('border-x-0', className, {
        '!border-r-4 border-r-blue-500':
          insertPositionState?.overId === column.id && insertPositionState?.position === 'after',
        '!border-l-4 border-l-blue-500':
          insertPositionState?.overId === column.id && insertPositionState?.position === 'before',
        '!border-x border-dashed border-x-blue-500 *:opacity-20':
          insertPositionState?.activeId === column.id,
        'border-r': theme.tables.border === 'rows-and-columns'
      })}
      style={displayRuleActionCellStyle}
    >
      {children ? (
        <TableViewCellContent
          icon={icon}
          columnAlign={column.align}
          value={recordValues[column.field?.key]}
          shouldHideContent={shouldHideContent}
          shouldCenterContent={shouldCenterContent}
        >
          {children}
        </TableViewCellContent>
      ) : (
        <TableViewCellContent
          icon={icon}
          value={recordValues[column.field?.key]}
          columnAlign={column.align}
          shouldHideContent={shouldHideContent}
          shouldCenterContent={shouldCenterContent}
        />
      )}
    </Table.Cell>
  );
}

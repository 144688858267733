import type { Active, Over } from '@dnd-kit/core';
import { useDndContext } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { type FormViewGroupColumn, type FormViewInput } from '@/types/schema/views/FormView';
import { FormInputEditor } from './FormInputEditor';

export function getViewInsertPosition(
  active: Active | null,
  over: Over | null,
  column: FormViewGroupColumn,
  input: FormViewInput
) {
  if (over?.id === input.id && active?.id !== input.id) {
    const activeColumnId: string | null = active?.data.current?.sortable.containerId ?? null;
    const overIndex: number = over.data.current?.sortable.index ?? -1;
    const activeIndex: number = active?.data.current?.sortable.index ?? -1;

    // If the view is being dragged within the same column
    if (activeColumnId === column.id) {
      return overIndex > activeIndex ? 'after' : 'before';
    }

    // If the view is being dragged from one column to another and the target view is the first one in the column,
    // we need to determine if the active view should be placed above or below the target view
    if (active && overIndex === 0) {
      const isBelowView =
        over &&
        active?.rect.current.translated &&
        active?.rect.current.translated.top > over.rect.top + over.rect.height / 2;

      return isBelowView ? 'after' : 'before';
    }

    return 'after';
  }

  return null;
}

export function SortableFormInput({
  input,
  column
}: {
  input: FormViewInput;
  column: FormViewGroupColumn;
}) {
  const dndContext = useDndContext();

  const { attributes, setNodeRef, transform, transition, isSorting } = useSortable({
    id: input.id,
    data: {
      type: 'input'
    }
  });

  const style = {
    transition,
    transform: isSorting ? undefined : CSS.Translate.toString(transform)
  };

  const insertPositionState = getViewInsertPosition(
    dndContext.active,
    dndContext.over,
    column,
    input
  );

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <FormInputEditor input={input} draggingOverInsertPosition={insertPositionState} />
    </div>
  );
}

import { Divider } from '@knack/asterisk-react';

import { type ListView } from '@/types/schema/views/ListView';
import {
  formatData,
  useViewMultipleRecordsQuery,
  type ViewRecordsResponse
} from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { useViewSearchParams } from '@/hooks/useViewSearchParams';
import { cn } from '@/utils/tailwind';
import { ViewToolbarSection } from '@/components/views/view-options/ViewToolbarSection';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewFooterSection } from '@/components/views/ViewFooterSection';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { ListItem } from './ListItem';

export function ListViewRender({ sourceData }: { sourceData?: ViewRecordsResponse }) {
  const { view } = useViewContext<ListView>();
  const { rowsPerPage, currentPage, searchValueFromParams, activeViewFilters } =
    useViewSearchParams(view);

  const { data: listData } = useViewMultipleRecordsQuery({
    viewKey: view.key,
    objectKey: view.source.object,
    enabled: !sourceData,
    options: {
      rowsPerPage,
      page: currentPage,
      ...(searchValueFromParams && { search: searchValueFromParams }),
      ...(activeViewFilters && { filters: JSON.stringify(activeViewFilters) })
    }
  });

  const parsedListData = sourceData ? formatData(sourceData) : listData;
  const { list_layout: listColumnLayout, columns } = view;
  const totalPageNumber = listData?.totalPages || 0;
  const listRows = columns[0].groups[0].columns;

  const listColumnLayoutNumber: Record<string, number> = {
    'one-column': 1,
    'two-column': 2,
    'three-column': 3
  };

  return (
    <div className="w-full">
      <ViewHeaderSection view={view} className="mb-6" />
      <ViewToolbarSection />
      <div
        className={cn('grid w-full grid-cols-1 gap-4', {
          'max-w-[650px]': listColumnLayoutNumber[listColumnLayout] === 1,
          'md:grid-cols-2': listColumnLayoutNumber[listColumnLayout] === 2,
          'md:grid-cols-3': listColumnLayoutNumber[listColumnLayout] === 3
        })}
      >
        {parsedListData?.records.length ? (
          parsedListData.records.map((record) => (
            <div key={record.values.id} className="flex flex-wrap gap-3 sm:flex-nowrap">
              {listRows.map((row, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${record.values.id}-${index}`}
                  listRows={row}
                  record={record}
                  isSingleColumn={listColumnLayoutNumber[listColumnLayout] === 1}
                />
              ))}
            </div>
          ))
        ) : (
          <p>{view.no_data_text}</p>
        )}
      </div>
      <Divider className="my-4" />
      <ViewFooterSection totalPageNumber={totalPageNumber} />
    </div>
  );
}

import { useState } from 'react';
import {
  closestCorners,
  DndContext,
  DragOverlay,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  type UniqueIdentifier
} from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';
import { Table } from '@knack/asterisk-react';

import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { type FormattedViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useDndUtils } from '@/hooks/useDndUtils';
import { type ColumnSummariesValues } from '@/components/views/table/helper';
import { TableElement } from '@/components/views/table/TableElement';
import { useViewContext } from '@/components/views/ViewContext';
import { usePageEditorContext } from '@/pages/page/page-editor/PageEditorContext';
import { TableHeadEditor } from './TableHeadEditor';

interface SortableTableWrapperProps {
  columns: TableViewColumn[];
  records: FormattedViewRecord[];
  summariesValues?: ColumnSummariesValues | null;
  children: React.ReactNode;
}

export interface InsertPositionState {
  position: 'none' | 'before' | 'after';
  activeId: UniqueIdentifier;
  overId: UniqueIdentifier;
}

export function SortableTableWrapper({
  columns,
  records,
  summariesValues,
  children
}: SortableTableWrapperProps) {
  const { view } = useViewContext<TableView>();
  const { selectedItem, setIsDraggingActive, updatePage } = usePageEditorContext();
  const { optimizedSensors } = useDndUtils();
  // State for keeping track of the id of the input being dragged. Needed for rendering the dragging overlay
  const [beingDraggedInputId, setBeingDraggedInputId] = useState<UniqueIdentifier | null>(null);
  const [insertPositionState, setInsertPositionState] = useState<InsertPositionState | null>(null);

  if (!selectedItem || selectedItem.type !== 'view') {
    return children;
  }

  // Show the sortable table heads only if the view is active
  const isViewActive = selectedItem.view.key === view.key;

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columns.findIndex((sum) => sum.id === active.id) ?? -1;
      const newIndex = columns.findIndex((sum) => sum.id === over.id) ?? -1;

      if (oldIndex > newIndex) {
        setInsertPositionState({
          position: 'before',
          activeId: active.id,
          overId: over.id
        });
      } else {
        setInsertPositionState({
          position: 'after',
          activeId: active.id,
          overId: over.id
        });
      }
    }

    if (over && active.id === over.id) {
      setInsertPositionState({
        position: 'none',
        activeId: active.id,
        overId: ''
      });
    }
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setBeingDraggedInputId(active.id);
    setInsertPositionState({
      position: 'after',
      activeId: active.id,
      overId: active.id
    });
    setIsDraggingActive(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (!columns) {
      return;
    }

    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columns.findIndex((sum) => sum.id === active.id) ?? -1;
      const newIndex = columns.findIndex((sum) => sum.id === over.id) ?? -1;

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      const newArray = arrayMove(columns, oldIndex, newIndex);

      updatePage({
        type: 'view',
        origin: 'live-app',
        action: 'update',
        updatedView: {
          ...view,
          columns: newArray
        }
      });
    }

    setBeingDraggedInputId(null);
    setIsDraggingActive(false);
    setInsertPositionState(null);
  };

  if (isViewActive) {
    return (
      <DndContext
        sensors={optimizedSensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={onDragOver}
        collisionDetection={closestCorners}
        modifiers={[restrictToHorizontalAxis]}
      >
        <TableElement
          columns={columns}
          records={records}
          summariesValues={summariesValues}
          insertPositionState={insertPositionState}
        />
        <DragOverlay
          dropAnimation={null}
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
        >
          {columns.map((column) => {
            if (column.id !== beingDraggedInputId) {
              return null;
            }

            return (
              <Table key={column.id} className="pointer-events-none">
                <Table.Header>
                  <Table.Row>
                    <TableHeadEditor
                      key={`${column.id}-sortable-head`}
                      column={column}
                      isDragOverlay
                    />
                  </Table.Row>
                </Table.Header>
                <Table.Body className="max-h-20 border border-blue-500 border-t-default">
                  {records.map((record) => (
                    <Table.Row key={record.values.id}>
                      <Table.Cell className="h-10 bg-blue-50 p-2"> </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            );
          })}
        </DragOverlay>
      </DndContext>
    );
  }

  return children;
}

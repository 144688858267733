import { SimplePagination } from '@knack/asterisk-react';

import { type ListView } from '@/types/schema/views/ListView';
import { type TableView } from '@/types/schema/views/TableView';
import { useViewSearchParams } from '@/hooks/useViewSearchParams';
import { cn } from '@/utils/tailwind';
import { RowsPerPageSelect } from './view-options/RowsPerPageSelect';
import { useViewContext } from './ViewContext';

interface ViewFooterSectionProps {
  totalPageNumber: number;
  className?: string;
}

export function ViewFooterSection({ totalPageNumber, className }: ViewFooterSectionProps) {
  const { view } = useViewContext<ListView | TableView>();
  const { currentPage, setViewParam } = useViewSearchParams(view);

  const { allow_limit: shouldAllowChangeRowsPerPage } = view;

  const shouldRenderPagination = totalPageNumber > 1;

  if (!shouldAllowChangeRowsPerPage && !shouldRenderPagination) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex flex-col-reverse items-center justify-between gap-3 p-2 text-sm md:flex-row',
        className,
        {
          'justify-end': !shouldAllowChangeRowsPerPage
        }
      )}
    >
      {shouldAllowChangeRowsPerPage && <RowsPerPageSelect />}
      {shouldRenderPagination && (
        <div>
          <SimplePagination
            totalPages={totalPageNumber}
            currentPage={Number(currentPage)}
            onPageChange={(page) => setViewParam({ page })}
          />
        </div>
      )}
    </div>
  );
}

import { createContext, useContext, useMemo, useState } from 'react';

import { type LiveAppPageSection } from '@/types/schema/LiveAppPage';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';

export type ViewInputHoverIdentifier = `${LiveAppViewKey}:${string}`;

type SectionEditorState = {
  section: LiveAppPageSection;
  currentHoveredViewKey: LiveAppViewKey | null;
  currentHoveredViewInput: ViewInputHoverIdentifier | null;
  hasSectionHover: boolean;
  setSectionHover: (value: boolean) => void;
  setViewHover: (viewKey: LiveAppViewKey | null) => void;
  setViewInputHover: (viewInputHoverIdentifier: ViewInputHoverIdentifier | null) => void;
} | null;

const SectionEditorContext = createContext<SectionEditorState>(null);

interface SectionEditorContextProviderProps {
  section: LiveAppPageSection;
  children: React.ReactNode;
}

export function SectionEditorContextProvider({
  section,
  children
}: SectionEditorContextProviderProps) {
  const [currentHoveredViewKey, setCurrentHoveredViewKey] = useState<LiveAppViewKey | null>(null);
  const [currentHoveredViewInput, setCurrentHoveredViewInput] =
    useState<ViewInputHoverIdentifier | null>(null);
  const [hasSectionHover, setHasSectionHover] = useState(false);

  const contextValue = useMemo(
    () => ({
      section,
      currentHoveredViewKey,
      currentHoveredViewInput,
      hasSectionHover,
      setSectionHover: setHasSectionHover,
      setViewHover: setCurrentHoveredViewKey,
      setViewInputHover: setCurrentHoveredViewInput
    }),
    [section, currentHoveredViewKey, currentHoveredViewInput, hasSectionHover]
  );

  return (
    <SectionEditorContext.Provider value={contextValue}>{children}</SectionEditorContext.Provider>
  );
}

export function useSectionEditorContext() {
  const context = useContext(SectionEditorContext);
  if (!context) {
    throw new Error(
      'usePageEditorSectionContext must be used within a SectionEditorContextProvider'
    );
  }
  return context;
}

import { queryOptions, useQuery } from '@tanstack/react-query';

import { type LiveAppApplication } from '@/types/schema/LiveAppApplication';
import { queryKeys } from '@/hooks/api/queryKeys';
import { getApplicationBasePathSegments } from '@/utils/application';
import { axiosInstance as axios } from '@/utils/axiosConfig';

async function getApplication(accountSlug: string, appSlug: string) {
  const { data: application } = await axios.get<LiveAppApplication>(
    `/v1/live-app/${accountSlug}/${appSlug}`,
    {
      withCredentials: true
    }
  );
  return application;
}

export function applicationQuery() {
  const { accountSlug, appSlug } = getApplicationBasePathSegments();

  return queryOptions({
    queryKey: [queryKeys.application, accountSlug, appSlug],
    queryFn: async () => getApplication(accountSlug, appSlug)
  });
}

export function useApplicationQuery() {
  return useQuery(applicationQuery());
}

import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Label, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { useForgotPasswordMutation } from '@/hooks/api/mutations/password-management/useForgotPasswordMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { zodEmailRegex } from '@/utils/zod';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { useThemingContext } from '@/context/ThemingContext';
import { BackToLoginButton } from './BackToLoginButton';

export function ForgotPasswordForm() {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { data: application } = useApplicationQuery();
  const { theme } = useThemingContext();
  const { setActiveAuthFlow } = useAuthFlow();

  const shouldShowLogo = theme.mainNavContainer.logo.isVisible && theme.mainNavContainer.logo.url;

  const forgotSchema = z.object({
    email: z
      .string()
      .min(1, t('components.views.login.errors.email_required'))
      .regex(zodEmailRegex, t('components.views.login.errors.email_invalid'))
  });

  type ForgotViewSchemaType = z.infer<typeof forgotSchema>;
  const forgotPasswordMutation = useForgotPasswordMutation();
  const {
    handleSubmit,
    register,
    formState: { isDirty }
  } = useForm<ForgotViewSchemaType>({
    resolver: zodResolver(forgotSchema),
    defaultValues: {
      email: ''
    }
  });

  const onSubmit: SubmitHandler<ForgotViewSchemaType> = async (data) => {
    await forgotPasswordMutation.mutateAsync(
      { email: data.email },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.views.login.forgot.request_received'),
            intent: 'success'
          });
          setActiveAuthFlow('login');
        },
        onError: () => {
          presentToast({
            title: t('components.views.login.forgot.error'),
            intent: 'destructive'
          });
        }
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="mb-8 flex flex-col items-center justify-center space-y-2">
          {shouldShowLogo && (
            <img
              src={theme.mainNavContainer.logo.url}
              alt={`${application?.name} Logo`}
              className="max-w-[64px] sm:max-w-[120px]"
            />
          )}
          <h1 className="text-2xl font-medium text-emphasis">
            {t('components.views.login.forgot.title')}
          </h1>
          <p>{t('components.views.login.forgot.description')}</p>
        </div>
        <Label htmlFor="email">{t('keywords.email')}</Label>
        <Input id="email" type="email" {...register('email')} />
        <Button intent="primary" className="my-6 w-full" type="submit" disabled={!isDirty}>
          {t('components.views.login.forgot.submit')}
        </Button>
      </form>

      <BackToLoginButton />
    </>
  );
}

import { Button } from '@knack/asterisk-react';

import { type MenuView } from '@/types/schema/views/MenuView';
import { MenuLinkContent } from './MenuLinkContent';

export function MenuButtons({ menuView }: { menuView: MenuView }) {
  // TODO: FE-1557 - handle button intent (primary or secondary) once that option is added to the schema in the menu settings
  const buttonIntent = 'primary';

  if (menuView.links.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-2">
      {menuView.links.map((link) => (
        <Button key={link.id} intent={buttonIntent} asChild>
          <MenuLinkContent link={link} />
        </Button>
      ))}
    </div>
  );
}

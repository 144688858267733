import { Chart } from '@knack/asterisk-react';
import {
  Line,
  LineChart,
  Legend as RechartsLegend,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { type HorizontalAlignmentType } from 'recharts/types/component/DefaultLegendContent';

import type { ReportsResponse, ReportView } from '@/types/schema/views/ReportView';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { useChart } from './shared/useChart';

export function LineChartRender({ data, view }: { data?: ReportsResponse; view: ReportView }) {
  const { chartData, chartKeys, chartConfig, hasMultipleGroups } = useChart({ data, view });
  const chartLayout = view.rows[0].reports[0].layout;
  const shouldUseVerticalLegend = chartLayout.legend === 'right' || chartLayout.legend === 'left';

  return (
    <>
      <ViewHeaderSection view={view} />
      <Chart config={chartConfig} className="w-full">
        <LineChart
          accessibilityLayer
          data={chartData}
          margin={{
            top: 15,
            bottom: 10,
            left: 10,
            right: 10
          }}
        >
          {!hasMultipleGroups && (
            <YAxis
              dataKey="calc_0"
              type="number"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value}
            />
          )}
          <XAxis dataKey="group_0" type="category" tickLine={false} axisLine={false} />
          <RechartsTooltip cursor={false} content={<Chart.TooltipContent hideLabel />} />
          {chartLayout.legend !== 'none' && (
            <RechartsLegend
              content={<Chart.LegendContent />}
              layout={shouldUseVerticalLegend ? 'vertical' : 'horizontal'}
              verticalAlign={shouldUseVerticalLegend ? 'middle' : undefined}
              align={
                chartLayout.legend === 'below'
                  ? 'center'
                  : (chartLayout.legend as HorizontalAlignmentType)
              }
            />
          )}
          {Object.keys(chartKeys).map((key) => (
            <Line
              key={`${view.key}-line-${key}`}
              dataKey={key}
              stroke={`var(--color-${key})`}
              radius={5}
              label={chartLayout.data_labels ? { position: 'top' } : undefined}
              type={chartLayout.line_type}
              dot={chartLayout.data_points}
            />
          ))}
        </LineChart>
      </Chart>
    </>
  );
}

import { useMutation } from '@tanstack/react-query';

import { axiosInstance as axios } from '@/utils/axiosConfig';

export interface ForgotPasswordMutationParams {
  email: string;
}

export async function forgotPassword({ email }: ForgotPasswordMutationParams) {
  const response = await axios.post('/v1/live-app/forgot-password', {
    email
  });

  return response;
}

export function useForgotPasswordMutation() {
  return useMutation({
    mutationFn: ({ email }: ForgotPasswordMutationParams) => forgotPassword({ email })
  });
}

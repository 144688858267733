import { type ChartConfig } from '@knack/asterisk-react';

export function CustomLabel({
  chartConfig,
  payload,
  ...props
}: {
  chartConfig: ChartConfig;
  payload: {
    raw_0: string;
  };
}) {
  return (
    <text {...props} textAnchor={undefined}>
      {payload.raw_0}
    </text>
  );
}

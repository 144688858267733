import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';

import { type TableViewColumn } from '@/types/schema/views/TableView';
import { type InsertPositionState } from './SortableTableWrapper';
import { TableHeadEditor } from './TableHeadEditor';

export function SortableTableHeadGroups({
  columns,
  insertPositionState
}: {
  columns: TableViewColumn[];
  insertPositionState?: InsertPositionState | null;
}) {
  return (
    <SortableContext
      items={columns.map((column) => column.id)}
      strategy={horizontalListSortingStrategy}
    >
      {columns.map((column) => (
        <TableHeadEditor
          key={`${column.id}-sortable-head`}
          column={column}
          insertPositionState={insertPositionState}
        />
      ))}
    </SortableContext>
  );
}

import { DropdownMenu } from '@knack/asterisk-react';

import type { LiveAppPage } from '@/types/schema/LiveAppPage';
import { cn } from '@/utils/tailwind';
import { NavItemWrapper } from '@/components/main-navigation/NavItemWrapper';
import { useNavigation } from '@/components/main-navigation/useNavigation';
import { useThemingContext } from '@/context/ThemingContext';

export function NavHorizontalItem({
  item,
  allItems,
  shouldUseSubmenu
}: {
  item: LiveAppPage;
  allItems: LiveAppPage[];
  shouldUseSubmenu?: boolean;
}) {
  const { theme } = useThemingContext();
  const { childrenItems, shouldDisplayChildren, isActive } = useNavigation({ item });

  // Hidden items or dropdowns with submenus
  if (shouldUseSubmenu) {
    if (shouldDisplayChildren) {
      return (
        <DropdownMenu.SubMenu>
          <DropdownMenu.SubTrigger className="p-0" shouldHideChevron>
            <NavItemWrapper item={item} isActive={isActive} isSubTrigger />
          </DropdownMenu.SubTrigger>
          <DropdownMenu.Portal>
            <DropdownMenu.SubContent>
              {childrenItems?.map((childItem) => (
                <NavHorizontalItem
                  key={childItem.key}
                  item={childItem}
                  allItems={allItems}
                  shouldUseSubmenu
                />
              ))}
            </DropdownMenu.SubContent>
          </DropdownMenu.Portal>
        </DropdownMenu.SubMenu>
      );
    }
    return (
      <DropdownMenu.Item asChild>
        <NavItemWrapper item={item} isActive={isActive} />
      </DropdownMenu.Item>
    );
  }

  // Visible items with children
  if (shouldDisplayChildren) {
    return (
      <DropdownMenu>
        <DropdownMenu.Trigger
          className={cn({
            rounded: theme.appearance.corners === 'rounded'
          })}
        >
          <NavItemWrapper item={item} isDropdownMenu isActive={isActive} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="start">
          {childrenItems?.map((childItem) => (
            <NavHorizontalItem key={childItem.key} item={childItem} allItems={allItems} />
          ))}
        </DropdownMenu.Content>
      </DropdownMenu>
    );
  }

  // Visible items
  return <NavItemWrapper item={item} isActive={isActive} />;
}

import { useQuery } from '@tanstack/react-query';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { type LiveAppNewViewKey, type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { type ChartReport, type ReportsResponse } from '@/types/schema/views/ReportView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';
import { isIframedByBuilder } from '@/utils/iframe';
import { usePageContext } from '@/context/PageContext';

async function getNewReport(
  pageKey: LiveAppPage['key'],
  viewKey: LiveAppViewKey,
  report: ChartReport
) {
  if (!isIframedByBuilder() || !viewKey.startsWith('new_view_' satisfies LiveAppNewViewKey)) {
    return undefined;
  }

  const payload = {
    record_per_page: 25,
    page: 1,
    preview: true,
    report
  };

  // This is a POST route in the server, but we treat it as a GET route in the client.
  // The reason for using a POST is to avoid the URL length limit for report previewing so it can include large reports in the request body
  const { data: reports } = await axios.post<ReportsResponse>(
    `/v1/scenes/${pageKey}/views/new/report`,
    payload,
    {
      withCredentials: true,
      headers: getAppBasedRequestHeaders()
    }
  );

  return reports;
}

export function useNewReportQuery({
  viewKey,
  report,
  enabled = true
}: {
  viewKey: LiveAppViewKey;
  report: ChartReport;
  enabled?: boolean;
}) {
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useQuery({
    queryKey: [queryKeys.report, activePage.key, viewKey],
    queryFn: async () => getNewReport(activePage.key, viewKey, report),
    enabled
  });
}

import { RichTextRenderer } from '@knack/asterisk-react';

import { type LiveAppView } from '@/types/schema/LiveAppView';

export function ViewHeaderSection({ view, className }: { view: LiveAppView; className?: string }) {
  const { title, description } = view;

  if (!title && !description) {
    return null;
  }

  return (
    <div className={className} data-testid="view-header-section">
      {title && (
        <h2 className="font-semibold text-emphasis" data-testid={`${view.id}-view-title`}>
          {title}
        </h2>
      )}
      {description && <RichTextRenderer dangerouslySetInnerHTML={{ __html: description }} />}
    </div>
  );
}

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';

interface UpdateOptionParams {
  pageKey: string;
  pageSlug: string;
  viewKey: string;
  recordId: string | undefined;
  data: {
    [key: string]: any;
  };
}

interface UseUpdateRecordMutationParams {
  data: {
    [key: string]: any;
  };
  recordId: string | undefined;
}

export async function editRecord({
  pageKey,
  pageSlug,
  viewKey,
  recordId,
  data
}: UpdateOptionParams) {
  if (!recordId) throw new Error('Record ID is required');
  const isActionLinkUpdate = data.action_link_index;

  const url = `/v1/scenes/${pageKey}/views/${viewKey}/records/${recordId}/`;

  const formattedData = {
    crumbtrails: {
      [`${pageSlug}_id`]: recordId
    },
    _id: recordId,
    ...data
  };

  const { data: responseData } = await axios.put<{ record: ViewRecord }>(
    url,
    isActionLinkUpdate ? data : formattedData,
    {
      withCredentials: true
    }
  );

  return responseData.record;
}

export function useUpdateRecordMutation({ viewKey }: { viewKey: string }) {
  const queryClient = useQueryClient();
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('Active page not found');
  }

  return useMutation({
    mutationFn: ({ data, recordId }: UseUpdateRecordMutationParams) =>
      editRecord({
        pageKey: activePage.key,
        pageSlug: activePage.slug,
        viewKey,
        recordId,
        data
      }),
    onSuccess: async (record) => {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.viewData, record.id]
      });
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.viewData, viewKey]
      });
    }
  });
}

import { type ChartConfig } from '@knack/asterisk-react';
import snakeCase from 'lodash/snakeCase';

import type { ReportRecord, ReportsResponse, ReportView } from '@/types/schema/views/ReportView';
import { colors } from './colors';

export function useChart({ data, view }: { data?: ReportsResponse; view: ReportView }): {
  chartData: ReportRecord[];
  chartConfig: ChartConfig;
  chartKeys: { [k: string]: unknown };
  hasMultipleGroups: boolean;
} {
  if (!data || !data.reports || !data.reports[0] || !data.reports[0].records[0]) {
    return {
      chartData: [],
      chartKeys: {},
      chartConfig: {},
      hasMultipleGroups: false
    };
  }

  // A chart can have a maximum of 2 groups
  const hasMultipleGroups = !!data.reports[0].records[0].group_1;
  const chartCalculations = view.rows[0].reports[0].calculations;

  let chartConfig: ChartConfig = {};

  chartConfig = chartCalculations.reduce((acc, calculation, index) => {
    acc[`calc_${index}`] = {
      label: calculation.label,
      color: colors[index % colors.length]
    };
    return acc;
  }, {} as ChartConfig);

  if (!hasMultipleGroups) {
    return {
      chartData: data.reports[0].records,
      chartKeys: {
        calc_0: {}
      },
      chartConfig,
      hasMultipleGroups
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: Record<string, any> = {};
  const labels: string[] = [];

  // Convert the chart data into a format that the chart component can understand
  data.reports[0].records.forEach((item) => {
    const groupValue = item.group_0;
    const subGroupValue = item.group_1;
    const calcValue = item.calc_0;

    if (!result[groupValue]) {
      result[groupValue] = { group_0: groupValue };
    }

    result[groupValue][snakeCase(subGroupValue)] = calcValue;
    labels.push(subGroupValue);
  });

  const chartData = Object.values(result);

  // Remove duplicate labels
  const chartLabels = [...new Set(labels)];

  const chartKeys = Object.fromEntries(Object.entries(chartData[0]).slice(1));

  Object.keys(chartKeys).forEach((key, index) => {
    chartConfig[key] = {
      label: <span className="mr-2">{chartLabels[index]}</span>,
      color: colors[index % colors.length]
    };
  });

  return {
    chartData,
    chartKeys,
    chartConfig,
    hasMultipleGroups
  };
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiCalculator as CalculatorIcon } from 'react-icons/hi';

import { type FormViewEquationInput } from '@/types/schema/views/form/Equation';

const numberDelimiterMapping = {
  comma: ',',
  period: '.',
  space: ' ',
  none: ''
} as const;

export function EquationInput({ input }: { input: FormViewEquationInput }) {
  const [t] = useTranslation();
  const {
    formState: { defaultValues: formDefaultValues },
    setValue: setFormValue,
    getValues
  } = useFormContext();
  const inputFormat = input.format;
  const { equation, mark_decimal: markDecimal, mark_thousands: markThousands } = inputFormat;
  const defaultValue = formDefaultValues?.[input.field.key];
  const inputValue = getValues(input.field.key) || defaultValue;
  const decimalDelimiter = markDecimal;
  const thousandDelimiter = markThousands;

  const formatDecimalDelimiter = () => {
    const hasSameDelimiter =
      (decimalDelimiter === thousandDelimiter && decimalDelimiter !== 'none') ||
      (decimalDelimiter === thousandDelimiter && decimalDelimiter !== 'space');

    if (decimalDelimiter === 'none') return '';

    if (hasSameDelimiter) {
      return decimalDelimiter === 'comma' ? '.' : ',';
    }

    return numberDelimiterMapping[decimalDelimiter];
  };

  const mask = {
    mask: Number,
    scale: decimalDelimiter !== 'none' ? input.format.precision : 0,
    ...(thousandDelimiter !== 'none' && {
      thousandsSeparator: numberDelimiterMapping[thousandDelimiter]
    }),
    ...(decimalDelimiter !== 'none' && {
      radix: formatDecimalDelimiter()
    }),
    padFractionalZeros: true
  };
  // Commenting out the code that is not being used, this input will be implemented after the Pilot because it requires more time and is very complex

  // const { setValue } = useIMask<HTMLInputElement>(mask, {
  //   defaultValue: inputValue?.toString(),
  //   onAccept: (value: string) => {
  //     setFormValue(input.field.key, value);
  //   }
  // });

  // const { ref: formRef, ...formRegister } = register(input.field.key);

  // useEffect(() => {
  //   const subscription = watch((formValues) => {
  //     const evaluatedResult = evaluateEquation(equation, formValues, fields);
  //     if (evaluatedResult) {
  //       setValue(evaluatedResult.toString());
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [equation, setValue, watch, input.field.key, fields]);

  // if (inputFormat.equation_type === 'numeric') {
  //   return (
  //     <div className="flex items-center gap-2">
  //       <CalculatorIcon size={20} />
  //       <input
  //         ref={inputRef}
  //         className="pointer-events-none border-0 focus:outline-0"
  //         {...formRegister}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="flex items-center gap-2">
      <CalculatorIcon size={20} />
      <p className="text-muted">{t('keywords.coming_soon')}</p>
    </div>
  );
}

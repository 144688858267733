import { type ComboboxOption } from '@knack/asterisk-react';

import { type TableView } from '@/types/schema/views/TableView';
import { ViewFiltersPopover } from '@/components/views/view-options/ViewFiltersPopover';

export function TableViewFiltersPopover({ view }: { view: TableView }) {
  const columnOptions: ComboboxOption[] = view.columns.reduce((acc: ComboboxOption[], column) => {
    if (column.type === 'field') {
      acc.push({
        key: column.field?.key || '',
        label: column.header
      });
    }
    return acc;
  }, []);

  return <ViewFiltersPopover view={view} columnOptions={columnOptions} />;
}

import { useTranslation } from 'react-i18next';
import { Chip, Tooltip } from '@knack/asterisk-react';
import { snakeCase } from 'lodash';

import { useViewFilters, type ViewFilterRule, type ViewFilters } from '@/hooks/useViewFilters';
import { type ViewWithSearchParams } from '@/hooks/useViewSearchParams';
import { InlineKnackRecordValue } from '@/components/InlineKnackValue';
import { useViewContext } from '@/components/views/ViewContext';

export function ViewFiltersChips({ activeViewFilters }: { activeViewFilters: ViewFilters }) {
  const [t] = useTranslation();
  const filterLimit = 3;
  const { view, sourceTable } = useViewContext<ViewWithSearchParams>();
  const { removeActiveFilter } = useViewFilters({ view, sourceTable });

  const getFilterBadgeText = (filter: ViewFilterRule) =>
    `${filter.field_name} ${t(`operators.${snakeCase(filter.operator)}`)}`;

  const chipsRules = activeViewFilters.rules.slice(0, filterLimit);

  return (
    <div className="flex flex-wrap">
      {chipsRules.map((filter, index) => (
        <Tooltip
          // eslint-disable-next-line react/no-array-index-key
          key={`${filter.field}-${index}-chip`}
        >
          <Tooltip.Trigger>
            <Chip
              className="text-xs font-medium"
              onClose={() => {
                removeActiveFilter(index);
              }}
            >
              <p>
                <span className="font-bold">{filter.field_name}</span>{' '}
                {t(`operators.${snakeCase(filter.operator)}`)}{' '}
                {filter.type ? (
                  <span className="font-bold">
                    {filter.range && filter.range} {filter.type}
                  </span>
                ) : (
                  <span className="font-bold">
                    <InlineKnackRecordValue
                      fieldKey={filter.field}
                      viewKey={view.key}
                      value={filter.value}
                    />
                  </span>
                )}
              </p>
            </Chip>
          </Tooltip.Trigger>
          <Tooltip.Content className="text-xs">
            {getFilterBadgeText(filter)}{' '}
            <InlineKnackRecordValue
              fieldKey={filter.field}
              viewKey={view.key}
              value={filter.value}
            />
          </Tooltip.Content>
        </Tooltip>
      ))}
      {activeViewFilters.rules.length > filterLimit && (
        <Chip
          className="text-xs"
          text={`+ ${activeViewFilters.rules.length - filterLimit} ${t('keywords.more')}`}
        />
      )}
    </div>
  );
}

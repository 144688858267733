import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiMiniFunnel as FiltersIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Popover, type ComboboxOption } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type ListView } from '@/types/schema/views/ListView';
import { type TableView } from '@/types/schema/views/TableView';
import { useViewFilters, type ViewFilters } from '@/hooks/useViewFilters';
import { useViewSearchParams, type ViewWithSearchParams } from '@/hooks/useViewSearchParams';
import { getRulesOperators } from '@/utils/operators';
import { cn } from '@/utils/tailwind';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { ViewFilterRow } from './ViewFilterRow';

interface ViewFilterProps {
  view: TableView | ListView;
  columnOptions: ComboboxOption[];
}

export interface UpdateFilterParams {
  index: number;
  ruleKey: 'field' | 'operator' | 'value' | 'match';
  value: string;
  fieldKey?: KnackFieldKey;
  fieldName?: string;
}

const filterFormSchema = z.object({
  match: z.string(),
  rules: z
    .array(
      z.object({
        match: z.string(),
        field: z.string(),
        operator: z.string().min(1),
        value: z.any(), // TODO: Add types here
        field_name: z.string(),
        range: z.string().optional(),
        type: z.string().optional()
      })
    )
    .min(1)
});

export type FormSchemaType = z.infer<typeof filterFormSchema>;

export function ViewFiltersPopover({ view, columnOptions }: ViewFilterProps) {
  const [t] = useTranslation();
  const { theme } = useThemingContext();
  const { sourceTable } = useViewContext<ViewWithSearchParams>();
  const { activeViewFilters } = useViewSearchParams(view);
  const { saveActiveFilters, resetFilters, getDefaultFilters } = useViewFilters({
    view,
    sourceTable
  });

  const defaultFilters = getDefaultFilters();

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(filterFormSchema),
    defaultValues: activeViewFilters || defaultFilters
  });

  const {
    fields: localActiveViewFiltersRules,
    append: addNewLocalFilter,
    remove: removeLocalFilter,
    replace: replaceLocalFilters
  } = useFieldArray({
    control: form.control,
    name: 'rules'
  });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleAddNewFilter = () => {
    addNewLocalFilter({ ...defaultFilters.rules[0], match: form.getValues().match });
  };

  const handleClickClearAll = () => {
    replaceLocalFilters([defaultFilters.rules[0]]);
    resetFilters();
    setIsPopoverOpen(false);
  };

  const handleDialogClose = (open: boolean) => {
    // if we modify filters and we close without saving, reset the filters to the activeTableFilters
    if (activeViewFilters && activeViewFilters?.rules.length > 0 && !open) {
      form.reset(activeViewFilters);
    }
    setIsPopoverOpen(open);
  };

  const handleSaveLocalFilters = (localFilters: FormSchemaType) => {
    saveActiveFilters(localFilters as ViewFilters);
    setIsPopoverOpen(false);
  };

  const handleRemoveLocalFilter = (ruleIndex: number) => {
    if (localActiveViewFiltersRules.length === 1) {
      replaceLocalFilters([defaultFilters.rules[0]]);
      return;
    }
    removeLocalFilter(ruleIndex);
  };

  const formatDataForSubmit = (data: FormSchemaType) => {
    const formattedData = { ...data };

    formattedData.rules = data.rules.map((rule) => {
      const selectedColumnField = sourceTable.fields.find((field) => field.key === rule.field);
      if (!selectedColumnField) return rule;

      if (selectedColumnField.type === 'connection') {
        return {
          ...rule,
          value: [rule.value[0]]
        };
      }

      if (selectedColumnField.type === 'date_time' && rule.value) {
        return {
          ...rule,
          value: {
            date: rule.value.date,
            time: rule.value.time
          }
        };
      }

      return rule;
    });

    handleSaveLocalFilters(formattedData);
  };

  useEffect(() => {
    form.reset(activeViewFilters || defaultFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeViewFilters]);

  return (
    <Popover open={isPopoverOpen} onOpenChange={handleDialogClose}>
      <Popover.Trigger asChild>
        <Button
          intent="secondary"
          className="gap-2 text-default md:w-auto"
          size="sm"
          disabled={defaultFilters.rules.length === 0}
        >
          <FiltersIcon size={16} />
          <span className="hidden md:inline">{t('actions.filter')}</span>
          {activeViewFilters && (
            <span
              className={cn('block size-4 bg-subtle text-xs text-subtle md:hidden', {
                rounded: theme.appearance.corners === 'rounded'
              })}
            >
              {localActiveViewFiltersRules.length}
            </span>
          )}
        </Button>
      </Popover.Trigger>
      <Popover.Content
        align="start"
        className="max-h-[400px] max-w-[350px] overflow-auto p-6 sm:w-[720px] sm:max-w-[720px]"
        collisionPadding={16}
      >
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(formatDataForSubmit)} className="space-y-4 p-3 text-sm">
            {localActiveViewFiltersRules.map((rule, ruleIndex) => {
              const selectedColumnField = sourceTable.fields.find(
                (field) => field.key === form.watch().rules[ruleIndex].field
              );
              if (!selectedColumnField) return null;

              const filterOperators = getRulesOperators(selectedColumnField?.type);
              return (
                <ViewFilterRow
                  key={rule.id}
                  ruleIndex={ruleIndex}
                  columnOptions={columnOptions}
                  filterOperators={filterOperators}
                  onRemoveFilter={handleRemoveLocalFilter}
                />
              );
            })}
            <Button intent="minimal" className="mt-3 gap-2" onClick={handleAddNewFilter}>
              <AddIcon size={16} className="shrink-0" />
              {t('components.views.table.filters.add_condition')}
            </Button>
          </form>
        </FormProvider>
        <div className="flex justify-end gap-2 pt-2">
          <Button intent="minimal" onClick={handleClickClearAll}>
            {t('actions.clear_all')}
          </Button>
          <Button onClick={form.handleSubmit(formatDataForSubmit)}>{t('actions.save')}</Button>
        </div>
      </Popover.Content>
    </Popover>
  );
}

import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { useMediaQuery } from '@/hooks/useMediaQuery';
import { BREAKPOINTS } from '@/utils/constants';
import { isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import { NavHorizontalContainer } from '@/components/main-navigation/horizontal/NavHorizontalContainer';
import { NavMobileContainer } from '@/components/main-navigation/mobile/NavMobileContainer';
import { NavVerticalContainer } from '@/components/main-navigation/vertical/NavVerticalContainer';
import { useMessagingContext } from '@/context/MessagingContext';
import { useThemingContext } from '@/context/ThemingContext';

export function BuilderAppPageLayout({ children }: { children?: React.ReactNode }) {
  const navBrandRef = useRef(null);
  const { messageFromBuilder } = useMessagingContext();
  const { theme, setTheme } = useThemingContext();
  const shouldUseMobileNav = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);
  const navContainer =
    theme.mainNavContainer.layout === 'vertical' ? (
      <NavVerticalContainer
        containerclasses={cn('z-10', {
          'border-r border-subtle': theme.mainNavContainer.hasBorder,
          shadow: theme.mainNavContainer.hasShadow
        })}
      />
    ) : (
      <NavHorizontalContainer
        containerclasses={cn('mb-4 w-full bg-navigation p-4 text-center', {
          'border-b border-subtle': theme.mainNavContainer.hasBorder,
          shadow: theme.mainNavContainer.hasShadow,
          'sticky top-0 z-10': theme.mainNavContainer.isSticky
        })}
        navBrandRef={navBrandRef}
      />
    );

  useEffect(() => {
    if (!messageFromBuilder) {
      return;
    }

    if (messageFromBuilder.action === 'set-theme') {
      setTheme(messageFromBuilder.theme);
    }
  }, [messageFromBuilder, setTheme]);

  return (
    <div
      className={cn({
        flex: theme.mainNavContainer.layout === 'vertical',
        'flex-col': shouldUseMobileNav
      })}
    >
      {shouldUseMobileNav ? (
        <NavMobileContainer
          containerclasses={cn('mb-4 w-full bg-navigation p-4 text-center', {
            'border-b border-subtle': theme.mainNavContainer.hasBorder,
            shadow: theme.mainNavContainer.hasShadow,
            'sticky top-0 z-10': theme.mainNavContainer.isSticky
          })}
        />
      ) : (
        navContainer
      )}
      <div
        className={cn('isolate p-4', {
          'mx-auto max-w-screen-2xl px-6 pb-6': isPageEditor(),
          'container max-w-screen-xl': theme.appWidth === 'max',
          'container-fluid w-full': theme.appWidth === 'full'
        })}
      >
        <Breadcrumbs />
        <main className="flex flex-col gap-4">{children || <Outlet />}</main>
      </div>
    </div>
  );
}

import { forwardRef, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Spinner } from '@knack/asterisk-react';

const FontAwesomeIcon = lazy(() => import('@/components/FontAwesomeIcon'));

interface ActionButtonProps {
  onClick?: () => void;
  icon: string | undefined;
  label: string | undefined;
  linkTo?: string;
  isDelete?: boolean;
}

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ icon, label = '', onClick, linkTo, isDelete }, ref) => {
    const [t] = useTranslation();
    const content = (
      <>
        {icon && (
          <Suspense fallback={<Spinner />}>
            <FontAwesomeIcon icon={icon} className="size-4" />
          </Suspense>
        )}
        {label}
      </>
    );

    return (
      <Button
        aria-label={t('components.views.action_button')}
        ref={ref}
        intent={isDelete ? 'destructive' : 'secondary'}
        size="sm"
        asChild={!!linkTo}
        className="truncate"
        {...(onClick && { onClick })}
      >
        {linkTo ? <Link to={linkTo}>{content}</Link> : content}
      </Button>
    );
  }
);

ActionButton.displayName = 'ActionButton';

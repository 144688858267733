import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { getApplicationBasePathSegments } from '@/utils/application';
import { AppProviders } from '@/AppProviders';
import { Router } from '@/Router';

import '@/i18n';
import '@/index.css';
import '@knack/asterisk-react/style.css';

const { accountSlug, appSlug } = getApplicationBasePathSegments();
if (!accountSlug) {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    throw new Error('Account slug is missing');
  } else {
    window.location.replace(import.meta.env.PUBLIC_KNACK_REDIRECT_URL);
  }
}

if (!appSlug) {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    throw new Error('Application slug is missing');
  } else {
    window.location.replace(import.meta.env.PUBLIC_KNACK_REDIRECT_URL);
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 10 // Set the default stale time to 10 minutes
    }
  }
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppProviders>
        <Router />
      </AppProviders>
    </QueryClientProvider>
  </React.StrictMode>
);

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useIMask } from 'react-imask';
import { Input, InputWithDisabledText, Tooltip } from '@knack/asterisk-react';

import { type FormViewNumberInput } from '@/types/schema/views/form/Number';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';

const numberDelimiterMapping = {
  comma: ',',
  period: '.',
  space: ' ',
  none: ''
} as const;

export function NumberInput({ input }: { input: FormViewNumberInput }) {
  const [t] = useTranslation();
  const {
    formState: { errors },
    setValue: setFormValue,
    register,
    getValues
  } = useFormContext();
  const { view } = useViewContext();

  const inputValue = getValues(input.field.key);

  const decimalDelimiter = input.format.mark_decimal;
  const thousandDelimiter = input.format.mark_thousands;
  const numberFieldFormat = input.format.format;
  const isReadOnly = input.read_only;

  const formatDecimalDelimiter = () => {
    const hasSameDelimiter =
      (decimalDelimiter === thousandDelimiter && decimalDelimiter !== 'none') ||
      (decimalDelimiter === thousandDelimiter && decimalDelimiter !== 'space');

    if (decimalDelimiter === 'none') return '';

    if (hasSameDelimiter) {
      return decimalDelimiter === 'comma' ? '.' : ',';
    }

    return numberDelimiterMapping[decimalDelimiter];
  };

  const {
    ref: inputRef,
    value: iMaskValue,
    typedValue
  } = useIMask<HTMLInputElement>(
    {
      mask: Number,
      scale: decimalDelimiter !== 'none' ? input.format.precision : 0,
      ...(thousandDelimiter !== 'none' && {
        thousandsSeparator: numberDelimiterMapping[thousandDelimiter]
      }),
      ...(decimalDelimiter !== 'none' && {
        radix: formatDecimalDelimiter()
      }),
      padFractionalZeros: true
    },
    {
      defaultValue: inputValue?.toString(),
      onAccept: (val: string, maskRef) => {
        const rawValue = maskRef.rawInputValue;
        setFormValue(input.field.key, rawValue);
      }
    }
  );

  const { ref: formRef, onBlur, ...formRegister } = register(input.field.key);

  const renderFormatTooltip = () => (
    <Tooltip.Content align="start" side="bottom">
      <p className="text-xs">
        {t('components.views.form.number_input.field_format')}{' '}
        {`12${numberDelimiterMapping[thousandDelimiter]}345${formatDecimalDelimiter()}67`}
      </p>
    </Tooltip.Content>
  );

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        {numberFieldFormat ? (
          <InputWithDisabledText
            ref={inputRef}
            id={`${view.key}-${input.id}`}
            disabled={isReadOnly}
            intent={errors[input.field.key] ? 'destructive' : 'default'}
            placeholder={t('components.views.form.number_input.placeholder')}
            data-testid="form-view-number-input"
            value={typedValue !== undefined ? iMaskValue : inputValue}
            {...((numberFieldFormat === '€_after' ||
              (numberFieldFormat === 'custom' && input.format.post)) && {
              disabledTextRight: numberFieldFormat === 'custom' ? input.format.post : '€'
            })}
            {...(numberFieldFormat &&
              numberFieldFormat !== '€_after' && {
                disabledTextLeft:
                  numberFieldFormat === 'custom' ? input.format.pre : numberFieldFormat
              })}
            {...formRegister}
          />
        ) : (
          <Input
            ref={inputRef}
            disabled={isReadOnly}
            id={`${view.key}-${input.id}`}
            intent={errors[input.field.key] ? 'destructive' : 'default'}
            placeholder={t('components.views.form.number_input.placeholder')}
            data-testid="form-view-number-input"
            className="text-sm"
            value={typedValue !== undefined ? iMaskValue : inputValue}
            {...formRegister}
          />
        )}
      </Tooltip.Trigger>
      <FormErrorMessage errors={errors} name={input.field.key} />
      {renderFormatTooltip()}
    </Tooltip>
  );
}

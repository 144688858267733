import { RichTextRenderer } from '@knack/asterisk-react';

import { type RichTextView } from '@/types/schema/views/RichTextView';
import { useViewContext } from '@/components/views/ViewContext';

export function RichTextViewRender() {
  const { view } = useViewContext<RichTextView>();

  return (
    <RichTextRenderer
      dangerouslySetInnerHTML={{
        __html: view.content
      }}
    />
  );
}

interface PageErrorProps {
  title: string;
  message: string;
  button?: JSX.Element;
}

export function PageError({ title, message, button }: PageErrorProps) {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <span aria-label="Sad face with tear" className="mb-6 text-5xl">
        😢
      </span>
      <h2 className="mb-2 text-2xl font-medium text-emphasis">{title}</h2>
      <p className="mb-6">{message}</p>
      {button}
    </div>
  );
}

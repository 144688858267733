import { type ListView } from '@/types/schema/views/ListView';

export const listViewDemoView: ListView = {
  id: '66ba08315c4e01010ffd8bf2',
  columns: [
    {
      id: 'column_1',
      groups: [
        {
          id: 'group_1',
          columns: [
            [
              {
                id: 'field_17',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_17',
                name: 'Date'
              },
              {
                id: 'field_18',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_18',
                name: 'Notes'
              },
              {
                id: 'field_19',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_19',
                name: 'Yes/No'
              },
              {
                id: 'field_20',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_20',
                name: 'Task or Meeting'
              },
              {
                id: 'field_21',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_21',
                name: 'Deal Size'
              },
              {
                id: 'field_24',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: 'delete',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'delete',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_24',
                name: ''
              },
              {
                id: 'field_22',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_22',
                name: 'Contact'
              },
              {
                id: 'field_23',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_23',
                name: 'Title'
              },
              {
                id: 'field_27',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: '',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'field',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                key: 'field_27',
                name: 'Image'
              },
              {
                id: 'field_25',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: 'view',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'scene_link',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                scene: 'mock-data-8-details',
                key: 'field_25',
                name: ''
              },
              {
                id: 'field_28',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: 'edit',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'scene_link',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                scene: 'edit-mock-data-83',
                key: 'field_28',
                name: ''
              },
              {
                id: 'field_26',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: 'Edit Data',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'scene_link',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                scene: 'edit-mock-data-8',
                key: 'field_26',
                name: ''
              },
              {
                id: 'field_29',
                conn_separator: '',
                conn_link: '',
                link_type: 'text',
                link_text: 'action',
                link_field: '',
                link_design_active: false,
                icon: {
                  icon: '',
                  align: 'left'
                },
                img_gallery: '',
                show_map: false,
                map_width: 400,
                map_height: 300,
                value: '',
                copy: '',
                type: 'action_link',
                format: {
                  label_custom: false,
                  label_format: 'left',
                  styles: []
                },
                action_rules: [
                  {
                    criteria: [],
                    link_text: 'action',
                    record_rules: [],
                    submit_rules: [
                      {
                        action: 'message',
                        message: 'Action successfully completed.',
                        reload_show: false,
                        key: '',
                        is_default: false
                      }
                    ]
                  }
                ],
                name: 'Trigger an action',
                key: 'field_29'
              }
            ]
          ]
        }
      ],
      width: 100
    }
  ],
  links: [],
  inputs: [],
  groups: [],
  hide_fields: false,
  keyword_search_fields: 'view',
  name: 'MOCK_DATA (8)s',
  type: 'list',
  source: {
    criteria: {
      match: 'all',
      rules: [],
      groups: []
    },
    limit: null,
    sort: [
      {
        field: 'field_17',
        order: 'asc'
      }
    ],
    object: 'object_6'
  },
  description: '',
  layout: 'full',
  label_format: 'left',
  rows_per_page: '25',
  allow_limit: true,
  allow_exporting: false,
  keyword_search: true,
  allow_preset_filters: false,
  filter_type: 'fields',
  preset_filters: [],
  menu_filters: [],
  filter_fields: 'view',
  rules: {
    fields: []
  },
  list_layout: 'two-column',
  key: 'view_46',
  no_data_text: 'No Data',
  design: {}
};

import { type FormViewConnectionInput } from '@/types/schema/views/form/Connection';
import { isPageEditor, isPageEditorPreview } from '@/utils/iframe';
import { AddConnectedRecordModal } from './AddConnectedRecordModal';
import { AddConnectedRecordModalPageEditor } from './AddConnectedRecordModalPageEditor';
import { AddConnectedRecordModalPageEditorPreview } from './AddConnectedRecordModalPageEditorPreview';

interface AddConnectedRecordButtonProps {
  connectionInput: FormViewConnectionInput;
  onSuccess: (recordId: string) => void;
}

export function AddConnectedRecordButton({
  connectionInput,
  onSuccess
}: AddConnectedRecordButtonProps) {
  if (isPageEditor()) {
    return <AddConnectedRecordModalPageEditor connectionInput={connectionInput} />;
  }

  if (isPageEditorPreview()) {
    return <AddConnectedRecordModalPageEditorPreview connectionInput={connectionInput} />;
  }

  return <AddConnectedRecordModal connectionInput={connectionInput} onSuccess={onSuccess} />;
}

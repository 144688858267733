import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

export type SignupButtonProps = {
  userRoleName?: string;
  onClick: () => void;
};

export function SignupButton({ userRoleName, onClick }: SignupButtonProps) {
  const [t] = useTranslation();

  const buttonText = userRoleName
    ? t('components.views.login.login.sign_up_as', { userRole: userRoleName })
    : t('components.views.login.login.sign_up');

  return (
    <Button intent="link" className="text-medium ml-2" onClick={onClick}>
      {buttonText}
    </Button>
  );
}

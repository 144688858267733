import { axiosInstance as axios } from '@/utils/axiosConfig';
import { usePageContext } from '@/context/PageContext';
import { useApplicationQuery } from './useApplicationQuery';

export type ExportViewRecordsFormat = 'csv' | 'json' | 'text';

type ExportViewRecordsParams = {
  viewKey: string;
  fileFormat: ExportViewRecordsFormat;
  fileName: string | undefined;
};

export function downloadExportedFile(
  exportTableFormat: ExportViewRecordsFormat,
  exportTableData: Blob,
  fileName: string
) {
  // The server is expecting text instead of txt, so we need to convert back to txt
  const fileFormat = exportTableFormat === 'text' ? 'txt' : exportTableFormat;
  const downloadUrl = URL.createObjectURL(exportTableData);
  const temporaryLink = document.createElement('a');
  temporaryLink.href = downloadUrl;
  temporaryLink.download = `${fileName}.${fileFormat}`;
  temporaryLink.click();
  URL.revokeObjectURL(downloadUrl);
}

export function useExportViewRecords() {
  const { data: application } = useApplicationQuery();
  const { activePage } = usePageContext();

  if (!application) throw new Error('Application not found');
  if (!activePage) throw new Error('Page not found');

  const pageKey = activePage.key;
  const appId = application.id;

  async function exportViewRecords({
    viewKey,
    fileFormat,
    fileName = 'exported-table'
  }: ExportViewRecordsParams) {
    const url = `/v1/scenes/${pageKey}/views/${viewKey}/records/export/applications/${appId}/?type=${fileFormat}`;
    const { data: exportFile } = await axios.get<Blob>(url, {
      withCredentials: true,
      responseType: 'blob'
    });
    downloadExportedFile(fileFormat, exportFile, fileName);
  }

  return exportViewRecords;
}

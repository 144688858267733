import { useState } from 'react';
import { HiFunnel as FilterIcon } from 'react-icons/hi2';
import { Tabs } from '@knack/asterisk-react';

import { type KnackMenuFilter } from '@/types/schema/LiveAppView';
import { useViewFilters } from '@/hooks/useViewFilters';
import { type ViewWithSearchParams } from '@/hooks/useViewSearchParams';
import { useViewContext } from '@/components/views/ViewContext';

export function ViewMenuFilters({ menuFilters }: { menuFilters: KnackMenuFilter[] }) {
  const { view, sourceTable } = useViewContext<ViewWithSearchParams>();
  const { saveActiveFilters } = useViewFilters({ view, sourceTable });

  const [activeMenuFilter, setActiveMenuFilter] = useState('');

  const handleSelectMenuFilter = (selectedMenuFilter: string) => {
    if (selectedMenuFilter === activeMenuFilter) {
      setActiveMenuFilter('');
      saveActiveFilters(null);
      return;
    }

    const menuFilter = menuFilters.find((filter) => filter.value === selectedMenuFilter);

    if (!menuFilter) return;

    setActiveMenuFilter(selectedMenuFilter);
    saveActiveFilters({
      match: 'and',
      rules: [
        {
          match: 'and',
          field: menuFilter.field,
          operator: menuFilter.operator,
          value: menuFilter.value,
          field_name: menuFilter.text
        }
      ]
    });
  };

  const items = menuFilters.map((filter) => ({
    value: filter.value,
    children: <span>{filter.text}</span>,
    onClick: () => handleSelectMenuFilter(filter.value)
  }));

  return (
    <Tabs value={activeMenuFilter}>
      <Tabs.List items={items} intent="default">
        <FilterIcon size={16} className="mr-1" />
      </Tabs.List>
    </Tabs>
  );
}

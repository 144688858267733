import { useEffect, useRef, useState } from 'react';
import { Table, Tooltip } from '@knack/asterisk-react';
import { truncate } from 'lodash';

import {
  type TableView,
  type TableViewColumn,
  type TableViewTotalSummary
} from '@/types/schema/views/TableView';
import { useViewContext } from '@/components/views/ViewContext';
import {
  calculateCharactersInWidth,
  formatCurrencyValueForSummary,
  getSummaryValue,
  hasExceededMaxLines,
  type ColumnSummariesValues
} from './helper';

interface TableViewSummaryRowProps {
  columnSummary: {
    label: string;
    calc: TableViewTotalSummary['calc'];
  };
  columns: TableViewColumn[];
  summariesValues: ColumnSummariesValues;
}
const cellMaxWidthInPx = 140;
const maxNumberCharacters = calculateCharactersInWidth(cellMaxWidthInPx);
const tableCellClasses = `border-x-0 bg-muted p-2 text-emphasis max-w-[${cellMaxWidthInPx}px] font-semibold hover:bg-muted border-b border-default`;

function TableViewSummaryLabel({ label }: { label: string }) {
  const cellRef = useRef<HTMLTableCellElement>(null);
  const [shouldTruncate, setShouldTruncate] = useState(false);

  useEffect(() => {
    if (cellRef.current) {
      setShouldTruncate(hasExceededMaxLines(cellRef.current));
    }
  }, [label]);

  if (label.length > maxNumberCharacters) {
    return (
      <Tooltip>
        <Tooltip.Trigger asChild>
          <Table.Cell ref={cellRef} className={tableCellClasses}>
            {shouldTruncate
              ? truncate(label, {
                  length: maxNumberCharacters,
                  omission: '...'
                })
              : label}
          </Table.Cell>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content align="start">{label}</Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip>
    );
  }
  return <Table.Cell className={tableCellClasses}>{label}</Table.Cell>;
}

export function TableViewSummaryRow({
  columnSummary,
  columns,
  summariesValues
}: TableViewSummaryRowProps) {
  const { sourceTable } = useViewContext<TableView>();
  return (
    <Table.Row>
      <TableViewSummaryLabel label={columnSummary.label} />
      {columns.map((column, columnIndex) => {
        const sourceTableField = sourceTable.fields.find(
          (field) => field.key === column.field?.key
        );

        // If action column or ignore summary then return an empty cell
        if (!sourceTableField || column.ignore_summary) {
          return (
            <Table.Cell
              // eslint-disable-next-line react/no-array-index-key
              key={`${column.id}-${columnIndex}-summary`}
              className={tableCellClasses}
            />
          );
        }

        const summaryValue = getSummaryValue(summariesValues, column.field.key, columnSummary.calc);

        return (
          <Table.Cell
            // eslint-disable-next-line react/no-array-index-key
            key={`${column.id}-${columnIndex}-summary`}
            className={tableCellClasses}
          >
            {sourceTableField.type === 'currency' && (summaryValue || summaryValue === 0)
              ? formatCurrencyValueForSummary(summaryValue, sourceTableField.format)
              : summaryValue}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );
}

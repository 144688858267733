import { Controller } from 'react-hook-form';
import { Combobox, Spinner, type ComboboxOption } from '@knack/asterisk-react';

import { type ConnectionField } from '@/types/schema/fields';
import { useConnectionRecordsQuery } from '@/hooks/api/queries/useConnectionRecordsQuery';
import { formatConnectionInputIdentifier } from '@/components/views/form/utils';
import { type ViewFilterSpecialInput } from '@/components/views/view-options/ViewFilterRow';
import { useViewContext } from '@/components/views/ViewContext';

export function FilterConnectionInput({
  formFieldName,
  field
}: ViewFilterSpecialInput<ConnectionField>) {
  const { view } = useViewContext();
  const { data: connectionRecords, isLoading } = useConnectionRecordsQuery({
    viewKey: view.key,
    fieldKey: field.key
  });

  const getRecordIdentifierFromId = (id: string) => {
    if (!connectionRecords) return '';
    const record = connectionRecords.records.find((connection) => connection.id === id);
    return record?.identifier || '';
  };

  const options =
    connectionRecords?.records.map((record) => ({
      label: formatConnectionInputIdentifier(record.identifier),
      key: record.id
    })) || [];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Controller
      name={formFieldName}
      render={({ field: { value, onChange } }) => {
        const formattedValue: ComboboxOption = {
          key: value?.[0],
          label: value?.[0]?.identifier || getRecordIdentifierFromId(value?.[0])
        };
        return (
          <Combobox
            id={`${formFieldName}-filters-connection-input`}
            options={options}
            isSearchEnabled
            selectedOption={formattedValue}
            onSelectOption={(option) => onChange([option.key])}
          />
        );
      }}
    />
  );
}

import { createContext, useContext, type ReactNode } from 'react';
import { Banner } from '@knack/asterisk-react';

import { type Session } from '@/types/session';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';

const SessionContext = createContext<Session | null>(null);

export const useSessionContext = () => {
  const session = useContext(SessionContext);
  return session;
};

export function SessionContextProvider({ children }: { children: ReactNode }) {
  const sessionQuery = useSessionQuery();

  if (sessionQuery.isLoading) {
    return null;
  }

  if (sessionQuery.isError) {
    return (
      <Banner intent="destructive">
        <Banner.Message>{sessionQuery.error.message}</Banner.Message>
      </Banner>
    );
  }

  return (
    <SessionContext.Provider value={sessionQuery.data?.session ?? null}>
      {children}
    </SessionContext.Provider>
  );
}

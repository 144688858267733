import { type LiveAppNewViewKey } from '@/types/schema/LiveAppView';
import { type ReportView } from '@/types/schema/views/ReportView';
import { useNewReportQuery } from '@/hooks/api/queries/useNewReportQuery';
import { useReportQuery } from '@/hooks/api/queries/useReportQuery';
import { isIframedByBuilder } from '@/utils/iframe';
import { useViewContext } from '@/components/views/ViewContext';
import { AreaChartRender } from './AreaChartRender';
import { BarChartHorizontalRender } from './BarChartHorizontalRender';
import { BarChartRender } from './BarChartRender';
import { LineChartRender } from './LineChartRender';
import { PieChartRender } from './PieChartRender';

export function ReportViewRender() {
  const { view } = useViewContext<ReportView>();

  const isNewView = view.key.startsWith('new_view_' satisfies LiveAppNewViewKey);

  // For existing report views we call the regular report query
  const { data: existingReportData } = useReportQuery({
    viewKey: view.key,
    enabled: !isNewView
  });

  // For new report views (when iframed in the builder) we call the query for new reports
  const { data: newReportData } = useNewReportQuery({
    viewKey: view.key,
    report: view.rows[0].reports[0],
    enabled: isNewView && isIframedByBuilder()
  });

  if (
    !view.rows ||
    Number(view.rows.length) === 0 ||
    !view.rows[0].reports ||
    view.rows[0].reports.length === 0
  ) {
    return null;
  }

  const data = isNewView ? newReportData : existingReportData;
  const chartType = view.rows[0].reports[0].type;
  const barType = view.rows[0].reports[0].layout.bar_type;

  switch (chartType) {
    case 'bar': {
      if (barType === 'column') {
        return <BarChartRender data={data} view={view} />;
      }
      return <BarChartHorizontalRender data={data} view={view} />;
    }
    case 'pie': {
      return <PieChartRender data={data} view={view} />;
    }
    case 'line': {
      return <LineChartRender data={data} view={view} />;
    }
    case 'area': {
      return <AreaChartRender data={data} view={view} />;
    }
    default: {
      return null;
    }
  }
}

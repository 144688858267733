import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Card, Tabs, ThemeProviderContext } from '@knack/asterisk-react';

import { type LiveAppView } from '@/types/schema/LiveAppView';
import { LoginViewRender } from '@/components/views/auth/login/LoginViewRender';
import { DetailsViewRender } from '@/components/views/details/DetailsViewRender';
import { FormViewRender } from '@/components/views/form/FormViewRender';
import { ListViewRender } from '@/components/views/list/ListViewRender';
import { RichTextViewRender } from '@/components/views/rich-text/RichTextViewRender';
import { TableViewRender } from '@/components/views/table/TableViewRender';
import { ViewContextProvider } from '@/components/views/ViewContext';
import { AuthFlowContextProvider } from '@/pages/page/AuthFlowContext';
import { detailsViewDemoView } from '@/pages/theme/demo/detailsViewDemo';
import { formViewDemoView } from '@/pages/theme/demo/formViewDemo';
import { listViewDemoView } from '@/pages/theme/demo/listViewDemo';
import { loginViewDemoView } from '@/pages/theme/demo/loginViewDemo';
import { richTextDemoView } from '@/pages/theme/demo/richTextViewDemo';
import { tableDemoData } from '@/pages/theme/demo/tableDemoData';
import { tableViewDemoView } from '@/pages/theme/demo/tableViewDemo';
import { useMessagingContext } from '@/context/MessagingContext';
import { detailsDemoData } from './demo/detailsDemoData';
import { tableDemo } from './demo/tableDemo';

function DemoView({ view, children }: { view: LiveAppView; children: React.ReactNode }) {
  return (
    <AuthFlowContextProvider>
      <ViewContextProvider view={view} demoSourceTable={tableDemo}>
        {children}
      </ViewContextProvider>
    </AuthFlowContextProvider>
  );
}

export function ThemePage() {
  const [t] = useTranslation();
  const { messageFromBuilder, sendMessageToBuilder } = useMessagingContext();
  const { setDarkMode } = useContext(ThemeProviderContext);
  const items = [
    {
      children: <span>{t('components.page_editor.demo_page.tabs.list')}</span>,
      value: 'list'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.details')}</span>,
      value: 'details'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.form')}</span>,
      value: 'form'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.table')}</span>,
      value: 'table'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.content')}</span>,
      value: 'content'
    },
    {
      children: <span>{t('components.page_editor.demo_page.tabs.login')}</span>,
      value: 'login'
    }
  ];

  // Block internal navigation since the app is running inside the builder
  useBlocker(true);

  // Tell the builder app that this page is ready to receive messages
  sendMessageToBuilder({ action: 'request-page-sync' });

  useEffect(() => {
    if (!messageFromBuilder) {
      return;
    }

    if (messageFromBuilder.action === 'set-theme-preview-mode') {
      // While the Live App is in preview mode inside the builder, we must avoid updating
      // the themeMode key in the localStorage to prevent affecting other active theme
      if (messageFromBuilder.mode === 'system') {
        const media = window.matchMedia('(prefers-color-scheme: dark)');
        const isSystemDark = media.matches;
        setDarkMode(isSystemDark);
      } else {
        setDarkMode(messageFromBuilder.mode === 'dark');
      }
    }
  }, [messageFromBuilder, setDarkMode]);

  return (
    <div className="pb-12">
      <h1 className="mb-2 text-3xl font-semibold">{t('components.page_editor.demo_page.title')}</h1>
      <Card className="mb-6 sm:p-6 md:p-6">
        <p className="text-default">{t('components.page_editor.demo_page.description')}</p>
      </Card>
      <Tabs defaultValue="list">
        <Tabs.List intent="page" items={items} />
        <Card className="mb-6 sm:p-6 md:p-6">
          <Tabs.Content intent="page" value="list">
            <DemoView view={listViewDemoView}>
              <ListViewRender sourceData={tableDemoData} />
            </DemoView>
          </Tabs.Content>
          <Tabs.Content intent="page" value="details">
            <DemoView view={detailsViewDemoView}>
              <DetailsViewRender sourceData={detailsDemoData} />
            </DemoView>
          </Tabs.Content>
          <Tabs.Content intent="page" value="form">
            <DemoView view={formViewDemoView}>
              <FormViewRender />
            </DemoView>
          </Tabs.Content>
          <Tabs.Content intent="page" value="table">
            <DemoView view={tableViewDemoView}>
              <TableViewRender sourceData={tableDemoData} />
            </DemoView>
          </Tabs.Content>
          <Tabs.Content intent="page" value="content">
            <DemoView view={richTextDemoView}>
              <RichTextViewRender />
            </DemoView>
          </Tabs.Content>
          <Tabs.Content intent="page" value="login">
            <DemoView view={loginViewDemoView}>
              <LoginViewRender />
            </DemoView>
          </Tabs.Content>
        </Card>
      </Tabs>
    </div>
  );
}

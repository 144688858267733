import { useEffect, useState } from 'react';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiArrowPath as ReloadIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, RichTextRenderer, Spinner, useToast } from '@knack/asterisk-react';
import { isAxiosError } from 'axios';
import { type z } from 'zod';

import { type FormView } from '@/types/schema/views/FormView';
import { useAddRecordMutation } from '@/hooks/api/mutations/useAddRecordMutation';
import { useSignUpMutation } from '@/hooks/api/mutations/useSignUpMutation';
import { useUpdateRecordMutation } from '@/hooks/api/mutations/useUpdateRecordMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useViewMultipleRecordsQuery } from '@/hooks/api/queries/useViewMultipleRecordsQuery';
import { useViewRecordQuery, type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { useViewRules } from '@/hooks/useViewRules';
import { isIframedByBuilder, isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { ReactHookFormDevTools } from '@/components/ReactHookFormDevTools';
import { BackToLoginButton } from '@/components/views/auth/BackToLoginButton';
import { useViewContext } from '@/components/views/ViewContext';
import { ViewHeaderSection } from '@/components/views/ViewHeaderSection';
import { useAuthFlow } from '@/pages/page/AuthFlowContext';
import { usePageContext } from '@/context/PageContext';
import { FormGroupsSortableWrapper } from './form-groups-sortable/FormGroupsSortableWrapper';
import { FormDisplayRulesContextProvider } from './FormDisplayRulesContext';
import { FormGroups } from './FormGroups';
import { getDynamicFormSchema } from './schema/helper';
import { SignUpErrorBanner, type SignUpError } from './SignUpErrorBanner';
import { getDefaultFormValues, getFormViewInputs } from './utils';

function FormViewRenderContent({
  recordValues,
  onSubmitSuccess
}: {
  recordValues?: ViewRecord;
  onSubmitSuccess?: (newRecord: ViewRecord) => void;
}) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { view, sourceTable } = useViewContext<FormView>();
  const { activePageRecordId } = usePageContext();
  const { activeAuthFlow } = useAuthFlow();

  const { data: application } = useApplicationQuery();
  const { mutate: addRecord } = useAddRecordMutation();
  const { mutate: updateRecord } = useUpdateRecordMutation({ viewKey: view.key });
  const { mutate: signUp } = useSignUpMutation();
  const { handleSubmitRules, applicableSubmitRuleValues, setApplicableSubmitRuleValues } =
    useViewRules();

  const { confirmationMessage, shouldShowReloadButton, shouldReloadAutomatically } =
    applicableSubmitRuleValues;

  // We use this key to force a remount of the form element to ensure that the inputs that use IMask get their default values reset
  const [formKey, setFormKey] = useState(0);

  const submitRules = view.rules.submits;
  const formSchema = getDynamicFormSchema({
    inputs: getFormViewInputs(view.groups),
    sourceTableFields: sourceTable.fields,
    ...(application?.settings.passwordOptions && {
      passwordOptions: application.settings.passwordOptions
    })
  });

  type FormSchemaType = z.infer<typeof formSchema>;

  const defaultFormValues = getDefaultFormValues(
    getFormViewInputs(view.groups),
    sourceTable.fields
  );

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: recordValues || defaultFormValues
  });

  const onSubmitHandler: SubmitHandler<FormSchemaType> = (data) => {
    const handleFormError = (err: Error, isSignUpError?: boolean) => {
      if (isAxiosError(err) && err.response) {
        const { field, message, type } = err.response.data.errors[0];
        form.setError(isSignUpError ? 'signUpError' : field, {
          message,
          type: isSignUpError ? type : 'schema'
        });
      }
    };

    if (view.type === 'registration') {
      signUp(
        { viewKey: view.key, objectKey: view.source.object, data },
        {
          onSuccess: () => {
            handleSubmitRules({ data, rules: submitRules });
            form.reset(defaultFormValues);
          },
          onError: (error) => handleFormError(error, true)
        }
      );
      return;
    }

    if (view.action === 'create' || view.action === 'insert') {
      addRecord(
        {
          viewKey: view.key,
          data
        },
        {
          onSuccess: (newRecord) => {
            if (onSubmitSuccess) {
              onSubmitSuccess(newRecord);
              return;
            }
            handleSubmitRules({ data, rules: submitRules });
            form.reset(defaultFormValues);
            setFormKey((prev) => prev + 1);
          },
          onError: (error) => handleFormError(error)
        }
      );
    }
    if (view.action === 'update') {
      updateRecord(
        {
          data,
          recordId: activePageRecordId || recordValues?.id
        },
        {
          onSuccess: (updatedRecord) => {
            if (onSubmitSuccess) {
              onSubmitSuccess(updatedRecord);
              return;
            }
            handleSubmitRules({ data, rules: submitRules });
          },
          onError: (error) => handleFormError(error)
        }
      );
    }
  };

  const formatDataForSubmit = (data: FormSchemaType) => {
    const formattedData = { ...data };

    Object.keys(formattedData).forEach((key) => {
      // When adding a MultipleChoice Single Select, verify if the value of the key is 'kn-blank' and replace it with an empty string
      if (formattedData[key] === 'kn-blank') formattedData[key] = '';
      // If the field is a timer, we need to format the data to match the expected format from the Server
      if (formattedData[key]?.from) {
        formattedData[key] = {
          times: [formattedData[key]]
        };
      }
      // If the field is a file type and we are updating a record, we clean unnecessary data
      if (formattedData[key]?.type === 'file' || formattedData[key]?.type === 'image') {
        formattedData[key] = formattedData[key].id;
      }

      // When updating a user record password, we need to match the expected format from the Server
      if (view.action === 'update') {
        if (formattedData[key].confirmPassword) {
          formattedData[key].password_confirmation = formattedData[key].confirmPassword;
          delete formattedData[key].confirmPassword;
        }
        if (formattedData[key].currentPassword) {
          formattedData[key].password_authentication = formattedData[key].currentPassword;
          delete formattedData[key].currentPassword;
        }
      }
    });
    onSubmitHandler(formattedData);
  };

  const getFormRenderContent = () => (
    <>
      <FormProvider {...form}>
        <form
          id={`${view.key}-form`}
          key={`${view.key}-${formKey}`}
          className={cn('space-y-2', {
            'flex flex-col items-center justify-center p-10':
              view.type === 'registration' && view.groups[0].columns.length === 1
          })}
          data-testid="form-view"
          onSubmit={(e) => {
            e.stopPropagation();

            if (isIframedByBuilder()) {
              e.preventDefault();
              return undefined;
            }

            return form.handleSubmit(formatDataForSubmit, () => {
              presentToast({
                title: t('components.views.form.submit_error'),
                intent: 'destructive'
              });
            })(e);
          }}
        >
          <ViewHeaderSection view={view} />
          {form.formState.errors.signUpError && (
            <SignUpErrorBanner error={form.formState.errors.signUpError as SignUpError} />
          )}
          <FormDisplayRulesContextProvider values={form.watch()} displayRules={view.rules}>
            {isPageEditor() ? (
              <FormGroupsSortableWrapper view={view}>
                <FormGroups view={view} />
              </FormGroupsSortableWrapper>
            ) : (
              <FormGroups view={view} />
            )}
          </FormDisplayRulesContextProvider>

          <div className="flex gap-4">
            <Button intent="primary" type="submit">
              {view.submitButtonText || t('actions.submit')}
            </Button>
            <Button
              intent="secondary"
              onClick={() => {
                if (isIframedByBuilder()) {
                  return;
                }

                form.reset();
                setFormKey((prev) => prev + 1);
              }}
            >
              {t('actions.clear')}
            </Button>
          </div>
        </form>
      </FormProvider>

      {!onSubmitSuccess && <ReactHookFormDevTools control={form.control} />}

      {activeAuthFlow === 'signup' && (
        <div className="flex justify-center">
          <BackToLoginButton />
        </div>
      )}
    </>
  );

  // Recalculate the form default values when the app is iframed in the builder and the view changes.
  // This is necessary because the inputs can change dynamically from outside the context of the form (e.g. from the builder's page editor),
  // so the form should be able to dynamically update its default values when the view changes.
  useEffect(() => {
    if (!isIframedByBuilder()) {
      return;
    }

    // If the view is an 'edit' form and there is a record associated with the view, we need to keep track of new inputs being added to the form via the page editor.
    if (view.action === 'update' && recordValues) {
      const newDefaultFormValues = { ...recordValues };

      // We look through the default form values for new field records that were added to the form that are not present in the record values
      Object.keys(defaultFormValues).forEach((fieldKey) => {
        if (!recordValues[fieldKey]) {
          newDefaultFormValues[fieldKey] = defaultFormValues[fieldKey];
        }
      });

      form.reset(newDefaultFormValues);
      return;
    }

    form.reset(defaultFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  if (confirmationMessage) {
    return (
      <>
        <RichTextRenderer
          dangerouslySetInnerHTML={{
            __html: confirmationMessage
          }}
        />

        {shouldShowReloadButton && (
          <Button
            data-testid="form-view-reload-button"
            intent="secondary"
            className="mt-4 gap-2"
            onClick={() =>
              setApplicableSubmitRuleValues((prev) => ({
                ...prev,
                confirmationMessage: ''
              }))
            }
          >
            <ReloadIcon size={16} className="shrink-0" />
            {view.reload_form_text || t('actions.reload')}
          </Button>
        )}

        {shouldReloadAutomatically && <div className="mt-4">{getFormRenderContent()}</div>}
      </>
    );
  }

  return getFormRenderContent();
}

export function FormViewRender({
  onSubmitSuccess
}: {
  onSubmitSuccess?: (newRecord: ViewRecord) => void;
}) {
  const { view } = useViewContext<FormView>();
  const { activePageRecordId } = usePageContext();

  // If this is an edit form and there is a record associated with the page, we use that record id to fetch the data
  const { data: viewRecordFromPage, isLoading: isViewRecordFromPageLoading } = useViewRecordQuery({
    viewKey: view.key,
    objectKey: view.source.object,
    enabled: view.action === 'update' && !!activePageRecordId,
    options: {
      format: 'both'
    }
  });

  // If this is an edit form and there is no record associated with the page, we use the record id associated with the view to fetch the data
  const { data: viewRecordFromView, isLoading: isViewRecordFromViewLoading } =
    useViewMultipleRecordsQuery({
      viewKey: view.key,
      objectKey: view.source.object,
      enabled: view.action === 'update' && !activePageRecordId,
      options: {
        format: 'both'
      }
    });

  if (isViewRecordFromPageLoading || isViewRecordFromViewLoading) {
    return <Spinner />;
  }

  return (
    <FormViewRenderContent
      onSubmitSuccess={onSubmitSuccess}
      recordValues={viewRecordFromPage?.rawValues || viewRecordFromView?.records[0]?.rawValues}
    />
  );
}

import { forwardRef, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from '@knack/asterisk-react';

import { type MenuViewLink } from '@/types/schema/views/MenuView';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { getApplicationBasePath } from '@/utils/application';
import { isIframedByBuilder } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';

const FontAwesomeIcon = lazy(() => import('@/components/FontAwesomeIcon'));

interface MenuLinkContentProps {
  link: MenuViewLink;
}

function MenuLinkIcon({ icon, position }: { icon: string; position?: 'left' | 'right' }) {
  return (
    <Suspense fallback={<Spinner />}>
      <FontAwesomeIcon
        icon={icon}
        className={cn({
          'mr-2': position === 'left',
          'ml-2': position === 'right'
        })}
      />
    </Suspense>
  );
}

export function MenuLinkContentWithRef(
  { link, ...props }: MenuLinkContentProps,
  ref: React.Ref<HTMLAnchorElement>
) {
  const { getPagePathBySlug } = usePageHelpers();

  const getLinkTextWithIcon = () => {
    const icon = link.icon?.icon || undefined;
    const iconPosition = link.icon?.align || 'left';

    return (
      <>
        {icon && iconPosition === 'left' && <MenuLinkIcon icon={icon} position="left" />}
        {link.name}
        {icon && iconPosition === 'right' && <MenuLinkIcon icon={icon} position="right" />}
      </>
    );
  };

  // We don't want any links to be clickable/linkable in the builder
  if (isIframedByBuilder()) {
    return <span {...props}>{getLinkTextWithIcon()}</span>;
  }

  if (link.type === 'url') {
    return (
      <a
        ref={ref}
        href={link.url}
        target={link.new_window ? '_blank' : undefined}
        rel={link.new_window ? 'noreferrer' : undefined}
        {...props}
      >
        {getLinkTextWithIcon()}
      </a>
    );
  }

  const scenePath = getPagePathBySlug(link.scene);

  if (link.new_window) {
    const fullPagePath = `${window.location.origin}${getApplicationBasePath()}${scenePath}`;

    return (
      <a ref={ref} href={fullPagePath} target="_blank" rel="noreferrer" {...props}>
        {getLinkTextWithIcon()}
      </a>
    );
  }

  return (
    <Link ref={ref} to={scenePath} {...props}>
      {getLinkTextWithIcon()}
    </Link>
  );
}

export const MenuLinkContent = forwardRef<HTMLAnchorElement, MenuLinkContentProps>(
  MenuLinkContentWithRef
);

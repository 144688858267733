import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { type KnackFieldKey } from '@/types/schema/fields/KnackField';
import { type LiveAppTableKey } from '@/types/schema/LiveAppTable';
import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios, getAppBasedRequestHeaders } from '@/utils/axiosConfig';
import { isIframedByBuilder } from '@/utils/iframe';
import { usePageContext } from '@/context/PageContext';
import { type ViewRecord } from './useViewRecordQuery';

export interface ViewRecordsResponse {
  records: ViewRecord[];
  total_pages: number;
  current_page: number;
  total_records: number;
}

type ViewRecordsQueryOptions = {
  format?: 'both' | 'raw';
  rowsPerPage?: string;
  page?: number | null;
  search?: string;
  sortField?: KnackFieldKey;
  sortOrder?: 'asc' | 'desc';
  filters?: string | null;
};

type GetViewDataParams = {
  pageKey: string;
  viewKey: LiveAppViewKey;
  objectKey: LiveAppTableKey;
  options?: ViewRecordsQueryOptions;
};

export const formatData = (data: ViewRecordsResponse) => {
  const formattedRecords = data.records.map((record) => {
    const formattedRecord: { values: ViewRecord; rawValues: ViewRecord } = {
      values: {
        id: record.id
      },
      rawValues: {
        id: record.id
      }
    };

    Object.keys(record).forEach((key) => {
      if (key.endsWith('_raw')) {
        const newKey = key.replace('_raw', '');
        formattedRecord.rawValues[newKey] = record[key];

        // If the field is a phone field
        if (record[key]?.area) {
          formattedRecord.rawValues[newKey] = record[key].formatted;
        }

        // If the field is a timer, we need to format the data to match the expected in the frontend
        if (record[key]?.times) {
          [formattedRecord.rawValues[newKey]] = record[key].times;
        }
      } else {
        formattedRecord.values[key] = record[key];
      }
    });

    return formattedRecord;
  });

  return {
    records: formattedRecords,
    totalRecords: data.total_records,
    totalPages: data.total_pages,
    currentPage: data.current_page
  };
};

async function getViewData({ pageKey, viewKey, objectKey, options }: GetViewDataParams) {
  const { rowsPerPage, page, sortOrder, sortField, filters, ...restOptions } = options || {};
  const queryParams = new URLSearchParams({
    ...restOptions,
    ...(rowsPerPage && { rows_per_page: rowsPerPage }),
    ...(page && { page: page.toString() }),
    ...(sortOrder && { sort_order: sortOrder }),
    ...(sortField && { sort_field: sortField }),
    ...(filters && filters !== 'null' && { filters })
  } as Record<string, string>);

  // When iframed by the builder we need to fetch the records from the object endpoint so it returns all fields
  const url = isIframedByBuilder()
    ? `v1/objects/${objectKey}/records`
    : `v1/scenes/${pageKey}/views/${viewKey}/records`;

  const { data: formData } = await axios.get<ViewRecordsResponse>(url, {
    params: queryParams,
    withCredentials: true,
    headers: getAppBasedRequestHeaders()
  });

  return formatData(formData);
}

export function useViewMultipleRecordsQuery({
  viewKey,
  objectKey,
  enabled = true,
  options
}: {
  viewKey: LiveAppViewKey;
  objectKey: LiveAppTableKey;
  enabled?: boolean;
  options?: ViewRecordsQueryOptions;
}) {
  const { activePage } = usePageContext();

  if (!activePage) {
    throw new Error('useViewMultipleRecordsQuery must be used within a page context');
  }

  const optionEntries = Object.entries(options || {}).map(([key, value]) => `${key}-${value}`);
  const queryKey = [queryKeys.viewData, viewKey, ...optionEntries];

  return useQuery({
    queryKey,
    queryFn: async () => getViewData({ pageKey: activePage.key, viewKey, objectKey, options }),
    enabled,
    placeholderData: keepPreviousData
  });
}

import { type KnackFieldType } from '@/types/schema/LiveAppTable';

const filterDefaultOperators = ['is', 'is not', 'is blank', 'is not blank'];

const containsOperators = ['contains', 'does not contain'];

const startsWithOperators = ['starts with', 'ends with'];

const filterTextOperators = [
  ...filterDefaultOperators,
  ...containsOperators,
  ...startsWithOperators
];

const numberComparisonOperators = ['higher than', 'lower than'];

const characterCountOperators = [
  'character count is not',
  'character count is',
  'character count is higher than',
  'character count is lower than'
];

const regexOperators = ['matches regular expression', 'does not match regular expression'];

const domainOperators = ['domain must equal', 'domain must not equal'];

const fileValidationOperators = [
  'size is less than',
  'size is greater than',
  'file type is',
  'file type is not'
];

const dateValidationOperators = [
  'is a day of the week',
  'is between days of the week',
  'is between dates',
  'is a future date',
  'is not a future date'
];

const dateTimeCommonOperators = [
  'is',
  'is not',
  'is before',
  'is after',
  'is today',
  'is today or before',
  'is today or after',
  'is before today',
  'is after today',
  'is before current time',
  'is after current time',
  'is blank',
  'is not blank'
];

const dateTimePeriodOperators = [
  'is during the current',
  'is during the previous',
  'is during the next',
  'is before the previous',
  'is after the next'
];

const passwordCommonOperators = [...containsOperators, ...startsWithOperators];

const filterNumberOperators = [...filterDefaultOperators, ...numberComparisonOperators];

const operatorsRequiringNoInput = [
  'is blank',
  'is not blank',
  'is today',
  'is today or before',
  'is today or after',
  'is before today',
  'is after today',
  'is before current time',
  'is after current time',
  'has changed',
  'is any'
];

const operatorsRequiringRangeInput = [
  'is during the next',
  'is during the previous',
  'is before the previous',
  'is after the next',
  'is during the current'
];

export const ruleOperators: Partial<Record<KnackFieldType, string[]>> = {
  auto_increment: filterNumberOperators,
  boolean: filterDefaultOperators,
  multiple_choice: [...filterDefaultOperators, ...containsOperators, 'is any'],
  user_roles: [...filterDefaultOperators, ...containsOperators, 'is any'],
  connection: [...filterDefaultOperators, ...containsOperators, 'is any'],
  number: filterNumberOperators,
  sum: filterNumberOperators,
  min: filterNumberOperators,
  max: filterNumberOperators,
  currency: [...filterNumberOperators],
  equation: filterNumberOperators,
  date_time: [...dateTimeCommonOperators, ...dateTimePeriodOperators],
  time: dateTimeCommonOperators,
  timer: [...dateTimeCommonOperators, ...dateTimePeriodOperators],
  image: filterDefaultOperators,
  file: filterDefaultOperators,
  signature: filterDefaultOperators,
  link: filterDefaultOperators,
  phone: filterTextOperators,
  short_text: filterTextOperators,
  paragraph_text: filterTextOperators,
  rich_text: filterTextOperators,
  rating: filterNumberOperators,
  email: filterTextOperators,
  address: filterTextOperators,
  name: filterTextOperators,
  concatenation: filterTextOperators,
  password: passwordCommonOperators
};

const validationOperators: Partial<Record<KnackFieldType, string[]>> = {
  ...ruleOperators,
  number: [...filterNumberOperators, 'is one of', 'is not one of'],
  date_time: [...dateTimeCommonOperators, ...dateTimePeriodOperators, ...dateValidationOperators],
  image: [...filterDefaultOperators, ...fileValidationOperators],
  file: [...filterDefaultOperators, ...fileValidationOperators],
  short_text: [...filterTextOperators, ...characterCountOperators, ...regexOperators],
  paragraph_text: [...filterTextOperators, ...characterCountOperators],
  rich_text: [...filterTextOperators, ...characterCountOperators],
  email: [...filterTextOperators, ...domainOperators],
  currency: [...filterNumberOperators, 'is one of'],
  average: filterNumberOperators,
  count: filterNumberOperators,
  password: [...passwordCommonOperators, ...characterCountOperators, ...regexOperators]
};

export const getRulesOperators = (fieldType: KnackFieldType | undefined, isValidation = false) => {
  if (!fieldType) return filterDefaultOperators;
  return isValidation
    ? validationOperators[fieldType] || filterDefaultOperators
    : ruleOperators[fieldType] || filterDefaultOperators;
};

export function isInputRequiredForOperator(operator: string) {
  return !operatorsRequiringNoInput.includes(operator.toLowerCase());
}

export function isRangeRequiredForOperator(operator: string) {
  return operatorsRequiringRangeInput.includes(operator?.toLowerCase());
}

import { Controller } from 'react-hook-form';
import { Select } from '@knack/asterisk-react';

import { type BooleanField } from '@/types/schema/fields';
import {
  booleanOptions,
  getFormattedBooleanDefaultValue
} from '@/components/views/form/inputs/boolean/helper';
import { type ViewFilterSpecialInput } from '@/components/views/view-options/ViewFilterRow';

export function FilterBooleanInput({ formFieldName, field }: ViewFilterSpecialInput<BooleanField>) {
  const { format: booleanFormat } = field;

  return (
    <Controller
      name={formFieldName}
      render={({ field: { value, onChange } }) => {
        const fieldValue =
          typeof value === 'boolean'
            ? getFormattedBooleanDefaultValue(value, booleanFormat)
            : value;

        return (
          <Select value={fieldValue} onValueChange={(val) => onChange(val)}>
            <Select.Trigger id={`${formFieldName}-filter-boolean-input`} className="w-full" />
            <Select.Content>
              <Select.Group>
                {booleanOptions[booleanFormat.format].map((option, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Select.Item key={index} value={option}>
                    {option}
                  </Select.Item>
                ))}
              </Select.Group>
            </Select.Content>
          </Select>
        );
      }}
    />
  );
}

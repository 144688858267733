import { Collapsible, Divider } from '@knack/asterisk-react';

import { type LiveAppPage } from '@/types/schema/LiveAppPage';
import { NavItemWrapper } from '@/components/main-navigation/NavItemWrapper';
import { useNavigation } from '@/components/main-navigation/useNavigation';

export function NavMobileItem({
  item,
  setIsOpen,
  hasDivider,
  isChild
}: {
  item: LiveAppPage;
  setIsOpen: (isOpen: boolean) => void;
  hasDivider?: boolean;
  isChild?: boolean;
}) {
  const { childrenItems, shouldDisplayChildren, isActive } = useNavigation({ item });

  return shouldDisplayChildren ? (
    <>
      <Collapsible defaultOpen>
        <Collapsible.Trigger className="group flex w-full items-center text-subtle">
          <NavItemWrapper item={item} isActive={false} isDropdownMenu isMobile />
        </Collapsible.Trigger>

        <Collapsible.Content className="mt-1 flex flex-col gap-1">
          {childrenItems?.map((childItem) => (
            <NavMobileItem key={childItem.key} item={childItem} setIsOpen={setIsOpen} isChild />
          ))}
        </Collapsible.Content>
      </Collapsible>
      <Divider />
    </>
  ) : (
    <>
      <NavItemWrapper
        item={item}
        setIsOpen={setIsOpen}
        isActive={isActive}
        isChild={isChild}
        isMobile
      />
      {hasDivider && <Divider />}
    </>
  );
}

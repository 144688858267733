import { Table } from '@knack/asterisk-react';

import { type KnackFieldType } from '@/types/schema/LiveAppTable';
import { type FormViewFieldInput } from '@/types/schema/views/FormView';
import { type TableView, type TableViewColumn } from '@/types/schema/views/TableView';
import { type ViewRecord } from '@/hooks/api/queries/useViewRecordQuery';
import { isIframedByBuilder, isPageEditor } from '@/utils/iframe';
import { cn } from '@/utils/tailwind';
import { TableActionButton } from '@/components/views/action-button/TableActionButton';
import { useViewContext } from '@/components/views/ViewContext';
import { useThemingContext } from '@/context/ThemingContext';
import { getInlineEditInput, type ColumnSummariesValues } from './helper';
import { InlineEditForm } from './InlineEditForm';
import { SortableTableHeadGroups } from './table-sortable/SortableTableHeadGroups';
import { type InsertPositionState } from './table-sortable/SortableTableWrapper';
import { TableViewCellWrapper } from './TableViewCellWrapper';
import { TableViewHeadGroup } from './TableViewHeadGroup';
import { TableViewSummaryRow } from './TableViewSummaryRow';

export const nonEditableInputTypes = [
  'concatenation',
  'auto_increment',
  'sum',
  'min',
  'max',
  'average',
  'count',
  'time',
  'formula',
  'equation'
] as KnackFieldType[];

interface TableElementProps {
  columns: TableViewColumn[];
  records: { values: ViewRecord; rawValues: ViewRecord }[];
  summariesValues?: ColumnSummariesValues | null;
  insertPositionState?: InsertPositionState | null;
}

export function TableElement({
  columns,
  records,
  summariesValues,
  insertPositionState
}: TableElementProps) {
  const { view, sourceTable } = useViewContext<TableView>();
  const { theme } = useThemingContext();
  const { no_data_text: fallBackText, options, totals: columnSummaries } = view;
  const sourceTableFields = sourceTable?.fields ?? [];
  const shouldEnableInlineEditing = options?.cell_editor;
  const rowSpacingClasses = cn({
    'px-2 py-1': theme.tables.rowSpacing === 'compact',
    'p-2': theme.tables.rowSpacing === 'default',
    'px-2 py-3': theme.tables.rowSpacing === 'comfortable',
    'hover:bg-subtle': theme.tables.hasHoverRowColor
  });

  // Identify new columns not present in the record keys when we are adding new columns in the page editor
  const identifyNewColumns = (
    tableColumns: TableView['columns'],
    tableRecords: { values: ViewRecord; rawValues: ViewRecord }[]
  ) => {
    if (!isIframedByBuilder()) return null;

    const newColumns = tableColumns.map((column) => column.field?.key);
    const recordKeys = tableRecords.length > 0 ? Object.keys(tableRecords[0].values) : [];

    return newColumns.filter((column) => !recordKeys.includes(column));
  };

  const newTableViewColumns = identifyNewColumns(columns, records);

  return (
    <Table className="table w-full overflow-auto">
      <Table.Header>
        <Table.Row>
          {/* If we have column summaries, We add an empty table head at the beginning of the table */}
          {columnSummaries.length > 0 && (
            <Table.Head
              className={cn(
                'h-auto max-w-32 whitespace-nowrap border-x-0 border-t-0 border-default px-2 py-1 text-default',
                {
                  'border-r': theme.tables.border === 'rows-and-columns'
                }
              )}
            />
          )}
          {isPageEditor() ? (
            <SortableTableHeadGroups columns={columns} insertPositionState={insertPositionState} />
          ) : (
            <TableViewHeadGroup columns={columns} />
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body className="w-full">
        {records ? (
          records.map((record: { values: ViewRecord; rawValues: ViewRecord }) => (
            <Table.Row
              key={record.values.id}
              className={cn({
                'odd:bg-brand-muted even:bg-default': theme.tables.hasAlternateRowColor
              })}
            >
              {/* If we have column summaries, We add an empty table cell at the beginning of the table */}
              {columnSummaries.length > 0 && (
                <Table.Cell className={cn('w-32 border-x-0 text-center', rowSpacingClasses)} />
              )}
              {columns.map((column) => {
                if (column.type === 'field' && !column.field?.key) return null;

                if (
                  newTableViewColumns &&
                  newTableViewColumns.includes(column.field?.key) &&
                  column.field?.key !== undefined
                ) {
                  return (
                    <Table.Cell
                      key={`${column.id}-${record.values.id}`}
                      className={cn('border-x-0 text-center italic text-subtle', rowSpacingClasses)}
                    >
                      -
                    </Table.Cell>
                  );
                }

                // If the column is not type field, then is an action column
                if (column.type !== 'field') {
                  return (
                    <TableViewCellWrapper
                      key={`${column.id}-${record.values.id}`}
                      className={rowSpacingClasses}
                      recordValues={record.values}
                      column={column}
                      insertPositionState={insertPositionState}
                    >
                      <TableActionButton column={column} record={record.values} />
                    </TableViewCellWrapper>
                  );
                }

                const selectedField = sourceTableFields.find(
                  (field) => field.key === column.field.key
                );

                if (!selectedField) return null;

                const inlineEditInput = getInlineEditInput({
                  selectedField
                }) as FormViewFieldInput;

                if (
                  shouldEnableInlineEditing &&
                  !nonEditableInputTypes.includes(selectedField.type) &&
                  inlineEditInput &&
                  !column.ignore_edit &&
                  !isIframedByBuilder()
                ) {
                  return (
                    <InlineEditForm
                      key={`${column.id}-${record.values.id}`}
                      input={inlineEditInput}
                      record={record}
                      column={column}
                    />
                  );
                }
                return (
                  <TableViewCellWrapper
                    key={`${column.id}-${record.values.id}`}
                    className={rowSpacingClasses}
                    recordValues={record.values}
                    column={column}
                    insertPositionState={insertPositionState}
                  />
                );
              })}
            </Table.Row>
          ))
        ) : (
          <tr>
            <td>
              <p className="whitespace-nowrap p-2">{fallBackText}</p>
            </td>
          </tr>
        )}
        {summariesValues &&
          columnSummaries.map((columnSummary, index) => (
            <TableViewSummaryRow
              // eslint-disable-next-line react/no-array-index-key
              key={`${columnSummary.calc}-${index}`}
              columns={columns}
              columnSummary={columnSummary}
              summariesValues={summariesValues}
            />
          ))}
      </Table.Body>
    </Table>
  );
}

import { createContext, useContext, useMemo, useState } from 'react';

import { type LiveAppViewKey } from '@/types/schema/LiveAppView';
import { usePageQuery } from '@/hooks/api/queries/usePageQuery';
import { usePageContext } from '@/context/PageContext';

export const authViewFlows = ['login', 'signup', 'forgot-password'] as const;
type AuthViewFlow = (typeof authViewFlows)[number];

type AuthFlowContextState = {
  activeAuthFlow: AuthViewFlow;
  loginViewKey: LiveAppViewKey | null;
  activeSignupViewKey: LiveAppViewKey | null;
  setActiveAuthFlow: (flowState: AuthViewFlow) => void;
  setActiveSignupViewKey: (viewKey: LiveAppViewKey | null) => void;
} | null;

type AuthFlowProviderProps = {
  children: React.ReactNode;
};

const AuthFlowContext = createContext<AuthFlowContextState>(null);

export function AuthFlowContextProvider({ children }: AuthFlowProviderProps) {
  const { activePage } = usePageContext();
  const { data: page } = usePageQuery({ pageSlug: activePage?.slug });

  const [activeAuthFlow, setActiveAuthFlow] = useState<AuthViewFlow>('login');
  const [activeSignupViewKey, setActiveSignupViewKey] = useState<LiveAppViewKey | null>(null);

  const loginViewKey = useMemo(() => {
    if (page) {
      return page.views.find((view) => view.type === 'login')?.key ?? null;
    }
    return null;
  }, [page]);

  const value = useMemo(
    () => ({
      activeAuthFlow,
      loginViewKey,
      activeSignupViewKey,
      setActiveAuthFlow,
      setActiveSignupViewKey
    }),
    [activeAuthFlow, activeSignupViewKey, loginViewKey]
  );

  return <AuthFlowContext.Provider value={value}>{children}</AuthFlowContext.Provider>;
}

export const useAuthFlow = () => {
  const context = useContext(AuthFlowContext);

  if (!context) {
    throw new Error('useAuthFlow must be used within an AuthFlowProvider');
  }

  return context;
};

import { lazy, Suspense } from 'react';
import { Spinner } from '@knack/asterisk-react';

import { type TableViewColumn } from '@/types/schema/views/TableView';
import { cn } from '@/utils/tailwind';

const FontAwesomeIcon = lazy(() => import('@/components/FontAwesomeIcon'));

interface TableViewCellContentProps {
  shouldHideContent?: boolean;
  columnAlign: TableViewColumn['align'];
  icon: {
    icon: string;
    align: string;
    color: string;
  };
  value: string;
  children?: React.ReactNode;
  shouldCenterContent?: boolean;
}

export function TableViewCellContent({
  icon,
  columnAlign,
  shouldHideContent,
  value,
  children,
  shouldCenterContent
}: TableViewCellContentProps) {
  return (
    <div
      className={cn('flex items-center gap-1', {
        'flex-row-reverse': icon?.align === 'right',
        hidden: shouldHideContent,
        'justify-center': shouldCenterContent || columnAlign === 'center',
        'justify-start': columnAlign === 'left' && !shouldCenterContent,
        'justify-end': columnAlign === 'right' && !shouldCenterContent
      })}
    >
      {icon.icon && (
        <Suspense fallback={<Spinner />}>
          <FontAwesomeIcon
            icon={icon.icon}
            className="size-4 shrink-0"
            style={{ color: icon.color }}
          />
        </Suspense>
      )}
      {/* eslint-disable-next-line react/no-danger */}
      {children || <div dangerouslySetInnerHTML={{ __html: value }} />}
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

export function DeleteRecordConfirmDialog({
  onConfirm,
  children
}: {
  onConfirm: () => void;
  children: React.ReactNode;
}) {
  const [t] = useTranslation();

  return (
    <Dialog>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content>
        <Dialog.MainContent className="space-y-6">
          <Dialog.Title>{t('components.views.table.delete_record')}</Dialog.Title>
          <p>{t('components.views.table.delete_record_confirmation')}</p>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button intent="destructive" onClick={onConfirm}>
            {t('components.views.table.delete_record')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}

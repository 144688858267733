import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import md5 from 'md5';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useSessionContext } from '@/context/SessionContext';

// This URL returns a blank image from the gravatar service.
// We compare the response from this URL to a response from
// a user associated gravatar request.
const DEFAULT_GRAVATAR_URL = 'https://gravatar.com/avatar/default?d=blank';

function generateGravatarUrl(email: string) {
  const formattedEmail = email.trim().toLowerCase();

  return `https://gravatar.com/avatar/${md5(formattedEmail)}?d=blank`;
}

/**
 * Retrieves the gravatar URL for a given email address.
 *
 * By default, due to the d=blank parameter, the gravatar service returns a blank image in the absence of a user gravatar.
 * This function checks if a unique gravatar exists for an email address before passing the gravatar request URL
 * to the consumer to be used as the image source.
 *
 * @param {string} url - The gravatar request URL.
 * @returns {Promise<Object>} A promise that resolves to an object containing the gravatar response
 * and the gravatar URL if a gravatar exists for the email address, or `undefined` otherwise.
 */
async function getGravatar(url: string) {
  const [response, defaultGravatar] = await Promise.all([
    axios.get(url),
    axios.get(DEFAULT_GRAVATAR_URL)
  ]);
  const userHasGravatar = md5(response.data) !== md5(defaultGravatar.data);

  return {
    ...response,
    gravatarUrl: userHasGravatar ? url : undefined
  };
}

export function useGravatarQuery(email?: string) {
  const session = useSessionContext();
  const gravatarUrl = generateGravatarUrl(email ?? (session?.user.email || ''));

  return useQuery({
    queryKey: [queryKeys.gravatar, gravatarUrl],
    queryFn: () => getGravatar(gravatarUrl),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 30
  });
}

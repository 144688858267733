import { useLocation } from 'react-router-dom';

import { usePageContext } from '@/context/PageContext';

export function useActivePageHelpers() {
  const location = useLocation();

  const { activePage } = usePageContext();

  const getActivePagePathname = () => {
    if (!activePage) {
      return '/';
    }

    // If the current location has no page segments, it means it's the home page.
    // However, we still need the page's slug so we can generate the correct path when linking to child pages.
    if (location.pathname === '/') {
      return `/${activePage.slug}`;
    }

    return location.pathname;
  };

  return {
    getActivePagePathname
  };
}

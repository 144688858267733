import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@knack/asterisk-react';

import { type FormViewLinkInput } from '@/types/schema/views/form/Link';
import { FormErrorMessage } from '@/components/views/form/FormErrorMessage';
import { useViewContext } from '@/components/views/ViewContext';

export function LinkInput({ input }: { input: FormViewLinkInput }) {
  const [t] = useTranslation();
  const { view } = useViewContext();
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const inputFormat = input.format;
  const isReadOnly = input.read_only;

  if (inputFormat.text_format === 'unique') {
    return (
      <div className="space-y-4">
        <Input
          disabled={isReadOnly}
          className="text-sm"
          {...register(`${input.field.key}.label`)}
        />
        <FormErrorMessage errors={errors} name={`${input.field.key}.label`} />
        <span className="text-sm text-subtle">
          {t('components.views.form.link_input.link_text')}
        </span>
        <Input
          id={`${view.key}-${input.id}`}
          disabled={isReadOnly}
          className="text-sm"
          {...register(`${input.field.key}.url`)}
        />
        <FormErrorMessage errors={errors} name={`${input.field.key}.url`} />
        <span className="text-sm text-subtle">
          {t('components.views.form.link_input.link_url')}
        </span>
      </div>
    );
  }

  return (
    <>
      <Input
        id={`${view.key}-${input.id}`}
        data-testid="form-view-link-input"
        placeholder={t('components.views.form.link_input.placeholder')}
        disabled={isReadOnly}
        className="text-sm"
        {...register(`${input.field.key}.url`)}
      />
      <FormErrorMessage errors={errors} name={`${input.field.key}.url`} />
    </>
  );
}
